import { CompanyContract as TCompanyContract } from 'types'
import { useEditable } from 'utils'

import { CompanyContract } from './CompanyContract'
import { CompanyContractForm } from './CompanyContractForm'

type EditableCompanyContractProps = {
  contract: TCompanyContract
  isDeletable: boolean
}

export const EditableCompanyContract = ({
  contract,
  isDeletable,
}: EditableCompanyContractProps) => {
  const { isEditing, handleEdit, handleClose } = useEditable()

  if (isEditing) {
    return (
      <CompanyContractForm
        isDeletable={isDeletable}
        contract={contract}
        onClose={handleClose}
      />
    )
  }

  return <CompanyContract contract={contract} onEdit={handleEdit} />
}
