import { ReactNode } from 'react'
import { useParams } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import {
  Box,
  Paper,
  Grid,
  Typography,
  useMediaQuery,
  Theme,
} from '@mui/material'
import invariant from 'tiny-invariant'

import { useOrderQuery, useOrderUpdatedSubscription } from 'api'
import { NotFound404 } from 'pages'
import { LayoutWithBackButton, Spinner } from 'components'
import { Order as TOrder } from 'types'

import { EditableOrder } from './EditableOrder'
import { EditableOrderServices } from './EditableOrderServices'
import { EditableOrderComments } from './EditableOrderComments'

const StyledPaper = styled(Paper)(
  ({ theme }) => `
    min-width: ${theme.spacing(40)};
    padding: ${theme.spacing(3)};
    padding-bottom: ${theme.spacing(5)};
`,
)

type OrderForm = {
  title: string
  formComponent: ReactNode
}

const getForms = (order: TOrder): OrderForm[] => [
  {
    title: `Заказ № ${order.id}`,
    formComponent: <EditableOrder order={order} />,
  },
  {
    title: 'Услуги',
    formComponent: <EditableOrderServices order={order} />,
  },
  {
    title: 'Комментарии',
    formComponent: <EditableOrderComments order={order} />,
  },
]

type URLParams = {
  id: string
}

export const Order = () => {
  const isMatchSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
  const { id } = useParams<URLParams>()
  invariant(id, "Order id isn't set")

  const { data: orderData, loading: orderLoading } = useOrderQuery(id, {
    fetchPolicy: 'network-only',
  })

  const order = orderData?.order
  useOrderUpdatedSubscription(order?.id)

  if (orderLoading) {
    return (
      <LayoutWithBackButton>
        <Grid item xs={12}>
          <Spinner />
        </Grid>
      </LayoutWithBackButton>
    )
  }

  if (!order) {
    return (
      <LayoutWithBackButton>
        <Grid item xs={12}>
          <NotFound404 withLayout={false} />
        </Grid>
      </LayoutWithBackButton>
    )
  }

  return (
    <LayoutWithBackButton>
      {getForms(order).map(({ title, formComponent }, index) => (
        <Grid item xs={12} key={`${title}-${index}`}>
          <StyledPaper>
            <Grid container direction="column" spacing={2}>
              <Grid item xs={12}>
                <Box my={2}>
                  <Typography
                    variant={isMatchSm ? 'h4' : 'h5'}
                    color="primary"
                    align="center"
                  >
                    {title}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                {formComponent}
              </Grid>
            </Grid>
          </StyledPaper>
        </Grid>
      ))}
    </LayoutWithBackButton>
  )
}
