import { gql, useMutation } from '@apollo/client'

import { COMPANY, INPUT_ERROR } from 'api'
import { Company, InputError } from 'types'

export const CREATE_COMPANY_CONTRACT = gql`
  mutation CreateCompanyContract($input: CreateCompanyContractInput!) {
    createCompanyContract(input: $input) {
      company {
        ...Company
      }
      errors {
        ...InputError
      }
    }
  }
  ${COMPANY}
  ${INPUT_ERROR}
`

type CreateCompanyContractDataSuccess = {
  company: Company
  errors: null
}

type CreateCompanyContractDataInputError = {
  company: null
  errors: InputError<'content'>[]
}

export type CreateCompanyContractData = {
  createCompanyContract:
    | CreateCompanyContractDataSuccess
    | CreateCompanyContractDataInputError
}

export type CreateCompanyContractVariables = {
  input: {
    companyId: string
    name: string
    signedAt: string
    document?: File | null
  }
}

export const useCreateCompanyContractMutation = () => {
  return useMutation<CreateCompanyContractData, CreateCompanyContractVariables>(
    CREATE_COMPANY_CONTRACT,
  )
}
