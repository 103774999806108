import { QueryHookOptions, gql, useQuery } from '@apollo/client'

import { SOFTWARE } from 'api'
import { Software } from 'types'

export type SoftwareData = {
  software: Software[]
}

export const GET_SOFTWARE = gql`
  query GetSoftware {
    software {
      ...Software
    }
  }
  ${SOFTWARE}
`

export const softwareQueryOptions: QueryHookOptions<SoftwareData> = {
  errorPolicy: 'all',
}

export function useSoftwareQuery() {
  return useQuery<SoftwareData>(GET_SOFTWARE, softwareQueryOptions)
}
