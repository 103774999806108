import { useMemo } from 'react'
import { Skeleton } from '@mui/material'

import { SelectField, SelectFieldProps } from 'components'
import { usePerformerServiceTypesQuery } from 'api'
import { formatReferenceEntityToSelectOption } from 'utils'

type PerformerServiceTypeSelectFieldProps = Omit<
  SelectFieldProps,
  'options'
> & {
  serviceGroupId?: string
}

export const PerformerServiceTypeSelectField = ({
  serviceGroupId,
  ...props
}: PerformerServiceTypeSelectFieldProps) => {
  const { data, loading } = usePerformerServiceTypesQuery()

  const options = useMemo(() => {
    const performerServiceTypes = data?.performerServiceTypes || []

    return formatReferenceEntityToSelectOption(
      serviceGroupId
        ? performerServiceTypes.filter(
            (serviceType) => serviceType.group.id === serviceGroupId,
          )
        : performerServiceTypes,
    )
  }, [data, serviceGroupId])

  return loading ? (
    <Skeleton height={56} />
  ) : (
    <SelectField options={options} {...props} />
  )
}
