import { gql, useMutation } from '@apollo/client'

import { PERFORMER, INPUT_ERROR } from 'api'
import { Performer, InputError } from 'types'

export const CREATE_EDUCATION = gql`
  mutation CreateEducation($input: CreateEducationInput!) {
    createEducation(input: $input) {
      performer {
        ...Performer
      }
      errors {
        ...InputError
      }
    }
  }
  ${PERFORMER}
  ${INPUT_ERROR}
`

type CreateEducationDataSuccess = {
  performer: Performer
  errors: null
}

type CreateEducationDataInputError = {
  performer: null
  errors: InputError<'graduationYear' | 'diploma'>[]
}

export type CreateEducationData = {
  createEducation: CreateEducationDataSuccess | CreateEducationDataInputError
}

export type CreateEducationVariables = {
  input: {
    performerId: string
    graduationYear: number
    organizationName: string
    countryId: string
    major?: string
    diploma?: File | null
  }
}

export const useCreateEducationMutation = () => {
  return useMutation<CreateEducationData, CreateEducationVariables>(
    CREATE_EDUCATION,
  )
}
