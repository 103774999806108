import { Typography, Stack } from '@mui/material'

import { Layout } from 'components'

const Content = () => {
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ mt: 5 }}
    >
      <Typography variant="body1">К сожалению, такой страницы нет</Typography>
    </Stack>
  )
}

type NotFound404Props = {
  withLayout?: boolean
}

export const NotFound404 = ({ withLayout = true }: NotFound404Props) => {
  return withLayout ? (
    <Layout maxWidth="md">
      <Content />
    </Layout>
  ) : (
    <Content />
  )
}
