import { styled } from '@mui/material/styles'
import { Avatar, Box, Grid, IconButton, Link, Typography } from '@mui/material'
import { Edit as EditIcon, Store as StoreIcon } from '@mui/icons-material'

import { Company as TCompany } from 'types'

const StyledAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
`

type CompanyProps = {
  onEdit?: () => void
  company: TCompany
}

export const Company = ({ onEdit, company }: CompanyProps) => {
  const {
    name,
    docName,
    inn,
    kpp,
    ogrn,
    url,
    logo,
    address,
    legalAddress,
    mailAddress,
  } = company

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item>
        <StyledAvatar src={logo} alt={name}>
          <StoreIcon sx={{width: "75%", height: "75%"}} />
        </StyledAvatar>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4" align="center">
          {name}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1} alignItems="center" wrap="nowrap">
          <Grid item flexGrow={1}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="caption" color="primary">
                  Полное наименование
                </Typography>
                <Typography variant="body1">{docName}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" color="primary">
                  ИНН
                </Typography>
                <Typography variant="body1">{inn}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" color="primary">
                  КПП
                </Typography>
                <Typography variant="body1">{kpp}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" color="primary">
                  ОГРН
                </Typography>
                <Typography variant="body1">{ogrn}</Typography>
              </Grid>
              {url && (
                <Grid item xs={12}>
                  <Typography variant="caption" color="primary">
                    Сайт
                  </Typography>
                  <Box>
                    <Link href={url} variant="body1">
                      {url}
                    </Link>
                  </Box>
                </Grid>
              )}
              {address && (
                <Grid item xs={12}>
                  <Typography variant="caption" color="primary">
                    Фактический адрес
                  </Typography>
                  <Typography variant="body1">{address}</Typography>
                </Grid>
              )}
              {legalAddress && (
                <Grid item xs={12}>
                  <Typography variant="caption" color="primary">
                    Юридический адрес
                  </Typography>
                  <Typography variant="body1">{legalAddress}</Typography>
                </Grid>
              )}
              {mailAddress && (
                <Grid item xs={12}>
                  <Typography variant="caption" color="primary">
                    Почтовый адрес
                  </Typography>
                  <Typography variant="body1">{mailAddress}</Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="Редактировать"
              onClick={onEdit}
              size="large"
            >
              <EditIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
