import { useEffect } from 'react'

import { useSignOutMutation } from 'api'
import { Spinner } from 'components'

export const SignOut = () => {
  const [signOut] = useSignOutMutation()

  useEffect(() => {
    signOut()
    // Mutation must be triggered only once
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Spinner />
}
