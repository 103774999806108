import { gql, MutationFunctionOptions, useMutation } from '@apollo/client'

import { Customer, InputError } from 'types'
import {
  CUSTOMER,
  GET_CURRENT_USER,
  INPUT_ERROR,
  CurrentUserData,
  useCurrentUserQuery,
} from 'api'

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      customer {
        ...Customer
      }
      errors {
        ...InputError
      }
    }
  }
  ${CUSTOMER}
  ${INPUT_ERROR}
`

type UpdateCustomerDataSuccess = {
  customer: Customer
  errors: null
}

type UpdateCustomerDataInputError = {
  customer: null
  errors: InputError<'profile.email' | 'profile.phone'>[]
}

export type UpdateCustomerData = {
  updateCustomer: UpdateCustomerDataSuccess | UpdateCustomerDataInputError
}

export type UpdateCustomerVariables = {
  input: {
    id: string
    companies?: string[]
    profile?: {
      email?: string
      phone?: string
      firstName?: string
      lastName?: string
      birthday?: string | null
      avatar?: File | null
    }
  }
}

export const useUpdateCustomerMutation = () => {
  const [updateCustomer, fetchResult] = useMutation<
    UpdateCustomerData,
    UpdateCustomerVariables
  >(UPDATE_CUSTOMER)
  const { data, client } = useCurrentUserQuery()
  const currentUser = data!.currentUser!

  const sendUpdateCustomer = async (
    mutationOptions: MutationFunctionOptions<
      UpdateCustomerData,
      UpdateCustomerVariables
    >,
  ) => {
    const result = await updateCustomer(mutationOptions)

    if (result.data?.updateCustomer.customer?.profile.id === currentUser.id) {
      await client.query<CurrentUserData>({
        query: GET_CURRENT_USER,
        fetchPolicy: 'network-only',
      })
    }

    return result
  }

  return [sendUpdateCustomer, fetchResult] as const
}
