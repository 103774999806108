import { useState, createContext, useMemo, ReactNode } from 'react'

import {
  StyledEngineProvider,
  Theme,
  createTheme,
  ThemeOptions,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'
import { deepPurple, teal } from '@mui/material/colors'

const light: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: { main: deepPurple['500'] },
    secondary: teal,
  },
}

const dark: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: deepPurple['A100'],
    },
    secondary: teal,
  },
}

type ThemeName = 'dark' | 'light'

const themes = {
  dark,
  light,
}

type ThemeContextValues = {
  setTheme: (themeName: ThemeName) => void
  toggleTheme: () => void
}

export const CustomThemeContext = createContext<ThemeContextValues>({
  setTheme: () => {},
  toggleTheme: () => {},
})

const useCustomTheme = (): [Theme, ThemeContextValues] => {
  const OSThemeName: ThemeName = useMediaQuery('(prefers-color-scheme: dark)', {
    noSsr: true,
  })
    ? 'dark'
    : 'light'

  const savedThemeName = localStorage.getItem('theme') as string | null

  const initialThemeName =
    savedThemeName && savedThemeName in themes
      ? (savedThemeName as ThemeName)
      : OSThemeName

  const [themeName, setThemeName] = useState<ThemeName>(initialThemeName)

  const setTheme = (themeName: ThemeName) => {
    localStorage.setItem('theme', themeName)
    setThemeName(themeName)
  }

  const toggleTheme = () => {
    themeName === 'dark' ? setTheme('light') : setTheme('dark')
  }

  const themeOptions = themes[themeName]

  const theme = useMemo(() => createTheme(themeOptions), [themeOptions])

  return [theme, { setTheme, toggleTheme }]
}

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const [theme, changeThemeMethods] = useCustomTheme()

  return (
    <StyledEngineProvider injectFirst>
      <CustomThemeContext.Provider value={changeThemeMethods}>
        <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
      </CustomThemeContext.Provider>
    </StyledEngineProvider>
  )
}
