import { BaseMutationOptions, gql, useMutation } from '@apollo/client'

import { USER } from 'api'
import { User } from 'types'

export const CONFIRM_EMAIL = gql`
  mutation($input: ConfirmEmailInput!) {
    confirmEmail(input: $input) {
      user {
        ...User
      }
    }
  }
  ${USER}
`

export type ConfirmEmailData = {
  confirmEmail: {
    user: User
  }
}

export type ConfirmEmailVariables = {
  input: {
    token: string
  }
}

export const confirmEmailMutationOptions: BaseMutationOptions<
  ConfirmEmailData,
  ConfirmEmailVariables
> = {
  errorPolicy: 'all',
}

export const useConfirmEmailMutation = () => {
  return useMutation<ConfirmEmailData, ConfirmEmailVariables>(
    CONFIRM_EMAIL,
    confirmEmailMutationOptions,
  )
}
