import { styled } from '@mui/material/styles'
import { CircularProgress } from '@mui/material'

const StyledContainer = styled('div')`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`

export const Spinner = () => {
  return (
    <StyledContainer>
      <CircularProgress color="primary" />
    </StyledContainer>
  )
}
