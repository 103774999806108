import { QueryHookOptions, gql, useQuery } from '@apollo/client'

import { PERFORMER } from 'api'
import { Performer } from 'types'

export type PerformerData = {
  performer: Performer
}

export type PerformerVariables = {
  id: string
}

export const GET_PERFORMER = gql`
  query GetPerformer($id: ID!) {
    performer(id: $id) {
      ...Performer
    }
  }
  ${PERFORMER}
`

export const defaultPerformerQueryOptions: QueryHookOptions<
  PerformerData,
  PerformerVariables
> = {
  errorPolicy: 'all',
}

export function usePerformerQuery(
  performerId: string,
  performerQueryOptions?: Omit<
    QueryHookOptions<PerformerData, PerformerVariables>,
    'variables'
  >,
) {
  return useQuery<PerformerData, PerformerVariables>(GET_PERFORMER, {
    ...defaultPerformerQueryOptions,
    ...performerQueryOptions,
    variables: {
      id: performerId,
    },
  })
}
