import { ApolloClient, createHttpLink, fromPromise } from '@apollo/client'

import { onError } from '@apollo/client/link/error'

import {
  GET_CURRENT_USER,
  RENEW_TOKEN,
  CurrentUserData,
  RenewTokenData,
  RenewTokenVariables,
} from 'api'

import { cache } from './cache'

let isRefreshing = false
let pendingRequests: Function[] = []

const setIsRefreshing = (value: boolean) => {
  isRefreshing = value
}

const addPendingRequest = (pendingRequest: Function) => {
  pendingRequests.push(pendingRequest)
}

const renewTokenApiClient = new ApolloClient({
  link: createHttpLink({ uri: process.env.REACT_APP_API_URL }),
  cache,
  credentials: 'include',
})

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback())
  pendingRequests = []
}

const getNewToken = async () => {
  const oldRenewalToken = localStorage.getItem('renewalToken')

  if (oldRenewalToken) {
    const { data } = await renewTokenApiClient.mutate<
      RenewTokenData,
      RenewTokenVariables
    >({
      mutation: RENEW_TOKEN,
      variables: { input: { renewalToken: oldRenewalToken } },
    })!

    if (data) {
      const {
        renewToken: {
          session: { renewalToken, accessToken },
        },
      } = data

      localStorage.setItem('renewalToken', renewalToken)
      localStorage.setItem('accessToken', accessToken)
    }
  }
}

export const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      switch (err?.message) {
        case 'expired':
          if (!isRefreshing) {
            setIsRefreshing(true)

            return fromPromise(
              getNewToken().catch(() => {
                resolvePendingRequests()
                setIsRefreshing(false)

                localStorage.removeItem('accessToken')
                localStorage.removeItem('renewalToken')

                // Cache instance shared with main client instance
                renewTokenApiClient!.writeQuery<CurrentUserData>({
                  query: GET_CURRENT_USER,
                  data: { currentUser: null },
                })

                return forward(operation)
              }),
            ).flatMap(() => {
              resolvePendingRequests()
              setIsRefreshing(false)

              return forward(operation)
            })
          } else {
            return fromPromise(
              new Promise((resolve) => {
                addPendingRequest(resolve)
              }),
            ).flatMap(() => {
              return forward(operation)
            })
          }
      }
    }
  }
})
