import { useCallback, useEffect, SyntheticEvent } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { format } from 'date-fns'
import { Button, Grid } from '@mui/material'

import { useCreatePerformerMutation, usePerformerStatusesQuery } from 'api'
import {
  AvatarField,
  ButtonContainerWithSpinner,
  CountrySelectField,
  DateField,
  EmailField,
  Field,
  GenderSelectField,
  LanguageSelectField,
  PerformerStatusSelectField,
  PhoneField,
  UploadField,
} from 'components'
import { resetPhoneFormat, setFormErrors } from 'utils'
import { Performer } from 'types'

const errorsMessages = {
  'profile.email': {
    key: 'email' as const,
    messages: {
      'has already been taken':
        'Пользователь с таким email уже зарегистрирован',
      'is invalid': 'Неверный email',
    },
    default: 'Нельзя зарегистрироваться с таким email',
  },
  'profile.phone': {
    key: 'phone' as const,
    messages: {
      'is invalid': 'Неверный номер телефона',
      'has already been taken':
        'Пользователь с таким телефоном уже зарегистрирован',
    },
    default: 'Нельзя зарегистрироваться с таким номером телефона',
  },
}

const acceptedExtensions = ['pdf']

type CreatePerformerProfileFormData = {
  id: string
  bio: string
  cv?: File | string
  nativeLanguageId: string
  statusId: string
  city: string
  birthday: Date | null
  countryId: string
  genderId: string
  firstName: string
  lastName: string
  email: string
  phone: string
  avatar?: File | string
}

type CreatePerformerProfileFormProps = {
  onSubmit?: (performer: Performer) => void
}

export const CreatePerformerProfileForm = ({
  onSubmit: onSubmitAction,
}: CreatePerformerProfileFormProps) => {
  const { data: performerStatusesData } = usePerformerStatusesQuery()
  const [createPerformer] = useCreatePerformerMutation()

  const methods = useForm<CreatePerformerProfileFormData>({
    defaultValues: {
      bio: '',
      cv: '',
      nativeLanguageId: '',
      statusId: '',
      city: '',
      birthday: null,
      countryId: '',
      genderId: '',
      avatar: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    },
    mode: 'onBlur',
  })

  const onSubmit = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault()

      methods.handleSubmit(async (formData) => {
        const {
          city,
          birthday: rawBirthday,
          nativeLanguageId,
          statusId,
          countryId,
          genderId,
          bio,
          cv,
          avatar,
          firstName,
          lastName,
          email,
          phone,
        } = formData

        const birthday = rawBirthday ? format(rawBirthday, 'yyyy-MM-dd') : ''

        const { data } = await createPerformer({
          variables: {
            input: {
              bio,
              nativeLanguageId,
              statusId,
              // URL of existing cv is not valid value
              ...(typeof cv !== 'string' && { cv }),
              profile: {
                firstName,
                lastName,
                phone: resetPhoneFormat(phone),
                email,
                city,
                birthday,
                countryId,
                genderId,
                // URL of existing avatar is not valid value
                ...(typeof avatar !== 'string' && { avatar }),
              },
            },
          },
        })

        if (data?.createPerformer?.errors) {
          setFormErrors<
            Pick<CreatePerformerProfileFormData, 'email' | 'phone'>
          >(methods.setError, data.createPerformer.errors, errorsMessages)
        }

        if (data?.createPerformer?.performer) {
          if (onSubmitAction) onSubmitAction(data.createPerformer.performer)
        }
      })()
    },
    [methods, createPerformer, onSubmitAction],
  )

  useEffect(() => {
    if (performerStatusesData) {
      const defaultPerformerStatus =
        performerStatusesData.performerStatuses.find(
          (status) => status.name === 'Не проверен',
        )

      if (defaultPerformerStatus) {
        methods.setValue('statusId', defaultPerformerStatus.id)
      }
    }
  }, [methods, performerStatusesData])

  return (
    <FormProvider {...methods}>
      <Grid
        container
        spacing={2}
        noValidate
        autoComplete="off"
        onSubmit={onSubmit}
        component="form"
      >
        <Grid item container alignItems="center" spacing={2}>
          <Grid item xs={12} sm={6}>
            <AvatarField name="avatar" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  name="firstName"
                  label="Имя"
                  rules={{
                    required: true,
                    maxLength: {
                      value: 255,
                      message: 'Не более 255 символов',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="lastName"
                  label="Фамилия"
                  rules={{
                    required: true,
                    maxLength: {
                      value: 255,
                      message: 'Не более 255 символов',
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <EmailField
            name="email"
            label="Email"
            rules={{
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PhoneField rules={{ required: true }} name="phone" label="Телефон" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateField
            name="birthday"
            label="Дата рождения"
            rules={{ required: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LanguageSelectField
            name="nativeLanguageId"
            rules={{ required: true }}
            label="Родной язык"
            hasEmptyOption
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CountrySelectField
            name="countryId"
            rules={{ required: true }}
            label="Страна проживания"
            hasEmptyOption
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name="city"
            label="Город проживания"
            rules={{ required: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <GenderSelectField
            name="genderId"
            rules={{ required: true }}
            label="Пол"
            hasEmptyOption
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PerformerStatusSelectField
            name="statusId"
            rules={{ required: true }}
            label="Статус"
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="bio"
            label="Немного об исполнителе"
            multiline
            minRows={3}
          />
        </Grid>
        <Grid item xs={12}>
          <UploadField
            name="cv"
            label="Загрузить резюме"
            labelActive="Приложите резюме"
            accept={acceptedExtensions}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={1}>
            <Grid item>
              <ButtonContainerWithSpinner
                isLoading={methods.formState.isSubmitting}
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={methods.formState.isSubmitting}
                >
                  Зарегистрировать исполнителя
                </Button>
              </ButtonContainerWithSpinner>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}
