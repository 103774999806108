import { useMemo } from 'react'
import { InputAdornment } from '@mui/material'

import { Field, FieldProps } from 'components'

export type PercentageFieldProps = FieldProps

export const PercentageField = ({ rules, ...props }: PercentageFieldProps) => {
  const memoizedRules = useMemo(
    () => ({
      pattern: {
        value: /^[1-9][0-9]?$|^100$/,
        message: 'Недопустимое значение в процентах',
      },
      ...rules,
    }),
    [rules],
  )

  return (
    <Field
      rules={memoizedRules}
      InputProps={{
        startAdornment: <InputAdornment position="start">%</InputAdornment>,
      }}
      inputProps={{
        maxLength: 3,
      }}
      {...props}
    />
  )
}
