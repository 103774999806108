import { useMemo } from 'react'

import { usePerformerServiceTypesQuery } from 'api'

import type { Performer } from 'types'

export const useHasUnusedPerformerServices = (performer: Performer) => {
  const { data: performerServiceTypesData } = usePerformerServiceTypesQuery()

  return useMemo(() => {
    if (!performerServiceTypesData) return true

    const usedTypeIds =
      performer.services.map((service) => service.type.id) || []

    const allowedTypeIds =
      performerServiceTypesData.performerServiceTypes.filter(
        ({ id }) => !usedTypeIds.includes(id),
      )

    return Boolean(allowedTypeIds.length)
  }, [performer, performerServiceTypesData])
}
