import { MultipleEditableRoot, MultipleEditableItem } from 'components'
import { useMultipleForms } from 'utils'
import { Order } from 'types'

import { OrderServiceForm } from './OrderServiceForm'
import { EditableOrderService } from './EditableOrderService'

type EditableOrderServicesProps = {
  order: Order
}

export const EditableOrderServices = ({
  order,
}: EditableOrderServicesProps) => {
  const { forms, addForm, removeForm } = useMultipleForms()

  const buttonTitle =
    order.services.length > 0 || forms.length > 0 ? 'Добавить ещё' : 'Добавить'

  return (
    <MultipleEditableRoot onAddForm={addForm} buttonTitle={buttonTitle}>
      {order.services.map((service) => (
        <MultipleEditableItem key={service.id}>
          <EditableOrderService order={order} service={service} isDeletable />
        </MultipleEditableItem>
      ))}
      {forms.map((itemKey) => (
        <MultipleEditableItem key={itemKey}>
          <OrderServiceForm order={order} onClose={removeForm(itemKey)} />
        </MultipleEditableItem>
      ))}
    </MultipleEditableRoot>
  )
}
