import { useEditable } from 'utils'
import { Order as TOrder } from 'types'

import { Order } from './Order'
import { OrderForm } from './OrderForm'

type EditableOrderProps = {
  order: TOrder
}

export const EditableOrder = ({ order }: EditableOrderProps) => {
  const { isEditing, handleEdit, handleClose } = useEditable()

  if (isEditing) {
    return <OrderForm order={order} onCancel={handleClose} />
  }

  return <Order order={order} onEdit={handleEdit} />
}
