import { gql, useMutation } from '@apollo/client'

import { USER, INPUT_ERROR } from 'api'
import { User, InputError } from 'types'

export const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        ...User
      }
      errors {
        ...InputError
      }
    }
  }
  ${USER}
  ${INPUT_ERROR}
`

type UpdateUserDataSuccess = {
  user: User
  errors: null
}

type UpdateUserDataInputError = {
  user: null
  errors: InputError<'email' | 'phone'>[]
}

export type UpdateUserData = {
  updateUser: UpdateUserDataSuccess | UpdateUserDataInputError
}

export type UpdateUserVariables = {
  input: {
    id: string
    avatar?: File | null
    firstName?: string
    lastName?: string
    email?: string
    phone?: string
  }
}

export function useUpdateUserMutation() {
  return useMutation<UpdateUserData, UpdateUserVariables>(UPDATE_USER)
}
