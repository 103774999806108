import { MultipleEditableRoot, MultipleEditableItem } from 'components'
import { useMultipleForms } from 'utils'
import { Performer } from 'types'

import { PerformerExperienceForm } from './PerformerExperienceForm'
import { EditablePerformerExperienceItem } from './EditablePerformerExperienceItem'

type EditablePerformerExperienceProps = {
  performer: Performer
}

export const EditablePerformerExperience = ({
  performer,
}: EditablePerformerExperienceProps) => {
  const { forms, addForm, removeForm } = useMultipleForms()

  const buttonTitle =
    performer.experience.length > 0 || forms.length > 0
      ? 'Добавить ещё'
      : 'Добавить'

  const isDeletable = performer.experience.length > 1

  return (
    <MultipleEditableRoot onAddForm={addForm} buttonTitle={buttonTitle}>
      {performer.experience.map((experience) => (
        <MultipleEditableItem key={experience.id}>
          <EditablePerformerExperienceItem
            performer={performer}
            experience={experience}
            isDeletable={isDeletable}
          />
        </MultipleEditableItem>
      ))}
      {forms.map((itemKey) => (
        <MultipleEditableItem key={itemKey}>
          <PerformerExperienceForm
            performer={performer}
            onClose={removeForm(itemKey)}
          />
        </MultipleEditableItem>
      ))}
    </MultipleEditableRoot>
  )
}
