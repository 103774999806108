import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  Typography,
} from '@mui/material'
import { GetApp as GetAppIcon, Edit as EditIcon } from '@mui/icons-material'

import { getUserFullname } from 'utils'
import { Performer } from 'types'

type PerformerProfileProps = {
  onEdit?: () => void
  performer: Performer
}

export const PerformerProfile = ({
  onEdit,
  performer,
}: PerformerProfileProps) => {
  const fullName = getUserFullname(performer.profile)

  const {
    bio,
    nativeLanguage,
    cv,
    status,
    profile: { avatar, email, phone, city, birthday, country, gender },
  } = performer

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item>
        <Avatar src={avatar} alt={fullName} sx={{ width: 120, height: 120 }} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4" align="center">
          {fullName}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1} alignItems="center" wrap="nowrap">
          <Grid item flexGrow={1}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="caption" color="primary">
                  Почта
                </Typography>
                <Box>
                  <Link href={`mailto:${email}`} variant="body1">
                    {email}
                  </Link>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" color="primary">
                  Телефон
                </Typography>
                <Box>
                  <Link href={`tel:+${phone}`} variant="body1">
                    +{phone}
                  </Link>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" color="primary">
                  Дата рождения
                </Typography>
                <Typography variant="body1">{birthday}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" color="primary">
                  Город
                </Typography>
                <Typography variant="body1">{city}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" color="primary">
                  Страна проживания
                </Typography>
                <Typography variant="body1">{country?.name}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" color="primary">
                  Родной язык
                </Typography>
                <Typography variant="body1">{nativeLanguage?.name}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" color="primary">
                  Пол
                </Typography>
                <Typography variant="body1">{gender?.name}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" color="primary">
                  Статус
                </Typography>
                <Typography variant="body1">{status?.name}</Typography>
              </Grid>
              {bio && (
                <Grid item xs={12}>
                  <Typography variant="caption" color="primary">
                    О себе
                  </Typography>
                  <Typography variant="body1">{bio}</Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="Редактировать"
              onClick={onEdit}
              size="large"
            >
              <EditIcon />
            </IconButton>
          </Grid>
        </Grid>
        {cv && (
          <Box mt={3} textAlign="center">
            <Button
              variant="outlined"
              color="primary"
              href={cv}
              startIcon={<GetAppIcon />}
            >
              Скачать резюме
            </Button>
          </Box>
        )}
      </Grid>
    </Grid>
  )
}
