import { gql, useMutation } from '@apollo/client'

export const SEND_RESET_PASSWORD_URL = gql`
  mutation($input: SendResetPasswordUrlInput!) {
    sendResetPasswordUrl(input: $input) {
      email
    }
  }
`

export type SendResetPasswordUrlData = {
  sendResetPasswordUrl: {
    email: string
  }
}

export type SendResetPasswordUrlVariables = {
  input: {
    email: string
  }
}

export const useSendPasswordUrlMutation = () => {
  return useMutation<SendResetPasswordUrlData, SendResetPasswordUrlVariables>(
    SEND_RESET_PASSWORD_URL,
  )
}
