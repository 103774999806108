import { useRef } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { Box, Button, Grid, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'

import { useChangePasswordMutation, useCurrentUserQuery } from 'api'
import { ButtonContainerWithSpinner, PasswordField } from 'components'
import { setFormErrors } from 'utils'

const errorsMessages = {
  currentPassword: {
    default: 'Неверный пароль',
  },
  password: {
    default: 'Нельзя установить такой пароль',
  },
}

type FormData = {
  currentPassword: string
  password: string
}

export const ChangePassword = () => {
  const { enqueueSnackbar } = useSnackbar()
  const submitButtonRef = useRef<HTMLButtonElement | null>(null)
  const { data } = useCurrentUserQuery()
  const [changePassword, { loading }] = useChangePasswordMutation()

  const currentUser = data?.currentUser!
  const defaultValues = { password: '', currentPassword: '' }

  const methods = useForm<FormData>({ defaultValues, shouldFocusError: true })

  const onSubmit = methods.handleSubmit(
    async ({ password, currentPassword }) => {
      const { data } = await changePassword({
        variables: { input: { id: currentUser.id, password, currentPassword } },
      })

      if (data?.updateUser?.errors) {
        setFormErrors<FormData>(
          methods.setError,
          data.updateUser.errors,
          errorsMessages,
        )
      }

      if (data?.updateUser?.user) {
        methods.reset(defaultValues)
        submitButtonRef.current?.focus()
        submitButtonRef.current?.blur()
        enqueueSnackbar('Пароль успешно изменен', { variant: 'success' })
      }
    },
  )

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item xs={12}>
        <Box my={2}>
          <Typography variant="h5">Изменение пароля</Typography>
        </Box>
      </Grid>
      <FormProvider {...methods}>
        <Grid
          item
          container
          noValidate
          autoComplete="off"
          onSubmit={onSubmit}
          component="form"
        >
          <Grid item xs={12}>
            <PasswordField
              name="currentPassword"
              label="Старый пароль"
              rules={{
                required: true,
                minLength: 1,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordField
              name="password"
              label="Пароль"
              rules={{
                required: true,
              }}
            />
          </Grid>
          <Grid item xs={12} container alignItems="center">
            <ButtonContainerWithSpinner isLoading={loading}>
              <Button
                variant="text"
                color="primary"
                type="submit"
                ref={submitButtonRef}
                disabled={loading}
              >
                Сменить пароль
              </Button>
            </ButtonContainerWithSpinner>
          </Grid>
        </Grid>
      </FormProvider>
    </Grid>
  )
}
