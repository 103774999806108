import { useCallback, SyntheticEvent } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Button, Grid } from '@mui/material'

import {
  AvatarField,
  ButtonContainerWithSpinner,
  Field,
  PhoneField,
  EmailField,
} from 'components'
import { useUpdateUserMutation } from 'api'
import { resetPhoneFormat, setFormErrors } from 'utils'
import { User } from 'types'

const errorsMessages = {
  email: {
    messages: {
      'has already been taken':
        'Пользователь с таким email уже зарегистрирован',
      'is invalid': 'Неверный email',
    },
    default: 'Нельзя зарегистрироваться с таким email',
  },
  phone: {
    messages: {
      'is invalid': 'Неверный номер телефона',
      'has already been taken':
        'Пользователь с таким телефоном уже зарегистрирован',
    },
    default: 'Нельзя зарегистрироваться с таким номером телефона',
  },
}

type FormData = {
  firstName: string
  lastName: string
  email: string
  phone: string
  avatar: File | string
}

type ProfileFormProps = {
  user: User
  onSubmit?: () => void
  onClose: () => void
}

export const ProfileForm = ({
  onSubmit: onSubmitAction,
  onClose,
  user,
}: ProfileFormProps) => {
  const [updateUser, { loading }] = useUpdateUserMutation()

  const { avatar, firstName, lastName, email, phone } = user

  const methods = useForm<FormData>({
    defaultValues: { avatar, firstName, lastName, email, phone },
    mode: 'onBlur',
  })

  const onSubmit = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault()

      await methods.handleSubmit(async (formData) => {
        const { avatar, firstName, lastName, phone, email } = formData

        const { data } = await updateUser({
          variables: {
            input: {
              id: user.id,
              firstName,
              lastName,
              phone: resetPhoneFormat(phone),
              email,
              // URL of existing avatar is not valid value
              ...(typeof avatar !== 'string' && { avatar }),
            },
          },
        })

        if (data?.updateUser?.errors) {
          setFormErrors<Pick<FormData, 'email' | 'phone'>>(
            methods.setError,
            data.updateUser.errors,
            errorsMessages,
          )
        }

        if (data?.updateUser?.user) {
          if (onSubmitAction) onSubmitAction()

          onClose()
        }
      })()
    },
    [methods, updateUser, onSubmitAction, onClose, user.id],
  )

  return (
    <FormProvider {...methods}>
      <Grid
        container
        spacing={2}
        noValidate
        autoComplete="off"
        onSubmit={onSubmit}
        component="form"
      >
        <Grid item xs={12}>
          <Grid container alignItems="flex-end" spacing={2}>
            <Grid item xs={12} sm={6}>
              <AvatarField name="avatar" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    name="firstName"
                    label="Имя"
                    rules={{
                      required: true,
                      maxLength: {
                        value: 255,
                        message: 'Не более 255 символов',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="lastName"
                    label="Фамилия"
                    rules={{
                      required: true,
                      maxLength: {
                        value: 255,
                        message: 'Не более 255 символов',
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <EmailField
            name="email"
            label="Email"
            rules={{
              required: true,
              maxLength: {
                value: 255,
                message: 'Не более 255 символов',
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PhoneField rules={{ required: true }} name="phone" label="Телефон" />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={1}>
            <Grid item>
              <Button variant="text" color="primary" onClick={onClose}>
                Отмена
              </Button>
            </Grid>
            <Grid item>
              <Grid
                item
                xs={12}
                container
                justifyContent="center"
                alignItems="center"
              >
                <ButtonContainerWithSpinner isLoading={loading}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={loading}
                  >
                    Сохранить
                  </Button>
                </ButtonContainerWithSpinner>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}
