import { gql, useMutation } from '@apollo/client'

import { ORDER } from 'api'
import { Order } from 'types'

export const DELETE_ORDER_SERVICE = gql`
  mutation DeleteOrderService($id: ID!) {
    deleteOrderService(id: $id) {
      order {
        ...Order
      }
    }
  }
  ${ORDER}
`

export type DeleteOrderServiceData = {
  deleteOrderService: { order: Order }
}

export type DeleteOrderServiceVariables = {
  id: string
}

export const useDeleteOrderServiceMutation = () => {
  return useMutation<DeleteOrderServiceData, DeleteOrderServiceVariables>(
    DELETE_ORDER_SERVICE,
  )
}
