import { gql, useMutation } from '@apollo/client'

import {
  COMPANY,
  INPUT_ERROR,
} from 'api'
import { Company, InputError } from 'types'

export const UPDATE_COMPANY = gql`
  mutation UpdateCompany($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      company {
        ...Company
      }
      errors {
        ...InputError
      }
    }
  }
  ${COMPANY}
  ${INPUT_ERROR}
`

type UpdateCompanyDataSuccess = {
  company: Company
  errors: null
}

type UpdateCompanyDataInputError = {
  company: null
  errors: InputError<'name'>[]
}

export type UpdateCompanyData = {
  updateCompany: UpdateCompanyDataSuccess | UpdateCompanyDataInputError
}

export type UpdateCompanyVariables = {
  input: {
    id: string
    name?: string
    docName?: string
    logo?: File | null
    url?: string
    address?: string
    legalAddress?: string
    mailAddress?: string
    inn?: string
    kpp?: string
    ogrn?: string
    employees?: string[]
  }
}

export const useUpdateCompanyMutation = () => {
  return useMutation<
    UpdateCompanyData,
    UpdateCompanyVariables
  >(UPDATE_COMPANY)
}
