import { User } from 'types'
import { useEditable } from 'utils'

import { Profile } from './Profile'
import { ProfileForm } from './ProfileForm'

type EditableProfileProps = {
  user: User
}

export const EditableProfile = ({ user }: EditableProfileProps) => {
  const { isEditing, handleEdit, handleClose } = useEditable()

  if (isEditing) {
    return <ProfileForm user={user} onClose={handleClose} />
  }

  return <Profile user={user} onEdit={handleEdit} />
}
