import { gql, useMutation } from '@apollo/client'

import { COMPANY, INPUT_ERROR } from 'api'
import { Company, InputError } from 'types'

export const UPDATE_COMPANY_CONTRACT = gql`
  mutation UpdateCompanyContract($input: UpdateCompanyContractInput!) {
    updateCompanyContract(input: $input) {
      company {
        ...Company
      }
      errors {
        ...InputError
      }
    }
  }
  ${COMPANY}
  ${INPUT_ERROR}
`

type UpdateCompanyContractDataSuccess = {
  company: Company
  errors: null
}

type UpdateCompanyContractDataInputError = {
  company: null
  errors: InputError<'content'>[]
}

export type UpdateCompanyContractData = {
  updateCompanyContract:
    | UpdateCompanyContractDataSuccess
    | UpdateCompanyContractDataInputError
}

export type UpdateCompanyContractVariables = {
  input: {
    id: string
    name?: string
    signedAt?: string
    document?: File | null
  }
}

export const useUpdateCompanyContractMutation = () => {
  return useMutation<UpdateCompanyContractData, UpdateCompanyContractVariables>(
    UPDATE_COMPANY_CONTRACT,
  )
}
