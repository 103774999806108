import { useCallback } from 'react'
import { QueryHookOptions, gql, useQuery } from '@apollo/client'

import { PAGE_INFO } from 'api'
import { Order, SortOrder, PageInfo } from 'types'

const ITEMS_PER_PAGE = 30

export const cachePaginatedOrdersKeyArgs = ['search', 'sortOrder']
export const cachePaginatedOrdersItemsMerge = (
  existing: Order[],
  incoming: Order[],
) => {
  return existing ? [...existing, ...incoming] : incoming
}
export type PaginatedOrdersData = {
  paginatedOrders: {
    items: Order[]
    pageInfo: PageInfo
  }
}

export type PaginatedOrdersVariables = {
  sortOrder?: {
    id?: SortOrder
  }
  search?: string
  pagination?: {
    limit?: number
    endCursor?: string
    startCursor?: string
  }
}

export const GET_PAGINATED_ORDERS = gql`
  query GetPaginatedOrders(
    $search: String
    $sortOrder: OrdersSortOrder
    $pagination: PaginationInput
  ) {
    paginatedOrders(
      search: $search
      sortOrder: $sortOrder
      pagination: $pagination
    ) {
      items {
        id
        startAt
        endAt
        company {
          logo
          name
        }
        customer {
          profile {
            avatar
            firstName
            lastName
          }
        }
        manager {
          profile {
            avatar
            firstName
            lastName
          }
        }
        services {
          centsPerUnit
          units
          discount
          currency {
            symbol
          }
        }
        status {
          name
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PAGE_INFO}
`

export const defaultPaginatedOrdersQueryOptions: QueryHookOptions = {
  errorPolicy: 'all',
  notifyOnNetworkStatusChange: true,
}

export function usePaginatedOrdersQuery(
  paginatedOrdersQueryOptions?: QueryHookOptions,
) {
  const { data, loading, error, fetchMore, ...rest } = useQuery<
    PaginatedOrdersData,
    PaginatedOrdersVariables
  >(GET_PAGINATED_ORDERS, {
    ...defaultPaginatedOrdersQueryOptions,
    ...paginatedOrdersQueryOptions,
    variables: {
      ...paginatedOrdersQueryOptions?.variables,
      pagination: {
        limit: ITEMS_PER_PAGE,
        ...paginatedOrdersQueryOptions?.variables?.pagination,
      },
    },
  })

  const endCursor = data?.paginatedOrders.pageInfo.endCursor

  const patchedFetchMore = useCallback(() => {
    if (!loading && !error && endCursor) {
      fetchMore({
        variables: {
          pagination: {
            limit: ITEMS_PER_PAGE,
            ...paginatedOrdersQueryOptions?.variables?.pagination,
            endCursor,
          },
        },
      })
    }
  }, [
    fetchMore,
    endCursor,
    loading,
    error,
    paginatedOrdersQueryOptions?.variables?.pagination,
  ])

  return { data, loading, error, fetchMore: patchedFetchMore, ...rest }
}
