import { gql, useMutation } from '@apollo/client'

import { PERFORMER, INPUT_ERROR } from 'api'
import { Performer, InputError } from 'types'

export const UPDATE_PERFORMER_COMMENT = gql`
  mutation UpdatePerformerComment($input: UpdatePerformerCommentInput!) {
    updatePerformerComment(input: $input) {
      performer {
        ...Performer
      }
      errors {
        ...InputError
      }
    }
  }
  ${PERFORMER}
  ${INPUT_ERROR}
`

type UpdatePerformerCommentDataSuccess = {
  performer: Performer
  errors: null
}

type UpdatePerformerCommentDataInputError = {
  performer: null
  errors: InputError<'content'>[]
}

export type UpdatePerformerCommentData = {
  updatePerformerComment:
    | UpdatePerformerCommentDataSuccess
    | UpdatePerformerCommentDataInputError
}

export type UpdatePerformerCommentVariables = {
  input: {
    id: string
    content: string
  }
}

export const useUpdatePerformerCommentMutation = () => {
  return useMutation<
    UpdatePerformerCommentData,
    UpdatePerformerCommentVariables
  >(UPDATE_PERFORMER_COMMENT)
}
