import { useEditable } from 'utils'
import { Comment } from 'types'

import { OrderComment } from './OrderComment'
import { OrderCommentForm } from './OrderCommentForm'

type EditableOrderCommentProps = {
  comment: Comment
}

export const EditableOrderComment = ({
  comment,
}: EditableOrderCommentProps) => {
  const { isEditing, handleEdit, handleClose } = useEditable()

  if (isEditing) {
    return <OrderCommentForm comment={comment} onClose={handleClose} />
  }

  return <OrderComment comment={comment} onEdit={handleEdit} />
}
