import { gql, useMutation, BaseMutationOptions } from '@apollo/client'

import { resetWebsocketConnection } from 'utils'
import { USER } from 'api'
import { User } from 'types'

export const SIGN_OUT = gql`
  mutation SignOut($input: SignOutInput!) {
    signOut(input: $input) {
      user {
        ...User
      }
    }
  }
  ${USER}
`

export type SignOutData = {
  signOut: {
    user: User
  }
}

export type SignOutVariables = {
  input: {
    accessToken: string
  }
}

export const signOutMutationOptions: BaseMutationOptions<
  SignOutData,
  SignOutVariables
> = {
  onCompleted: () => {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('renewalToken')
    resetWebsocketConnection()
  },
}

export const useSignOutMutation = () => {
  const [signOut, fetchResult] = useMutation<SignOutData, SignOutVariables>(
    SIGN_OUT,
    signOutMutationOptions,
  )

  const sendSignOut = async () => {
    const token = localStorage.getItem('accessToken')

    const result = await signOut({
      variables: { input: { accessToken: token! } },
    })
    await fetchResult.client.resetStore()

    return result
  }

  return [sendSignOut, fetchResult] as const
}
