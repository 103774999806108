import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import { Box, Button, Grid, Link, Typography } from '@mui/material'

import { useSignUpMutation } from 'api'
import {
  ButtonContainerWithSpinner,
  CheckboxField,
  EmailField,
  Field,
  PasswordField,
  PhoneField,
  ModalPage,
  Logo,
} from 'components'
import { PrivacyAgreement } from './PrivacyAgreement'
import { setFormErrors, resetPhoneFormat } from 'utils'

const errorsMessages = {
  email: {
    messages: {
      'has already been taken':
        'Пользователь с таким email уже зарегистрирован',
      'is invalid': 'Неверный email',
    },
    default: 'Нельзя зарегистрироваться с таким email',
  },
  phone: {
    messages: {
      'is invalid': 'Неверный номер телефона',
      'has already been taken':
        'Пользователь с таким телефоном уже зарегистрирован',
    },
    default: 'Нельзя зарегистрироваться с таким номером телефона',
  },
  password: {
    default: 'Нельзя установить такой пароль',
  },
}

type FormData = {
  firstName: string
  lastName: string
  phone: string
  email: string
  password: string
  agreement: boolean
}

export const SignUp = () => {
  const [isConditionsPageVisible, setIsConditionsPageVisible] = useState(false)
  const methods = useForm<FormData>({
    shouldFocusError: true,
    defaultValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      password: '',
      agreement: false,
    },
  })
  const [signUp, { loading }] = useSignUpMutation()

  const onSubmit = methods.handleSubmit(async (formData: FormData) => {
    const { firstName, lastName, phone, email, password } = formData
    const { data } = await signUp({
      variables: {
        input: {
          firstName,
          lastName,
          phone: resetPhoneFormat(phone),
          email,
          password,
        },
      },
    })

    if (data?.signUp?.errors) {
      setFormErrors<Omit<FormData, 'firstName' | 'lastName' | 'agreement'>>(
        methods.setError,
        data.signUp.errors,
        errorsMessages,
      )
    }
  })

  return (
    <FormProvider {...methods}>
      <Grid
        container
        spacing={2}
        noValidate
        onSubmit={onSubmit}
        component="form"
      >
        <Grid item xs={12} container justifyContent="center">
          <Logo />
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <Box my={2}>
            <Typography variant="body1" color="primary">
              Зарегистрируйтесь в ProLing CRM
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Field name="firstName" label="Имя" rules={{ required: true }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field name="lastName" label="Фамилия" rules={{ required: true }} />
        </Grid>
        <Grid item xs={12}>
          <EmailField
            name="email"
            label="Email"
            rules={{
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <PhoneField rules={{ required: true }} name="phone" label="Телефон" />
        </Grid>
        <Grid item xs={12}>
          <PasswordField
            name="password"
            label="Пароль"
            rules={{
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CheckboxField
            name="agreement"
            rules={{
              required: {
                value: true,
                message: 'Необходимо согласие для продолжения',
              },
            }}
            label={
              <Typography variant="body1">
                Принимаю{' '}
                <Link
                  color="primary"
                  onClick={(e: React.MouseEvent<EventTarget>) => {
                    e.preventDefault()
                    setIsConditionsPageVisible(true)
                  }}
                >
                  условия передачи данных
                </Link>
              </Typography>
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
        >
          <ButtonContainerWithSpinner isLoading={loading}>
            <Button variant="contained" type="submit" disabled={loading}>
              Зарегистрироваться
            </Button>
          </ButtonContainerWithSpinner>
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <Link
            variant="body2"
            color="primary"
            component={RouterLink}
            to="/auth/sign-in"
          >
            Уже есть логин
          </Link>
        </Grid>
      </Grid>
      <ModalPage
        onClose={() => {
          setIsConditionsPageVisible(false)
        }}
        submitTitle="Принять условия"
        onSubmit={() => {
          methods.setValue('agreement', true)
        }}
        isVisible={isConditionsPageVisible}
      >
        <PrivacyAgreement />
      </ModalPage>
    </FormProvider>
  )
}
