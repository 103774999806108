import { gql, useMutation } from '@apollo/client'

import { ORDER, INPUT_ERROR } from 'api'
import { Order, InputError } from 'types'

export const CREATE_ORDER_SERVICE = gql`
  mutation CreateOrderService($input: CreateOrderServiceInput!) {
    createOrderService(input: $input) {
      order {
        ...Order
      }
      errors {
        ...InputError
      }
    }
  }
  ${ORDER}
  ${INPUT_ERROR}
`

type CreateOrderServiceDataSuccess = {
  order: Order
  errors: null
}

type CreateOrderServiceDataInputError = {
  order: null
  errors: InputError<'languageServiceId' | 'centsPerUnit' | 'units' | 'name'>[]
}

export type CreateOrderServiceData = {
  createOrderService:
    | CreateOrderServiceDataSuccess
    | CreateOrderServiceDataInputError
}

export type CreateOrderServiceVariables = {
  input: {
    orderId: string
    rateId: string
    currencyId: string
    serviceId: string | null
    languageServiceId: string | null
    name: string
    centsPerUnit: number
    units: number
    discount: number
  }
}

export const useCreateOrderServiceMutation = () => {
  return useMutation<CreateOrderServiceData, CreateOrderServiceVariables>(
    CREATE_ORDER_SERVICE,
  )
}
