import { useMemo, useState, useCallback, MouseEvent } from 'react'
import { InputAdornment, IconButton } from '@mui/material'
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material'

import { Field, FieldProps } from 'components'

export type PasswordFieldProps = FieldProps

export const PasswordField = ({ rules, ...props }: PasswordFieldProps) => {
  const [showPassword, setShowPassword] = useState(false)

  const toggleShowPassword = useCallback(() => {
    setShowPassword((currentShowPassword) => !currentShowPassword)
  }, [setShowPassword])

  const handleMouseDownPassword = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
    },
    [],
  )

  const memoizedRules = useMemo(
    () => ({
      minLength: {
        value: 6,
        message: 'Не меньше 6 символов',
      },
      ...rules,
    }),
    [rules],
  )

  const inputProps = useMemo(
    () => ({
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle visibility"
            onClick={toggleShowPassword}
            onMouseDown={handleMouseDownPassword}
            size="large"
          >
            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
        </InputAdornment>
      ),
    }),
    [toggleShowPassword, handleMouseDownPassword, showPassword],
  )

  return (
    <Field
      type={showPassword ? 'text' : 'password'}
      rules={memoizedRules}
      InputProps={inputProps}
      {...props}
    />
  )
}
