import { gql, useMutation } from '@apollo/client'

import { CUSTOMER, INPUT_ERROR } from 'api'
import { Customer, InputError } from 'types'

export const CREATE_CUSTOMER_COMMENT = gql`
  mutation CreateCustomerComment($input: CreateCustomerCommentInput!) {
    createCustomerComment(input: $input) {
      customer {
        ...Customer
      }
      errors {
        ...InputError
      }
    }
  }
  ${CUSTOMER}
  ${INPUT_ERROR}
`

type CreateCustomerCommentDataSuccess = {
  customer: Customer
  errors: null
}

type CreateCustomerCommentDataInputError = {
  customer: null
  errors: InputError<'content'>[]
}

export type CreateCustomerCommentData = {
  createCustomerComment:
    | CreateCustomerCommentDataSuccess
    | CreateCustomerCommentDataInputError
}

export type CreateCustomerCommentVariables = {
  input: {
    customerId: string
    content: string
  }
}

export const useCreateCustomerCommentMutation = () => {
  return useMutation<CreateCustomerCommentData, CreateCustomerCommentVariables>(
    CREATE_CUSTOMER_COMMENT,
  )
}
