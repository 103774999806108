import { useEditable } from 'utils'
import { Performer } from 'types'

import { PerformerProfile } from './PerformerProfile'
import { PerformerProfileForm } from './PerformerProfileForm'

type EditablePerformerProfileProps = {
  performer: Performer
}

export const EditablePerformerProfile = ({
  performer,
}: EditablePerformerProfileProps) => {
  const { isEditing, handleEdit, handleClose } = useEditable()

  if (isEditing) {
    return <PerformerProfileForm performer={performer} onCancel={handleClose} />
  }

  return <PerformerProfile performer={performer} onEdit={handleEdit} />
}
