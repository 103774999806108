import { ReactNode } from 'react'
import { styled } from '@mui/material/styles'
import { Grid, Container, Paper } from '@mui/material'

const Root = styled('div')`
  overflow: hidden;
  min-height: 100vh;
  display: flex;
`

const StyledPaper = styled(Paper)(
  ({ theme }) => `
    min-width: 320px;
    padding: ${theme.spacing(3)};
    padding-bottom: ${theme.spacing(5)};
    margin-top: ${theme.spacing(3)};
    margin-bottom: ${theme.spacing(5)};
`,
)

type AuthLayoutProps = {
  children: ReactNode
}

export const AuthLayout = ({ children }: AuthLayoutProps) => {
  return (
    <Root>
      <Grid container alignItems="center" justifyContent="center" flexGrow={1}>
        <Grid item xs={12} sm={8} md={7} lg={5} xl={3}>
          <Container>
            <StyledPaper>{children}</StyledPaper>
          </Container>
        </Grid>
      </Grid>
    </Root>
  )
}
