import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Avatar, ListItem, ListItemIcon, ListItemText } from '@mui/material'

import { Customer } from 'types'

type CompanyEmployeeCardProps = {
  employee: Customer
}

export const CompanyEmployeeCard = ({
  employee: {
    id,
    profile: { avatar, firstName, lastName },
  },
}: CompanyEmployeeCardProps) => {
  const navigate = useNavigate()

  const onEmployeeClick = useCallback(() => {
    navigate(`/customers/${id}`, {
      state: {
        backButtonTitle: 'К компании',
      },
    })
  }, [id, navigate])

  return (
    <ListItem button onClick={onEmployeeClick}>
      <ListItemIcon>
        <Avatar src={avatar} sx={{ width: 40, height: 40 }} />
      </ListItemIcon>
      <ListItemText primary={`${firstName} ${lastName}`} />
    </ListItem>
  )
}
