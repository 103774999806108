import { Divider, Grid, IconButton, Stack, Typography } from '@mui/material'
import { Edit as EditIcon } from '@mui/icons-material'

import { PerformerService as TPerformerService } from 'types'

type PerformerServiceProps = {
  service: TPerformerService
  onEdit?: () => void
}

export const PerformerService = ({
  service,
  onEdit,
}: PerformerServiceProps) => {
  return (
    <Grid container spacing={1} alignItems="center" wrap="nowrap">
      <Grid item flexGrow={1}>
        <Grid container spacing={1} direction="column">
          <Grid item xs={12}>
            <Typography variant="caption" color="primary">
              {service.type.group.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={12} sm="auto">
                <Typography variant="body1">{service.type.name}</Typography>
              </Grid>
              {service.centsPerUnit &&
                service.currency &&
                !service.languageServices.length && (
                  <Grid item>
                    <Typography variant="body2">
                      {`${service.centsPerUnit / 1_000}\u00a0${
                        service.currency.symbol
                      }\u00a0за\u00a0${service.type.rate.forOneName}`}
                    </Typography>
                  </Grid>
                )}
              {service.languageServices.length > 0 && (
                <Grid item xs={12}>
                  <Divider flexItem light sx={{ mb: 2 }} />
                  <Stack
                    spacing={2}
                    direction="column"
                    divider={<Divider light variant="middle" />}
                  >
                    {service.languageServices.map((languageService) => (
                      <div key={languageService.id}>
                        <Grid
                          container
                          spacing={2}
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Grid item xs={12} sm="auto">
                            <Typography variant="body1">
                              {`${languageService.sourceLanguage.name}\u00a0-\u00a0${languageService.targetLanguage?.name}`}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="body2">
                              {`${languageService.centsPerUnit / 1_000}\u00a0${
                                languageService.currency.symbol
                              }\u00a0за\u00a0${service.type.rate.name}`}
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    ))}
                  </Stack>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <IconButton aria-label="Редактировать" onClick={onEdit} size="large">
          <EditIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}
