import { useEffect } from 'react'
import { gql, useApolloClient } from '@apollo/client'

import { CUSTOMER } from 'api'
import { Customer } from 'types'

type CustomerUpdatedData = {
  customer: Customer
}

type CustomerUpdatedVariables = {
  customerId: string
}

export const CUSTOMER_UPDATED = gql`
  subscription($customerId: ID!) {
    customerUpdated(customerId: $customerId) {
      ...Customer
    }
  }
  ${CUSTOMER}
`

export const useCustomerUpdatedSubscription = (customerId?: string) => {
  const client = useApolloClient()

  useEffect(() => {
    if (customerId) {
      const updatedCustomerSubscription = client
        .subscribe<CustomerUpdatedData, CustomerUpdatedVariables>({
          query: CUSTOMER_UPDATED,
          variables: { customerId },
        })
        .subscribe(() => {})

      return () => {
        updatedCustomerSubscription.unsubscribe()
      }
    }
  })
}
