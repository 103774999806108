import { gql, useMutation } from '@apollo/client'

import { EXPERIENCE, INPUT_ERROR } from 'api'
import { Experience, InputError } from 'types'

export const UPDATE_EXPERIENCE = gql`
  mutation UpdateExperience($input: UpdateExperienceInput!) {
    updateExperience(input: $input) {
      experience {
        ...Experience
      }
      errors {
        ...InputError
      }
    }
  }
  ${EXPERIENCE}
  ${INPUT_ERROR}
`

type UpdateExperienceDataSuccess = {
  experience: Experience
  errors: null
}

type UpdateExperienceDataInputError = {
  experience: null
  errors: InputError<'startYear' | 'endYear'>[]
}

export type UpdateExperienceData = {
  updateExperience: UpdateExperienceDataSuccess | UpdateExperienceDataInputError
}

export type UpdateExperienceVariables = {
  input: {
    id: string
    organizationName?: string
    countryId?: string
    position?: string
    startYear?: number
    endYear: number | null
    achievements?: string
  }
}

export const useUpdateExperienceMutation = () => {
  return useMutation<UpdateExperienceData, UpdateExperienceVariables>(
    UPDATE_EXPERIENCE,
  )
}
