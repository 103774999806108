import { QueryHookOptions, gql, useQuery } from '@apollo/client'

import { CUSTOMER } from 'api'
import { Customer } from 'types'

export type CustomerData = {
  customer: Customer
}

export type CustomerVariables = {
  id: string
}

export const GET_CUSTOMERS = gql`
  query GetCustomer($id: ID!) {
    customer(id: $id) {
      ...Customer
    }
  }
  ${CUSTOMER}
`

export const defaultCustomerQueryOptions: QueryHookOptions<
  CustomerData,
  CustomerVariables
> = {
  errorPolicy: 'all',
}

export function useCustomerQuery(
  customerId: string,
  customerQueryOptions?: Omit<
    QueryHookOptions<CustomerData, CustomerVariables>,
    'variables'
  >,
) {
  return useQuery<CustomerData, CustomerVariables>(GET_CUSTOMERS, {
    ...defaultCustomerQueryOptions,
    ...customerQueryOptions,
    variables: {
      id: customerId,
    },
  })
}
