import { useCallback, useMemo, useState, SyntheticEvent } from 'react'

import { AutocompleteField, AutocompleteFieldProps } from 'components'
import { usePaginatedCustomersQuery } from 'api'
import type { Customer } from 'types'

export type CustomerFieldProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> = Omit<
  AutocompleteFieldProps<Customer, Multiple, DisableClearable, FreeSolo>,
  'loading' | 'options' | 'loading'
>

const getCustomerLabel = ({ profile }: Customer) => {
  return `${profile.firstName} ${profile.lastName}`
}

const isOptionEqualToValue = (option: Customer, value: Customer) => {
  return option.id === value.id
}

const filterOptions = (options: Customer[]) => options

export function CustomerField<Multiple extends boolean = false>(
  props: CustomerFieldProps<Multiple, false, false>,
) {
  const [search, setSearch] = useState('')

  const { data, loading } = usePaginatedCustomersQuery({
    variables: { search },
  })

  const options = useMemo(() => data?.paginatedCustomers.items || [], [data])

  const handleInputChange = useCallback(
    (_e: SyntheticEvent, value: string) => {
      setSearch(value)
    },
    [setSearch],
  )

  return (
    <AutocompleteField<Customer, Multiple, false, false>
      loading={loading}
      options={options}
      onInputChange={handleInputChange}
      getOptionLabel={getCustomerLabel}
      filterOptions={filterOptions}
      isOptionEqualToValue={isOptionEqualToValue}
      {...props}
    />
  )
}
