import {
  BaseMutationOptions,
  gql,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client'

import { resetWebsocketConnection } from 'utils'
import { USER, SESSION, INPUT_ERROR } from 'api'
import { User, Session, InputError } from 'types'

export const RESET_PASSWORD = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      session {
        ...Session
      }
      user {
        ...User
      }
      errors {
        ...InputError
      }
    }
  }
  ${USER}
  ${SESSION}
  ${INPUT_ERROR}
`

type ResetPasswordDataSuccess = {
  session: Session
  user: User
  errors: null
}

type ResetPasswordDataInputError = {
  session: null
  user: null
  errors: InputError<'password'>[]
}

export type ResetPasswordData = {
  resetPassword: ResetPasswordDataSuccess | ResetPasswordDataInputError
}

export type ResetPasswordVariables = {
  input: {
    password: string
    token: string
  }
}

export const resetPasswordMutationOptions: BaseMutationOptions<
  ResetPasswordData,
  ResetPasswordVariables
> = {
  errorPolicy: 'all',
  onCompleted: (data) => {
    if (data.resetPassword?.session) {
      const { accessToken, renewalToken } = data.resetPassword.session
      localStorage.setItem('accessToken', accessToken)
      localStorage.setItem('renewalToken', renewalToken)
      resetWebsocketConnection()
    }
  },
}

export const useResetPasswordMutation = () => {
  const [resetPassword, fetchResult] = useMutation<
    ResetPasswordData,
    ResetPasswordVariables
  >(RESET_PASSWORD, resetPasswordMutationOptions)
  const sendResetPassword = async (
    mutationOptions: MutationFunctionOptions<
      ResetPasswordData,
      ResetPasswordVariables
    >,
  ) => {
    const result = await resetPassword(mutationOptions)
    if (result.data?.resetPassword?.session)
      await fetchResult.client.resetStore()
    return result
  }

  return [sendResetPassword, fetchResult] as const
}
