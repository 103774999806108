import { MultipleEditableRoot, MultipleEditableItem } from 'components'
import { useMultipleForms } from 'utils'
import { Company } from 'types'

import { CompanyContractForm } from './CompanyContractForm'
import { EditableCompanyContract } from './EditableCompanyContract'

type EditableCompanyContractsProps = {
  company: Company
}

export const EditableCompanyContracts = ({
  company,
}: EditableCompanyContractsProps) => {
  const { forms, addForm, removeForm } = useMultipleForms()

  const buttonTitle =
    company.contracts.length > 0 || forms.length > 0
      ? 'Добавить ещё'
      : 'Добавить'

  const isDeletable = company.contracts.length > 1

  return (
    <MultipleEditableRoot onAddForm={addForm} buttonTitle={buttonTitle}>
      {company.contracts.map((contract) => (
        <MultipleEditableItem key={contract.id}>
          <EditableCompanyContract
            contract={contract}
            isDeletable={isDeletable}
          />
        </MultipleEditableItem>
      ))}
      {forms.map((itemKey) => (
        <MultipleEditableItem key={itemKey}>
          <CompanyContractForm
            onClose={removeForm(itemKey)}
            companyId={company.id}
          />
        </MultipleEditableItem>
      ))}
    </MultipleEditableRoot>
  )
}
