import { useCallback, SyntheticEvent } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { format } from 'date-fns'
import { Button, Grid } from '@mui/material'

import { useUpdatePerformerMutation } from 'api'
import {
  ButtonContainerWithSpinner,
  CountrySelectField,
  DateField,
  Field,
  GenderSelectField,
  LanguageSelectField,
  UploadField,
} from 'components'
import { Performer } from 'types'

type PerformerInfoFormData = {
  id: string
  bio: string
  cv?: File | string
  nativeLanguageId: string
  city: string
  birthday: Date | null
  countryId: string
  genderId: string
}

type PerformerInfoFormProps = {
  performer: Performer
  onSubmit?: () => void
  onCancel?: () => void
}

const acceptedExtensions = ['pdf']

export const PerformerInfoForm = ({
  performer,
  onSubmit: onSubmitAction,
  onCancel,
}: PerformerInfoFormProps) => {
  const [updatePerformer] = useUpdatePerformerMutation()

  const methods = useForm<PerformerInfoFormData>({
    defaultValues: {
      bio: performer.bio,
      cv: performer.cv,
      nativeLanguageId: performer.nativeLanguage.id,
      city: performer.profile.city,
      birthday: performer.profile.birthday
        ? new Date(performer.profile.birthday)
        : null,
      countryId: performer.profile.country.id,
      genderId: performer.profile.gender.id,
    },
    mode: 'onBlur',
  })

  const onSubmit = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault()

      await methods.handleSubmit(async (formData) => {
        const {
          city,
          birthday: rawBirthday,
          nativeLanguageId,
          countryId,
          genderId,
          bio,
          cv,
        } = formData

        const birthday = rawBirthday ? format(rawBirthday, 'yyyy-MM-dd') : ''

        await updatePerformer({
          variables: {
            input: {
              id: performer.id,
              bio,
              nativeLanguageId,
              // URL of existing cv is not valid value
              ...(typeof cv !== 'string' && { cv }),
              profile: {
                city,
                birthday,
                countryId,
                genderId,
              },
            },
          },
        })

        if (onSubmitAction) onSubmitAction()
        if (onCancel) onCancel()
      })()
    },
    [methods, updatePerformer, onSubmitAction, onCancel, performer.id],
  )

  return (
    <FormProvider {...methods}>
      <Grid
        container
        spacing={2}
        noValidate
        autoComplete="off"
        onSubmit={onSubmit}
        component="form"
      >
        <Grid item xs={12} sm={6}>
          <CountrySelectField
            name="countryId"
            rules={{ required: true }}
            label="Страна проживания"
            hasEmptyOption
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name="city"
            label="Город проживания"
            rules={{
              required: true,
              maxLength: {
                value: 255,
                message: 'Не более 255 символов',
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LanguageSelectField
            name="nativeLanguageId"
            rules={{ required: true }}
            label="Родной язык"
            hasEmptyOption
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <GenderSelectField
            name="genderId"
            rules={{ required: true }}
            label="Пол"
            hasEmptyOption
          />
        </Grid>
        <Grid item xs={12}>
          <DateField
            name="birthday"
            label="Дата рождения"
            rules={{ required: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <Field name="bio" label="Немного обо мне" multiline minRows={3} />
        </Grid>
        <Grid item xs={12}>
          <UploadField
            name="cv"
            label="Загрузить резюме"
            labelActive="Приложите резюме"
            accept={acceptedExtensions}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={1}>
            {onCancel && (
              <Grid item>
                <Button variant="text" color="primary" onClick={onCancel}>
                  Отмена
                </Button>
              </Grid>
            )}
            <Grid item>
              <ButtonContainerWithSpinner
                isLoading={methods.formState.isSubmitting}
              >
                <Button
                  variant="text"
                  color="primary"
                  type="submit"
                  disabled={methods.formState.isSubmitting}
                >
                  Сохранить
                </Button>
              </ButtonContainerWithSpinner>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}
