import { useCallback, useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { Grid, IconButton, InputAdornment, Typography } from '@mui/material'
import { Clear as ClearIcon } from '@mui/icons-material'
import useInfiniteScroll from 'react-infinite-scroll-hook'

import { usePaginatedCustomersQuery } from 'api'
import { Field, FixedButton, Spinner } from 'components'
import { useDebounce, useInitialSearchParams } from 'utils'
import { CustomerCard } from './CustomerCard'

const getCustomersFooterText = (itemsLength: number, totalCount: number) => {
  if (totalCount === 0) return 'К сожалению, никого не найдено по запросу'
  if (itemsLength === totalCount) return 'Это все клиенты, найденные по запросу'
  return ''
}

type CustomersSearchFormData = {
  search: string
}

export const CustomersList = () => {
  const initialSearchParams = useInitialSearchParams()
  const [, setSearchParams] = useSearchParams()

  const methods = useForm<CustomersSearchFormData>({
    defaultValues: {
      search: initialSearchParams.get('search') || '',
    },
    mode: 'onBlur',
    shouldUnregister: false,
  })

  const { search } = useDebounce<CustomersSearchFormData>(methods.watch(), 300)

  const variables = useMemo(
    () => ({
      ...(search && { search }),
    }),
    [search],
  )

  const {
    data: paginatedCustomersData,
    error: paginatedCustomersError,
    loading: paginatedCustomersLoading,
    fetchMore,
  } = usePaginatedCustomersQuery({
    variables,
  })

  const paginatedCustomersHasNextPage = Boolean(
    paginatedCustomersData?.paginatedCustomers.pageInfo.endCursor,
  )

  const [sentryRef] = useInfiniteScroll({
    loading: paginatedCustomersLoading,
    hasNextPage: paginatedCustomersHasNextPage,
    onLoadMore: fetchMore,
    disabled: Boolean(paginatedCustomersError),
    rootMargin: '0px 0px 400px 0px',
  })

  useEffect(() => {
    setSearchParams({ ...(search && { search }) })
  }, [setSearchParams, search])

  const resetSearchField = useCallback(() => {
    methods.resetField('search')
  }, [methods])

  return (
    <>
      <FormProvider {...methods}>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="stretch"
          sx={{ mt: 1 }}
        >
          <Grid item xs={12} flexGrow={1}>
            <Field
              name="search"
              label="Поиск"
              withoutHelperText
              {...(search && {
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="clear search input"
                        onClick={resetSearchField}
                        size="small"
                        edge="end"
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              })}
            />
          </Grid>
          {paginatedCustomersLoading && !paginatedCustomersData ? (
            <Grid item xs={12}>
              <Spinner />
            </Grid>
          ) : (
            paginatedCustomersData && (
              <>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {paginatedCustomersData.paginatedCustomers.items.map(
                      (customer) => (
                        <Grid item xs={12} key={customer.id}>
                          <CustomerCard customer={customer} />
                        </Grid>
                      ),
                    )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  alignItems="flex-start"
                  justifyContent="center"
                  sx={{ height: 80 }}
                >
                  {paginatedCustomersHasNextPage ||
                  paginatedCustomersLoading ? (
                    <div ref={sentryRef} />
                  ) : null}
                  {paginatedCustomersLoading ? (
                    <Spinner />
                  ) : (
                    <Typography variant="body1" color="primary">
                      {getCustomersFooterText(
                        paginatedCustomersData.paginatedCustomers.items.length,
                        paginatedCustomersData.paginatedCustomers.pageInfo
                          .totalCount,
                      )}
                    </Typography>
                  )}
                </Grid>
              </>
            )
          )}
        </Grid>
      </FormProvider>
      <FixedButton ariaLabel="Add customer" to="/customers/create" />
    </>
  )
}
