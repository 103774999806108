import { QueryHookOptions, gql, useQuery } from '@apollo/client'

import { PERFORMER_SERVICE_TYPE } from 'api'
import { PerformerServiceType } from 'types'

export type PerformerServiceTypesData = {
  performerServiceTypes: PerformerServiceType[]
}

export const GET_PERFORMER_SERVICE_TYPES = gql`
  query GetPerformerServiceTypes {
    performerServiceTypes {
      ...PerformerServiceType
    }
  }
  ${PERFORMER_SERVICE_TYPE}
`

export const performerServiceTypesQueryOptions: QueryHookOptions<PerformerServiceTypesData> =
  {
    errorPolicy: 'all',
  }

export function usePerformerServiceTypesQuery() {
  return useQuery<PerformerServiceTypesData>(
    GET_PERFORMER_SERVICE_TYPES,
    performerServiceTypesQueryOptions,
  )
}
