import { Link as RouterLink } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  Link,
  Typography,
} from '@mui/material'

import {
  ButtonContainerWithSpinner,
  EmailField,
  PasswordField,
  Logo,
} from 'components'

import { useSignInMutation } from 'api'

type FormData = {
  email: string
  password: string
}

export const SignIn = () => {
  const methods = useForm<FormData>({
    defaultValues: { email: '', password: '' },
    shouldFocusError: true,
  })
  const [signIn, { loading, error }] = useSignInMutation()

  const onSubmit = methods.handleSubmit(async (formData: FormData) => {
    const { email, password } = formData

    await signIn({ variables: { input: { email, password } } })
  })

  return (
    <FormProvider {...methods}>
      <Grid
        container
        spacing={2}
        noValidate
        onSubmit={onSubmit}
        component="form"
      >
        <Grid item xs={12} container justifyContent="center">
          <Logo />
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <Box my={2}>
            <Typography variant="body1" color="primary">
              Войдите в ProLing CRM
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <EmailField name="email" label="Email" rules={{ required: true }} />
        </Grid>
        <Grid item xs={12}>
          <PasswordField
            name="password"
            label="Пароль"
            rules={{
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <FormHelperText error>
            {error?.graphQLErrors.map(({ message }, i) => (
              <span key={i}>
                {message === 'incorrect email or password'
                  ? 'Неверный email или пароль'
                  : 'Произошла неизвестная ошибка. Попробуйте ещё раз позднее'}
              </span>
            )) || ' '}
          </FormHelperText>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
        >
          <ButtonContainerWithSpinner isLoading={loading}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
            >
              Войти
            </Button>
          </ButtonContainerWithSpinner>
        </Grid>
        <Grid item xs={12} container justifyContent="space-between">
          <Link
            variant="body2"
            color="primary"
            component={RouterLink}
            to="/auth/sign-up"
          >
            Зарегистрироваться
          </Link>
          <Link
            variant="body2"
            color="primary"
            component={RouterLink}
            to="/auth/password-recovery"
          >
            Забыли пароль?
          </Link>
        </Grid>
      </Grid>
    </FormProvider>
  )
}
