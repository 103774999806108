import React from 'react'
import { createRoot } from 'react-dom/client'
import { ApolloProvider } from '@apollo/client'
import { CssBaseline } from '@mui/material'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import {
  ErrorBoundary,
  CustomSnackbarProvider,
  ThemeProvider,
} from 'components'
import { apiClient } from 'utils'

import App from './App'
import * as serviceWorker from './serviceWorker'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://50fda43629a4482daa069a7cfe0e76fe@o496384.ingest.sentry.io/5570700',
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}

const container = document.getElementById('root') as HTMLDivElement
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <ThemeProvider>
      <CssBaseline />
      <CustomSnackbarProvider>
        <ApolloProvider client={apiClient}>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </ApolloProvider>
      </CustomSnackbarProvider>
    </ThemeProvider>
  </React.StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
