import { QueryHookOptions, gql, useQuery } from '@apollo/client'

import { GENDER } from 'api'
import { Gender } from 'types'

export type GendersData = {
  genders: Gender[]
}

export const GET_GENDERS = gql`
  query GetGenders {
    genders {
      ...Gender
    }
  }
  ${GENDER}
`

export const gendersQueryOptions: QueryHookOptions<GendersData> = {
  errorPolicy: 'all',
}

export function useGendersQuery() {
  return useQuery<GendersData>(GET_GENDERS, gendersQueryOptions)
}
