import { useCallback, FocusEvent, SyntheticEvent, HTMLAttributes } from 'react'
import { useFormContext, useController, RegisterOptions } from 'react-hook-form'
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteValue,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Checkbox,
  Typography,
} from '@mui/material'
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from '@mui/icons-material'

import { RawField, RawFieldProps } from 'components'
import { mergeRefs } from 'utils'

export type AutocompleteFieldProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> = Omit<
  AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  'renderInput' | 'value'
> & {
  name: string
  label: string
  rules?: RegisterOptions
  inputProps?: Omit<RawFieldProps, 'name' | 'label' | 'value'>
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

function renderOption<T>(getOptionLabel?: (option: T) => string) {
  return (
    props: HTMLAttributes<HTMLLIElement>,
    option: T,
    { selected }: { selected: boolean },
  ) => (
    <li {...props}>
      <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
      <Typography variant="body1" sx={{ ml: 1 }}>
        {getOptionLabel?.(option) || (typeof option === 'string' && option)}
      </Typography>
    </li>
  )
}

export function AutocompleteField<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  rules,
  onChange,
  inputProps,
  label,
  openOnFocus = true,
  noOptionsText = 'Нет совпадений',
  loadingText = 'Ищем совпадения...',
  ...props
}: AutocompleteFieldProps<T, Multiple, DisableClearable, FreeSolo>) {
  const { control } = useFormContext()

  const { field } = useController({ control, name, rules })

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      field.onBlur()
      inputProps?.onBlur?.(e)
    },
    [field, inputProps],
  )

  const handleChange = useCallback(
    (
      e: SyntheticEvent,
      value: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>,
      reason: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<T>,
    ) => {
      field.onChange(value)
      onChange?.(e, value, reason, details)
    },
    [field, onChange],
  )

  return (
    <Autocomplete<T, Multiple, DisableClearable, FreeSolo>
      value={field.value}
      noOptionsText={noOptionsText}
      loadingText={loadingText}
      openOnFocus={openOnFocus}
      onChange={handleChange}
      renderInput={(renderInputProps) => (
        <RawField
          name={name}
          label={label}
          {...renderInputProps}
          {...inputProps}
          onBlur={handleBlur}
          inputRef={
            inputProps?.inputRef
              ? mergeRefs([field.ref, inputProps.inputRef])
              : field.ref
          }
        />
      )}
      {...(props.multiple && {
        renderOption: renderOption<T>(props.getOptionLabel),
      })}
      {...props}
    />
  )
}
