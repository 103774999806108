import { useRef, useEffect, ReactNode } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material'

export type ModalPageProps = {
  isVisible: boolean
  onClose: () => void
  title?: string
  onSubmit?: () => void
  submitTitle?: string
  cancelTitle?: string
  children: ReactNode
}

export const ModalPage = ({
  isVisible,
  onClose,
  onSubmit,
  title,
  children,
  submitTitle = 'Принять',
  cancelTitle = 'Закрыть',
}: ModalPageProps) => {
  const descriptionElementRef = useRef<HTMLElement>(null)

  useEffect(() => {
    if (isVisible) {
      if (descriptionElementRef.current !== null) {
        descriptionElementRef.current.focus()
      }
    }
  }, [isVisible])

  return (
    <Dialog
      open={isVisible}
      onClose={onClose}
      scroll="paper"
      maxWidth="md"
      fullWidth
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      {title && <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>}
      <DialogContent
        dividers
        id="scroll-dialog-description"
        ref={descriptionElementRef}
        tabIndex={-1}
      >
        {children}
      </DialogContent>
      <DialogActions>
        {onSubmit && (
          <Button
            onClick={() => {
              onSubmit()
              onClose()
            }}
            variant="contained"
            color="primary"
          >
            {submitTitle}
          </Button>
        )}
        <Button onClick={onClose} color="primary">
          {cancelTitle}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
