import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from '@mui/material'

import type { Service } from 'types'
import { pluralize } from 'utils'

type ServiceCardProps = {
  service: Service
}

export const ServiceCard = ({ service }: ServiceCardProps) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const search = searchParams.get('search')

  const handleClick = useCallback(() => {
    const backButtonTitle = search ? 'К результатам поиска' : 'К услугам'

    navigate(`/services/${service.id}`, {
      state: {
        backButtonTitle,
      },
    })
  }, [navigate, service, search])

  const languageServicesCount = service.languageServices.length

  const languageServicesText = `${languageServicesCount}\u00a0${pluralize(
    languageServicesCount,
    ['языковая услуга', 'языковые услуги', 'языковых услуг'],
  )}`

  return (
    <Card>
      <CardActionArea
        onClick={handleClick}
        sx={{ display: 'flex', justifyContent: 'flex-start' }}
      >
        <CardContent sx={{ display: 'flex', flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} container spacing={2} alignItems="center">
              <Grid item flexGrow={1} container alignItems="center" spacing={1}>
                <Grid item flexGrow={1}>
                  <Typography variant="body1">{service.name}</Typography>
                </Grid>
                {service.centsPerUnit &&
                  service.currency &&
                  !service.languageServices.length && (
                    <Grid item>
                      <Typography variant="body1">
                        {`${service.centsPerUnit / 1_000}\u00a0${
                          service.currency.symbol
                        }\u00a0за\u00a0${service.rate.forOneName}`}
                      </Typography>
                    </Grid>
                  )}
                {languageServicesCount > 0 && (
                  <Grid item>
                    <Typography variant="caption">
                      {languageServicesText}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
