export const Logo = ({ width = '150' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    viewBox="0 0 476.586 256.902"
  >
    <path
      fill="#F0F"
      d="M152.104 38.285V77.35c-2.533-1.4-4.833-2.1-6.9-2.1-6.6 0-9.9 5.067-9.9 15.2v46.2h-36.6V83.42c0-14.066 3.833-25.267 11.533-33.633 7.667-8.367 17.933-12.534 30.733-12.534 2.868 0 6.568.334 11.134 1.035zm74.5-1.034c13.833 0 25.667 4.968 35.533 14.9 9.9 9.935 14.833 21.834 14.833 35.7 0 14.067-5 26.067-15 35.967-10.033 9.9-22.1 14.833-36.233 14.833-14.167 0-26.267-4.967-36.3-14.9-10.034-9.933-15.067-21.9-15.067-35.9 0-14.266 5.033-26.266 15.067-36 10.034-9.732 22.434-14.6 37.167-14.6zm-.966 36c-3.933 0-7.233 1.435-9.966 4.268-2.733 2.833-4.1 6.3-4.1 10.433 0 4.034 1.367 7.5 4.133 10.4 2.767 2.868 6.066 4.3 9.933 4.3 3.9 0 7.233-1.433 10-4.3 2.767-2.9 4.134-6.366 4.134-10.4 0-4.132-1.367-7.6-4.1-10.432-2.734-2.833-6.068-4.267-10.034-4.267z"
    />
    <path
      fill="#2B2A29"
      d="M134.52 78.403h39.633v79.2c0 6.167 1 10.3 3 12.367 1.966 2.033 5.9 3.066 11.767 3.066h2.066v38.667H180.42c-14.467 0-25.733-4.2-33.8-12.634-8.066-8.435-12.1-20.134-12.1-35.135V78.403zm108.632 35.933v97.367H206.62v-97.367h36.532zM224.986 70.87c4.934 0 9.234 1.733 12.833 5.233 3.632 3.467 5.433 7.6 5.433 12.333 0 5.1-1.7 9.267-5.134 12.5-3.4 3.267-7.8 4.9-13.134 4.9-5.333 0-9.733-1.633-13.133-4.9-3.433-3.233-5.133-7.4-5.133-12.5 0-4.733 1.8-8.867 5.434-12.333 3.598-3.5 7.9-5.233 12.832-5.233zm131.466 140.833H319.92v-54.967c0-5.6-2.3-8.4-6.934-8.4-4.567 0-6.834 2.8-6.834 8.4v54.967H269.62v-54.3c0-12.7 4.2-23.367 12.6-32.067 8.4-8.7 18.7-13.033 30.866-13.033 13.934 0 25.1 5.033 33.5 15.133 6.566 7.867 9.866 18.833 9.866 32.9v51.367zm78.234-40.134v33.5c-5.267 1.966-10.333 2.933-15.133 2.933-11.8 0-21.767-4.467-29.934-13.367-8.168-8.933-12.268-19.833-12.268-32.733 0-13.933 4.9-25.666 14.733-35.233s21.934-14.367 36.267-14.367c7.6 0 14.9 1.533 21.866 4.6 6.967 3.033 12.434 7.133 16.4 12.3 6.633 8.5 9.967 20.866 9.967 37.1v32.033c0 17.367-4.934 31.467-14.8 42.3-9.867 10.834-22.7 16.267-38.534 16.267-4.832 0-10.898-1.2-18.265-3.6v-33.2c4.767 2.6 9.566 3.9 14.466 3.9 6.568 0 11.635-2.167 15.234-6.5 3.567-4.334 5.367-10.533 5.367-18.6v-36.6c0-10.234-4.133-15.335-12.4-15.335-3.532 0-6.6 1.367-9.232 4.067-2.633 2.7-3.967 5.8-3.967 9.3 0 3.733 1.27 6.8 3.768 9.3 2.5 2.467 5.634 3.7 9.334 3.7 2.468 0 4.867-.6 7.134-1.766zM174.063 78.39h-39.427v-3.6h39.427v3.6z"
    />
    <path
      fill="#F0F"
      d="M46.2 90.5v-35c8 0 12-3.033 12-9.133C58.2 40.8 55.133 38 48.967 38c-6.233 0-9.367 3.833-9.367 11.467V136.6H0V53.566c0-8.6.867-15.633 2.6-21.133 1.7-5.5 4.667-10.6 8.833-15.367C16.133 11.8 22 7.633 29.033 4.6 36.067 1.533 43.333 0 50.8 0c13.567 0 24.967 4.566 34.167 13.667 9.233 9.1 13.833 20.4 13.833 33.867 0 13.1-4.133 23.7-12.4 31.833-7.534 7.5-18.933 11.233-34.133 11.233l-6.067-.1z"
    />
  </svg>
)
