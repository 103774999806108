import { gql, useMutation } from '@apollo/client'

import { GET_PAGINATED_ORDERS, ORDER, INPUT_ERROR } from 'api'
import { Order, InputError } from 'types'

export const CREATE_ORDER = gql`
  mutation CreateOrder($input: CreateOrderInput!) {
    createOrder(input: $input) {
      order {
        ...Order
      }
      errors {
        ...InputError
      }
    }
  }
  ${ORDER}
  ${INPUT_ERROR}
`

type CreateOrderDataSuccess = {
  order: Order
  errors: null
}

type CreateOrderDataInputError = {
  order: null
  errors: InputError<'startAt' | 'endAt'>[]
}

export type CreateOrderData = {
  createOrder: CreateOrderDataSuccess | CreateOrderDataInputError
}

export type CreateOrderVariables = {
  input: {
    startAt: string
    endAt?: string
    customerId: string
    companyId?: string | null
  }
}

export const useCreateOrderMutation = () => {
  return useMutation<CreateOrderData, CreateOrderVariables>(CREATE_ORDER, {
    refetchQueries: [GET_PAGINATED_ORDERS],
  })
}
