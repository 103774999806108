import { useCallback } from 'react'
import { QueryHookOptions, gql, useQuery } from '@apollo/client'

import { PAGE_INFO } from 'api'
import { Customer, SortOrder, PageInfo } from 'types'

const ITEMS_PER_PAGE = 30

export const cachePaginatedCustomersKeyArgs = ['search', 'sortOrder']
export const cachePaginatedCustomersItemsMerge = (
  existing: Customer[],
  incoming: Customer[],
) => {
  return existing ? [...existing, ...incoming] : incoming
}
export type PaginatedCustomersData = {
  paginatedCustomers: {
    items: Customer[]
    pageInfo: PageInfo
  }
}

export type PaginatedCustomersVariables = {
  sortOrder?: {
    firstName?: SortOrder
    lastName?: SortOrder
  }
  search?: string
  pagination?: {
    limit?: number
    endCursor?: string
    startCursor?: string
  }
}

export const GET_PAGINATED_CUSTOMERS = gql`
  query GetPaginatedCustomers(
    $search: String
    $sortOrder: CustomersSortOrder
    $pagination: PaginationInput
  ) {
    paginatedCustomers(
      search: $search
      sortOrder: $sortOrder
      pagination: $pagination
    ) {
      items {
        id
        profile {
          id
          avatar
          firstName
          lastName
          email
          phone
          birthday
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PAGE_INFO}
`

export const defaultPaginatedCustomersQueryOptions: QueryHookOptions = {
  errorPolicy: 'all',
  notifyOnNetworkStatusChange: true,
}

export function usePaginatedCustomersQuery(
  paginatedCustomersQueryOptions?: QueryHookOptions,
) {
  const { data, loading, error, fetchMore, ...rest } = useQuery<
    PaginatedCustomersData,
    PaginatedCustomersVariables
  >(GET_PAGINATED_CUSTOMERS, {
    ...defaultPaginatedCustomersQueryOptions,
    ...paginatedCustomersQueryOptions,
    variables: {
      ...paginatedCustomersQueryOptions?.variables,
      pagination: {
        limit: ITEMS_PER_PAGE,
        ...paginatedCustomersQueryOptions?.variables?.pagination,
      },
    },
  })

  const endCursor = data?.paginatedCustomers.pageInfo.endCursor

  const patchedFetchMore = useCallback(() => {
    if (!loading && !error && endCursor) {
      fetchMore({
        variables: {
          pagination: {
            limit: ITEMS_PER_PAGE,
            ...paginatedCustomersQueryOptions?.variables?.pagination,
            endCursor,
          },
        },
      })
    }
  }, [
    fetchMore,
    endCursor,
    loading,
    error,
    paginatedCustomersQueryOptions?.variables?.pagination,
  ])

  return { data, loading, error, fetchMore: patchedFetchMore, ...rest }
}
