import { QueryHookOptions, gql, useQuery } from '@apollo/client'

import { SERVICE, LANGUAGE_SERVICE } from 'api'
import { Service, LanguageService, SortOrder } from 'types'

export type HybridService =
  | (Service & { __typename: 'Service' })
  | (LanguageService & { service: Service; __typename: 'LanguageService' })

export type HybridSearchServicesData = { hybridSearchServices: HybridService[] }

export type HybridSearchServicesVariables = {
  search?: string
  sortOrder?: {
    id?: SortOrder
  }
}

export const GET_HYBRID_SEARCH_SERVICES = gql`
  query GetHybridSearchServices(
    $search: String!
    $sortOrder: HybridSearchServicesSortOrder
  ) {
    hybridSearchServices(search: $search, sortOrder: $sortOrder) {
      ... on Service {
        ...Service
      }
      ... on LanguageService {
        ...LanguageService
        service {
          ...Service
        }
      }
      __typename
    }
  }
  ${SERVICE}
  ${LANGUAGE_SERVICE}
`

export const defaultHybridSearchServicesQueryOptions: QueryHookOptions = {
  errorPolicy: 'all',
  notifyOnNetworkStatusChange: true,
}

export function useHybridSearchServicesQuery(
  hybridSearchServiceQueryOptions?: QueryHookOptions,
) {
  return useQuery<HybridSearchServicesData, HybridSearchServicesVariables>(
    GET_HYBRID_SEARCH_SERVICES,
    {
      ...defaultHybridSearchServicesQueryOptions,
      ...hybridSearchServiceQueryOptions,
      variables: hybridSearchServiceQueryOptions?.variables,
    },
  )
}
