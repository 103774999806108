import {
  ApolloClient,
  from,
  split,
  ApolloLink,
  createHttpLink,
} from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { createUploadMiddleware as createUploadLink } from 'apollo-absinthe-upload-link'

import {
  websocketLink,
  phoenixSocket,
  PhoenixSocketInstance,
} from './websocketLink'
import { errorLink } from './errorLink'
import { authLink } from './authLink'
import { cache } from './cache'

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
})

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL,
  credentials: 'include',
})

const transportLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  (websocketLink as unknown) as ApolloLink,
  from([errorLink, authLink, uploadLink, httpLink]),
)

export const apiClient = new ApolloClient({
  link: transportLink,
  cache,
  credentials: 'include',
})

export const resetWebsocketConnection = () => {
  const socket = (phoenixSocket as unknown) as PhoenixSocketInstance
  socket.conn?.close()
}
