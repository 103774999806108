import { gql, useMutation } from '@apollo/client'

import { PERFORMER, INPUT_ERROR } from 'api'
import { Performer, InputError } from 'types'

export const CREATE_PERFORMER_SERVICE = gql`
  mutation CreatePerformerService($input: CreatePerformerServiceInput!) {
    createPerformerService(input: $input) {
      performer {
        ...Performer
      }
      errors {
        ...InputError
      }
    }
  }
  ${PERFORMER}
  ${INPUT_ERROR}
`

type CreatePerformerServiceDataSuccess = {
  performer: Performer
  errors: null
}

type CreatePerformerServiceDataInputError = {
  performer: null
  errors: InputError<'centsPerUnit' | 'currencyId' | 'languageServices'>[]
}

export type CreatePerformerServiceData = {
  createPerformerService:
    | CreatePerformerServiceDataSuccess
    | CreatePerformerServiceDataInputError
}

type CreatePerformerLanguageServiceVariables = {
  currencyId: string
  centsPerUnit: number
  sourceLanguageId: string
  targetLanguageId: string
}

export type CreatePerformerServiceVariables = {
  input:
    | {
        performerId: string
        typeId: string
        languageServices: CreatePerformerLanguageServiceVariables[]
      }
    | {
        performerId: string
        centsPerUnit: number
        typeId: string
        currencyId: string
      }
}

export const useCreatePerformerServiceMutation = () => {
  return useMutation<
    CreatePerformerServiceData,
    CreatePerformerServiceVariables
  >(CREATE_PERFORMER_SERVICE)
}
