import { User, Role } from 'types'

type GetIsAuthorizedProps = {
  currentUser: User | null
  roles?: Role[]
  hasAccess?: (currentUser: User) => boolean
  isUnauthenticated?: boolean
}

export const getIsAuthorized = ({
  currentUser,
  roles,
  hasAccess,
  isUnauthenticated = false,
}: GetIsAuthorizedProps) => {
  const isAuthenticated = currentUser !== null
  const isAuthorized =
    roles && roles.length > 0
      ? currentUser?.role && roles.includes(currentUser.role)
      : isAuthenticated

  if (
    (hasAccess && currentUser !== null ? hasAccess(currentUser) : true) &&
    (isUnauthenticated ? !isAuthenticated : isAuthorized)
  ) {
    return true
  }

  return false
}
