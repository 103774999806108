import { useCallback, ChangeEvent, FocusEvent } from 'react'
import {
  useController,
  useFormContext,
  get,
  RegisterOptions,
} from 'react-hook-form'
import { TextField, TextFieldProps } from '@mui/material'

import { mergeRefs } from 'utils'
export type RawFieldProps = Omit<TextFieldProps, 'name'> & {
  name: string
  withoutHelperText?: boolean
}

export const RawField = ({
  withoutHelperText = false,
  fullWidth = true,
  helperText = ' ',
  variant = 'outlined',
  name,
  ...props
}: RawFieldProps) => {
  const {
    formState: { errors },
  } = useFormContext()

  const error = get(errors, name)

  return (
    <TextField
      fullWidth={fullWidth}
      margin="none"
      variant={variant}
      id={name}
      name={name}
      {...(!withoutHelperText && {
        helperText:
          (typeof error?.message === 'string' && error.message) || helperText,
      })}
      {...props}
      {...(error && { error: Boolean(error) })}
    />
  )
}

export type FieldProps = RawFieldProps & {
  rules?: RegisterOptions
  defaultValue?: unknown
}

export const Field = ({
  name,
  rules,
  onBlur,
  onChange,
  defaultValue,
  inputRef,
  error: passedError,
  ...props
}: FieldProps) => {
  const { control } = useFormContext()

  const { field } = useController({ control, name, rules, defaultValue })

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      field.onBlur()
      onBlur?.(e)
    },
    [field, onBlur],
  )

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      field.onChange(e)
      onChange?.(e)
    },
    [field, onChange],
  )

  return (
    <RawField
      value={field.value}
      name={name}
      onBlur={handleBlur}
      onChange={handleChange}
      inputRef={inputRef ? mergeRefs([field.ref, inputRef]) : field.ref}
      {...props}
    />
  )
}
