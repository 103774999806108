import { Routes, Route } from 'react-router-dom'

import { Layout, RequireAuth } from 'components'
import { ServicesList } from './ServicesList'
import { Service } from './Service'
import { CreateService } from './CreateService'

export const Services = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequireAuth roles={['admin']}>
            <Layout>
              <ServicesList />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path="create"
        element={
          <RequireAuth roles={['admin']}>
            <Layout maxWidth="lg">
              <CreateService />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path=":id"
        element={
          <RequireAuth roles={['admin']}>
            <Layout maxWidth="md">
              <Service />
            </Layout>
          </RequireAuth>
        }
      />
    </Routes>
  )
}
