import { Performer, Education } from 'types'
import { useEditable } from 'utils'

import { PerformerEducation } from './PerformerEducation'
import { PerformerEducationForm } from './PerformerEducationForm'

type EditablePerformerEducationItemProps = {
  education: Education
  performer: Performer
  isDeletable: boolean
}

export const EditablePerformerEducationItem = ({
  education,
  performer,
  isDeletable,
}: EditablePerformerEducationItemProps) => {
  const { isEditing, handleEdit, handleClose } = useEditable()

  if (isEditing) {
    return (
      <PerformerEducationForm
        isDeletable={isDeletable}
        education={education}
        performer={performer}
        onClose={handleClose}
      />
    )
  }

  return <PerformerEducation education={education} onEdit={handleEdit} />
}
