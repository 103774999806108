import { QueryHookOptions, gql, useQuery } from '@apollo/client'

import { PERFORMER_STATUS } from 'api'
import { PerformerStatus } from 'types'

export type PerformerStatusesData = {
  performerStatuses: PerformerStatus[]
}

export const GET_PERFORMER_STATUSES = gql`
  query GetPerformerStatuses {
    performerStatuses {
      ...PerformerStatus
    }
  }
  ${PERFORMER_STATUS}
`

export const performerStatusesQueryOptions: QueryHookOptions<PerformerStatusesData> = {
  errorPolicy: 'all',
}

export function usePerformerStatusesQuery() {
  return useQuery<PerformerStatusesData>(
    GET_PERFORMER_STATUSES,
    performerStatusesQueryOptions,
  )
}
