import { useParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import invariant from 'tiny-invariant'

import { usePerformerQuery, usePerformerUpdatedSubscription } from 'api'
import { NotFound404 } from 'pages'
import { LayoutWithBackButton, Spinner } from 'components'

import { EditablePerformer } from './components'

type URLParams = {
  id: string
}

export const Performer = () => {
  const { id } = useParams<URLParams>()
  invariant(id, "Performer id isn't set")

  const { data: performerData, loading: performerLoading } = usePerformerQuery(
    id,
    { fetchPolicy: 'network-only' },
  )

  const performer = performerData?.performer
  usePerformerUpdatedSubscription(performer?.id)

  if (performerLoading) {
    return (
      <LayoutWithBackButton>
        <Grid item xs={12}>
          <Spinner />
        </Grid>
      </LayoutWithBackButton>
    )
  }

  if (!performer) {
    return (
      <LayoutWithBackButton>
        <Grid item xs={12}>
          <NotFound404 withLayout={false} />
        </Grid>
      </LayoutWithBackButton>
    )
  }

  return (
    <LayoutWithBackButton>
      <EditablePerformer performer={performer} />
    </LayoutWithBackButton>
  )
}
