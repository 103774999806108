import { useCallback, useMemo, useState } from 'react'
import type { Software, Topic } from 'types'

type UseActiveFiltersProps = {
  statusId: string
  genderId: string
  countryId: string
  nativeLanguageId: string
  serviceGroupId: string
  serviceTypeId: string
  serviceSourceLanguageId: string
  serviceTargetLanguageId: string
  software: Software[]
  topics: Topic[]
}

export function useFiltersMenuState({
  statusId,
  genderId,
  countryId,
  nativeLanguageId,
  serviceGroupId,
  serviceTypeId,
  serviceSourceLanguageId,
  serviceTargetLanguageId,
  software,
  topics,
}: UseActiveFiltersProps) {
  const activeFiltersCount = useMemo(
    () =>
      [
        Boolean(statusId),
        Boolean(genderId),
        Boolean(countryId),
        Boolean(nativeLanguageId),
        Boolean(serviceGroupId),
        Boolean(serviceTypeId),
        Boolean(serviceSourceLanguageId),
        Boolean(serviceTargetLanguageId),
        Boolean(topics.length),
        Boolean(software.length),
      ].filter((filter) => filter).length,
    [
      statusId,
      genderId,
      countryId,
      nativeLanguageId,
      serviceGroupId,
      serviceTypeId,
      serviceSourceLanguageId,
      serviceTargetLanguageId,
      software.length,
      topics.length,
    ],
  )

  const [isFiltersMenuActive, setIsFiltersMenuActive] = useState(
    Boolean(activeFiltersCount),
  )

  const toggleIsFiltersMenuActive = useCallback(() => {
    setIsFiltersMenuActive((active) => !active)
  }, [])

  return { isFiltersMenuActive, toggleIsFiltersMenuActive, activeFiltersCount }
}
