import { useMemo } from 'react'
import { Skeleton } from '@mui/material'

import { SelectField, SelectFieldProps } from 'components'
import { useOrderStatusesQuery } from 'api'
import { formatReferenceEntityToSelectOption } from 'utils'

type OrderStatusSelectFieldProps = Omit<SelectFieldProps, 'options'>

export const OrderStatusSelectField = (props: OrderStatusSelectFieldProps) => {
  const { data, loading } = useOrderStatusesQuery()

  const options = useMemo(
    () => formatReferenceEntityToSelectOption(data?.orderStatuses || []),
    [data],
  )

  return loading ? (
    <Skeleton height={56} />
  ) : (
    <SelectField options={options} {...props} />
  )
}
