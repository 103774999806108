import * as AbsintheSocket from '@absinthe/socket'
import { Socket as PhoenixSocket } from 'phoenix'

import { createAbsintheSocketLink } from './createAbsintheSocketLink'

export type PhoenixSocketInstance = {
  conn: {
    close: () => void
  }
}

export const phoenixSocket = new PhoenixSocket(
  process.env.REACT_APP_SOCKET_URL as string,
  {
    params: () => {
      const accessToken = localStorage.getItem('accessToken')
      return accessToken ? { access_token: accessToken } : {}
    },
  },
)

const absintheSocket = AbsintheSocket.create(phoenixSocket)

export const websocketLink = createAbsintheSocketLink(absintheSocket)
