import { useCallback, SyntheticEvent } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Grid, Button, useMediaQuery, Theme } from '@mui/material'

import {
  useCreateCompanyCommentMutation,
  useUpdateCompanyCommentMutation,
  useDeleteCompanyCommentMutation,
} from 'api'
import { ButtonContainerWithSpinner, Field } from 'components'
import { Comment } from 'types'

type CompanyCommentFormData = {
  content: string
}

type CompanyCommentFormProps = {
  comment?: Comment
  companyId?: string
  onClose: () => void
}

export const CompanyCommentForm = ({
  comment,
  companyId,
  onClose,
}: CompanyCommentFormProps) => {
  const isMatchSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))

  const [createCompanyComment, { loading: createCompanyCommentLoading }] =
    useCreateCompanyCommentMutation()
  const [updateCompanyComment, { loading: updateCompanyCommentLoading }] =
    useUpdateCompanyCommentMutation()
  const [deleteCompanyComment, { loading: deleteCompanyCommentLoading }] =
    useDeleteCompanyCommentMutation()

  const isSubmittingCreateOrUpdate =
    createCompanyCommentLoading || updateCompanyCommentLoading

  const methods = useForm<CompanyCommentFormData>({
    defaultValues: comment
      ? {
          content: comment.content,
        }
      : {
          content: '',
        },
    mode: 'onBlur',
  })

  const onSubmit = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault()

      await methods.handleSubmit(async (formData) => {
        if (comment) {
          await updateCompanyComment({
            variables: { input: { id: comment.id, ...formData } },
          })
        } else if (companyId) {
          await createCompanyComment({
            variables: { input: { companyId, ...formData } },
          })
        }

        if (onClose) onClose()
      })()
    },
    [
      methods,
      comment,
      onClose,
      createCompanyComment,
      updateCompanyComment,
      companyId,
    ],
  )

  const handleDelete = useCallback(() => {
    if (comment) {
      deleteCompanyComment({
        variables: { id: comment.id },
      })
    }
  }, [comment, deleteCompanyComment])

  return (
    <FormProvider {...methods}>
      <Grid
        container
        spacing={2}
        noValidate
        autoComplete="off"
        onSubmit={onSubmit}
        component="form"
      >
        <Grid item xs={12}>
          <Field name="content" label="Комментарий" multiline rows={3} />
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            spacing={isMatchSm ? 2 : 0}
            wrap="nowrap"
          >
            {comment && (
              <Grid item>
                <ButtonContainerWithSpinner
                  isLoading={deleteCompanyCommentLoading}
                >
                  <Button
                    variant="text"
                    color="primary"
                    onClick={handleDelete}
                    disabled={deleteCompanyCommentLoading}
                  >
                    Удалить
                  </Button>
                </ButtonContainerWithSpinner>
              </Grid>
            )}
            <Grid item>
              <Button variant="text" color="primary" onClick={onClose}>
                Отменить
              </Button>
            </Grid>
            <Grid item>
              <ButtonContainerWithSpinner
                isLoading={isSubmittingCreateOrUpdate}
              >
                <Button
                  variant="text"
                  color="primary"
                  type="submit"
                  disabled={isSubmittingCreateOrUpdate}
                >
                  Сохранить
                </Button>
              </ButtonContainerWithSpinner>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}
