import { InMemoryCache } from '@apollo/client'

import {
  cachePaginatedPerformersKeyArgs,
  cachePaginatedPerformersItemsMerge,
  cachePaginatedCustomersKeyArgs,
  cachePaginatedCustomersItemsMerge,
  cachePaginatedCompaniesKeyArgs,
  cachePaginatedCompaniesItemsMerge,
  cachePaginatedOrdersKeyArgs,
  cachePaginatedOrdersItemsMerge,
} from 'api'

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        paginatedPerformers: {
          keyArgs: cachePaginatedPerformersKeyArgs,
        },
        paginatedCustomers: {
          keyArgs: cachePaginatedCustomersKeyArgs,
        },
        paginatedCompanies: {
          keyArgs: cachePaginatedCompaniesKeyArgs,
        },
        paginatedOrders: {
          keyArgs: cachePaginatedOrdersKeyArgs,
        },
      },
    },
    PaginatedPerformers: {
      fields: {
        items: {
          merge: cachePaginatedPerformersItemsMerge,
        },
      },
    },
    PaginatedCustomers: {
      fields: {
        items: {
          merge: cachePaginatedCustomersItemsMerge,
        },
      },
    },
    PaginatedCompanies: {
      fields: {
        items: {
          merge: cachePaginatedCompaniesItemsMerge,
        },
      },
    },
    PaginatedOrders: {
      fields: {
        items: {
          merge: cachePaginatedOrdersItemsMerge,
        },
      },
    },
  },
})
