// This is instead using library - @absinthe/socket-apollo-link@0.2.1
//
// Problem with this package appears after update on react-scripts v5 (webpack v5)
// REAKING CHANGE: The request 'graphql' failed to resolve only because it was resolved as fully specified
//
// There is module consumption problem and have no capacity to contribute to this library
// https://github.com/absinthe-graphql/absinthe-socket/tree/master/packages/socket-apollo-link

import { ApolloLink } from '@apollo/client'
import { send, toObservable, unobserveOrCancel } from '@absinthe/socket'
import { print } from 'graphql'

const unobserveOrCancelIfNeeded = (absintheSocket, notifier, observer) => {
  if (notifier && observer) {
    unobserveOrCancel(absintheSocket, notifier, observer)
  }
}

const notifierToObservable = (absintheSocket, onError, onStart) => (notifier) =>
  toObservable(absintheSocket, notifier, {
    onError,
    onStart,
    unsubscribe: unobserveOrCancelIfNeeded,
  })

const getRequest = ({ query, variables }) => ({
  operation: print(query),
  variables,
})

function pipe(...functions) {
  return (value) => functions.reduce((res, fn) => fn(res), value)
}

/**
 * Creates a terminating ApolloLink to request operations using given
 * AbsintheSocket instance
 */
export const createAbsintheSocketLink = (absintheSocket, onError, onStart) =>
  new ApolloLink(
    pipe(
      getRequest,
      (request) => send(absintheSocket, request),
      notifierToObservable(absintheSocket, onError, onStart),
    ),
  )
