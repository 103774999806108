import { ReactNode } from 'react'
import { Box, Grid, Divider } from '@mui/material'

type MultipleEditableItemProps = {
  children: ReactNode
}

export const MultipleEditableItem = ({
  children,
}: MultipleEditableItemProps) => {
  return (
    <Grid item xs={12}>
      {children}
      <Box mt={2}>
        <Divider variant="fullWidth" />
      </Box>
    </Grid>
  )
}
