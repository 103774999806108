import { Grid } from '@mui/material'

import { useCurrentUserQuery, usePerformerUpdatedSubscription } from 'api'

import { EditablePerformer } from './components'

export const CurrentPerformer = () => {
  const { data: currentUserData } = useCurrentUserQuery()

  const performer = currentUserData!.currentUser!.performer!
  usePerformerUpdatedSubscription(performer.id)

  return (
    <Grid container direction="column" spacing={3} sx={{ mt: 3, mb: 5 }}>
      <EditablePerformer performer={performer} isCurrentUser />
    </Grid>
  )
}
