import { useMemo } from 'react'

import { AutocompleteField, AutocompleteFieldProps } from 'components'
import { useTopicsQuery } from 'api'
import type { Topic } from 'types'

export type TopicFieldProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> = Omit<
  AutocompleteFieldProps<Topic, Multiple, DisableClearable, FreeSolo>,
  'loading' | 'options' | 'loading'
>

const getTopicLabel = ({ name }: Topic) => name

const isOptionEqualToValue = (option: Topic, value: Topic) => {
  return option.id === value.id
}

export function TopicField<Multiple extends boolean = false>(
  props: TopicFieldProps<Multiple, false, false>,
) {
  const { data, loading } = useTopicsQuery()

  const options = useMemo(() => data?.topics || [], [data])

  return (
    <AutocompleteField<Topic, Multiple, false, false>
      loading={loading}
      options={options}
      getOptionLabel={getTopicLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      {...props}
    />
  )
}
