import { useEditable } from 'utils'
import { Performer } from 'types'

import { PerformerInfo } from './PerformerInfo'
import { PerformerInfoForm } from './PerformerInfoForm'

type EditablePerformerInfoProps = {
  performer: Performer
}

export const EditablePerformerInfo = ({
  performer,
}: EditablePerformerInfoProps) => {
  const { isEditing, handleEdit, handleClose } = useEditable()

  if (isEditing) {
    return <PerformerInfoForm performer={performer} onCancel={handleClose} />
  }

  return <PerformerInfo performer={performer} onEdit={handleEdit} />
}
