import { gql } from '@apollo/client'

import { CURRENCY, RATE, LANGUAGE } from 'api/fragments'

export const MANAGER = gql`
  fragment Manager on Manager {
    id
    position
    profile {
      id
      avatar
      firstName
      lastName
      email
      phone
    }
  }
`

export const CUSTOMER_COMMENT = gql`
  fragment CustomerComment on CustomerComment {
    id
    content
    insertedAt
    updatedAt
    manager {
      ...Manager
    }
  }
  ${MANAGER}
`

export const CUSTOMER = gql`
  fragment Customer on Customer {
    id
    profile {
      id
      avatar
      firstName
      lastName
      email
      phone
      birthday
    }
    companies {
      ...CustomerCompany
    }
    comments {
      ...CustomerComment
    }
  }
  fragment CustomerCompany on CustomerCompany {
    id
    name
    logo
  }
  ${CUSTOMER_COMMENT}
`

export const COMPANY_CONTRACT = gql`
  fragment CompanyContract on CompanyContract {
    id
    name
    signedAt
    document
  }
  ${MANAGER}
`

export const COMPANY_COMMENT = gql`
  fragment CompanyComment on CompanyComment {
    id
    content
    insertedAt
    updatedAt
    manager {
      ...Manager
    }
  }
  ${MANAGER}
`

export const COMPANY = gql`
  fragment Company on Company {
    id
    name
    docName
    logo
    url
    inn
    kpp
    ogrn
    address
    legalAddress
    mailAddress
    employees {
      ...Customer
    }
    contracts {
      ...CompanyContract
    }
    comments {
      ...CompanyComment
    }
  }
  ${CUSTOMER}
  ${COMPANY_CONTRACT}
  ${COMPANY_COMMENT}
`

export const LANGUAGE_SERVICE = gql`
  fragment LanguageService on LanguageService {
    id
    centsPerUnit
    currency {
      ...Currency
    }
    sourceLanguage {
      ...Language
    }
    targetLanguage {
      ...Language
    }
  }
  ${LANGUAGE}
  ${CURRENCY}
`

export const SERVICE = gql`
  fragment Service on Service {
    id
    name
    centsPerUnit
    rate {
      ...Rate
    }
    currency {
      ...Currency
    }
    languageServices {
      ...LanguageService
    }
  }
  ${RATE}
  ${CURRENCY}
  ${LANGUAGE_SERVICE}
`

export const ORDER_STATUS = gql`
  fragment OrderStatus on OrderStatus {
    id
    name
  }
`

export const ORDER_COMMENT = gql`
  fragment OrderComment on OrderComment {
    id
    content
    insertedAt
    updatedAt
    manager {
      ...Manager
    }
  }
  ${MANAGER}
`

export const ORDER_SERVICE = gql`
  fragment OrderService on OrderService {
    id
    name
    centsPerUnit
    units
    discount
    service {
      id
    }
    languageService {
      id
    }
    rate {
      ...Rate
    }
    currency {
      ...Currency
    }
  }
  ${RATE}
  ${CURRENCY}
`

export const ORDER = gql`
  fragment Order on Order {
    id
    startAt
    endAt
    manager {
      ...Manager
    }
    customer {
      ...Customer
    }
    company {
      ...Company
    }
    status {
      ...OrderStatus
    }
    services {
      ...OrderService
    }
    comments {
      ...OrderComment
    }
  }
  ${MANAGER}
  ${CUSTOMER}
  ${COMPANY}
  ${ORDER_SERVICE}
  ${ORDER_STATUS}
  ${ORDER_COMMENT}
`
