import { useCallback, SyntheticEvent } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Grid, Button, useMediaQuery, Theme } from '@mui/material'

import {
  useCreatePerformerCommentMutation,
  useUpdatePerformerCommentMutation,
  useDeletePerformerCommentMutation,
} from 'api'
import { ButtonContainerWithSpinner, Field } from 'components'
import { Comment } from 'types'

type PerformerCommentFormData = {
  content: string
}

type PerformerCommentFormProps = {
  onClose: () => void
  comment?: Comment
  performerId?: string
}

export const PerformerCommentForm = ({
  onClose,
  comment,
  performerId,
}: PerformerCommentFormProps) => {
  const isMatchSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))

  const [createPerformerComment, { loading: createPerformerCommentLoading }] =
    useCreatePerformerCommentMutation()
  const [updatePerformerComment, { loading: updatePerformerCommentLoading }] =
    useUpdatePerformerCommentMutation()
  const [deletePerformerComment, { loading: deletePerformerCommentLoading }] =
    useDeletePerformerCommentMutation()

  const isSubmittingCreateOrUpdate =
    createPerformerCommentLoading || updatePerformerCommentLoading

  const methods = useForm<PerformerCommentFormData>({
    defaultValues: comment
      ? {
          content: comment.content,
        }
      : {
          content: '',
        },
    mode: 'onBlur',
  })

  const onSubmit = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault()

      await methods.handleSubmit(async (formData) => {
        if (comment) {
          await updatePerformerComment({
            variables: { input: { id: comment.id, ...formData } },
          })
        } else if (performerId) {
          await createPerformerComment({
            variables: { input: { performerId, ...formData } },
          })
        }

        onClose()
      })()
    },
    [
      methods,
      comment,
      onClose,
      createPerformerComment,
      updatePerformerComment,
      performerId,
    ],
  )

  const handleDelete = useCallback(() => {
    if (comment) {
      deletePerformerComment({
        variables: { id: comment.id },
      })
    }
  }, [comment, deletePerformerComment])

  return (
    <FormProvider {...methods}>
      <Grid
        container
        spacing={2}
        noValidate
        autoComplete="off"
        onSubmit={onSubmit}
        component="form"
      >
        <Grid item xs={12}>
          <Field name="content" label="Комментарий" multiline rows={3} />
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            spacing={isMatchSm ? 2 : 0}
            wrap="nowrap"
          >
            {comment && (
              <Grid item>
                <ButtonContainerWithSpinner
                  isLoading={deletePerformerCommentLoading}
                >
                  <Button
                    variant="text"
                    color="primary"
                    onClick={handleDelete}
                    disabled={deletePerformerCommentLoading}
                  >
                    Удалить
                  </Button>
                </ButtonContainerWithSpinner>
              </Grid>
            )}
            <Grid item>
              <Button variant="text" color="primary" onClick={onClose}>
                Отменить
              </Button>
            </Grid>
            <Grid item>
              <ButtonContainerWithSpinner
                isLoading={isSubmittingCreateOrUpdate}
              >
                <Button
                  variant="text"
                  color="primary"
                  type="submit"
                  disabled={isSubmittingCreateOrUpdate}
                >
                  Сохранить
                </Button>
              </ButtonContainerWithSpinner>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}
