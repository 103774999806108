import { useCallback, SyntheticEvent } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Button, Grid } from '@mui/material'

import { useUpdateCustomerMutation } from 'api'
import { ButtonContainerWithSpinner, CompanyField } from 'components'
import { Company, Customer } from 'types'

type CustomerCompaniesFormData = {
  companies: Company[]
}

type CustomerCompaniesFormProps = {
  onClose: () => void
  customer: Customer
  onSubmit?: () => void
}

export const CustomerCompaniesForm = ({
  customer,
  onSubmit: onSubmitAction,
  onClose,
}: CustomerCompaniesFormProps) => {
  const [updateCustomer, { loading: updateCompanyLoading }] =
    useUpdateCustomerMutation()

  const methods = useForm<CustomerCompaniesFormData>({
    defaultValues: {
      companies: customer.companies,
    },
    mode: 'onBlur',
  })

  const onSubmit = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault()

      await methods.handleSubmit(async ({ companies }) => {
        await updateCustomer({
          variables: {
            input: {
              id: customer.id,
              companies: companies ? companies.map(({ id }) => id) : [],
            },
          },
        })
        if (onSubmitAction) onSubmitAction()
        onClose()
      })()
    },
    [methods, updateCustomer, onSubmitAction, onClose, customer.id],
  )

  return (
    <FormProvider {...methods}>
      <Grid
        container
        spacing={2}
        noValidate
        autoComplete="off"
        onSubmit={onSubmit}
        component="form"
      >
        <Grid item xs={12}>
          <CompanyField name="companies" label="Компании" multiple />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={1}>
            <Grid item>
              <Button variant="text" color="primary" onClick={onClose}>
                Отмена
              </Button>
            </Grid>
            <Grid item>
              <ButtonContainerWithSpinner isLoading={updateCompanyLoading}>
                <Button
                  variant="text"
                  color="primary"
                  type="submit"
                  disabled={updateCompanyLoading}
                >
                  Сохранить
                </Button>
              </ButtonContainerWithSpinner>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}
