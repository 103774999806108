import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Avatar, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { Store as StoreIcon } from '@mui/icons-material'

import { Company } from 'types'

type CustomerCompanyCardProps = {
  company: Company
}

export const CustomerCompanyCard = ({
  company: { id, name, logo },
}: CustomerCompanyCardProps) => {
  const navigate = useNavigate()

  const onEmployeeClick = useCallback(() => {
    navigate(`/companies/${id}`, {
      state: {
        backButtonTitle: 'К сотруднику',
      },
    })
  }, [id, navigate])

  return (
    <ListItem button onClick={onEmployeeClick}>
      <ListItemIcon>
        <Avatar src={logo} sx={{ width: 40, height: 40 }}>
          <StoreIcon sx={{ width: '75%', height: '75%' }} />
        </Avatar>
      </ListItemIcon>
      <ListItemText primary={name} />
    </ListItem>
  )
}
