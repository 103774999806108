import { useCallback, SyntheticEvent } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Button, Grid } from '@mui/material'
import { Store as StoreIcon } from '@mui/icons-material'

import { useUpdateCompanyMutation } from 'api'
import { ButtonContainerWithSpinner, Field, AvatarField } from 'components'
import { Company as TCompany } from 'types'
import { setFormErrors } from 'utils'

const errorsMessages = {
  name: {
    key: 'name' as const,
    messages: {
      'has already been taken':
        'Компания с таким названием уже зарегистрирована',
    },
    default: 'Нельзя зарегистрироваться с таким названием',
  },
  docName: {
    key: 'docName' as const,
    messages: {
      'has already been taken':
        'Компания с таким наименованием уже зарегистрирована',
    },
    default: 'Нельзя зарегистрироваться с таким наименованием',
  },
  inn: {
    key: 'inn' as const,
    messages: {
      'has already been taken': 'Компания с таким ИНН уже зарегистрирована',
    },
    default: 'Нельзя зарегистрироваться с таким ИНН',
  },
  ogrn: {
    key: 'ogrn' as const,
    messages: {
      'has already been taken': 'Компания с таким ОГРН уже зарегистрирована',
    },
    default: 'Нельзя зарегистрироваться с таким ОГРН',
  },
}

type CompanyFormData = {
  name: string
  docName: string
  logo?: File | string
  url: string
  inn: string
  kpp: string
  ogrn: string
  address: string
  legalAddress: string
  mailAddress: string
}

type CompanyFormProps = {
  company: TCompany
  onSubmit?: () => void
  onCancel?: () => void
}

export const CompanyForm = ({
  company,
  onSubmit: onSubmitAction,
  onCancel,
}: CompanyFormProps) => {
  const [updateCompany] = useUpdateCompanyMutation()

  const methods = useForm<CompanyFormData>({
    defaultValues: {
      name: company.name,
      docName: company.docName,
      logo: company.logo,
      url: company.url,
      inn: company.inn,
      kpp: company.kpp,
      ogrn: company.ogrn,
      address: company.address,
      legalAddress: company.address,
      mailAddress: company.mailAddress,
    },
    mode: 'onBlur',
  })

  const onSubmit = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault()
      methods.handleSubmit(async (formData) => {
        const {
          name,
          docName,
          logo,
          url,
          inn,
          kpp,
          ogrn,
          address,
          legalAddress,
          mailAddress,
        } = formData

        const { data } = await updateCompany({
          variables: {
            input: {
              id: company.id,
              name,
              docName,
              url,
              inn,
              kpp,
              ogrn,
              address,
              legalAddress,
              mailAddress,
              // URL of existing avatar is not valid value
              ...(typeof logo !== 'string' && { logo }),
            },
          },
        })

        if (data?.updateCompany?.errors) {
          setFormErrors<
            Pick<CompanyFormData, 'name' | 'docName' | 'inn' | 'ogrn'>
          >(methods.setError, data.updateCompany.errors, errorsMessages)
        }

        if (data?.updateCompany?.company) {
          if (onSubmitAction) onSubmitAction()
          if (onCancel) onCancel()
        }
      })()
    },
    [methods, updateCompany, onSubmitAction, onCancel, company.id],
  )

  return (
    <FormProvider {...methods}>
      <Grid
        container
        spacing={2}
        noValidate
        autoComplete="off"
        onSubmit={onSubmit}
        component="form"
      >
        <Grid item container alignItems="center" spacing={2}>
          <Grid item xs={12} sm={6}>
            <AvatarField name="logo">
              <StoreIcon sx={{ width: '75%', height: '75%' }} />
            </AvatarField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field name="inn" label="ИНН" rules={{ required: true }} />
              </Grid>
              <Grid item xs={12}>
                <Field name="kpp" label="КПП" rules={{ required: true }} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name="name"
            label="Название"
            rules={{
              required: true,
              maxLength: {
                value: 255,
                message: 'Не более 255 символов',
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field name="ogrn" label="ОГРН" rules={{ required: true }} />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="docName"
            label="Юридическое названиe"
            rules={{ required: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <Field name="legalAddress" label="Юридический адрес" />
        </Grid>
        <Grid item xs={12}>
          <Field name="mailAddress" label="Почтовый адрес" />
        </Grid>
        <Grid item xs={12}>
          <Field name="address" label="Фактический адрес" />
        </Grid>
        <Grid item xs={12}>
          <Field name="url" label="Сайт" />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={1}>
            {onCancel && (
              <Grid item>
                <Button variant="text" color="primary" onClick={onCancel}>
                  Отмена
                </Button>
              </Grid>
            )}
            <Grid item>
              <ButtonContainerWithSpinner
                isLoading={methods.formState.isSubmitting}
              >
                <Button
                  variant="text"
                  color="primary"
                  type="submit"
                  disabled={methods.formState.isSubmitting}
                >
                  Сохранить
                </Button>
              </ButtonContainerWithSpinner>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}
