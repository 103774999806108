import { useCallback, SyntheticEvent } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { format } from 'date-fns'
import { Button, Grid } from '@mui/material'
import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material'

import { useCreatePerformerMutation, useUpdatePerformerMutation } from 'api'
import {
  AvatarField,
  ButtonContainerWithSpinner,
  DateField,
  Field,
  CountrySelectField,
  GenderSelectField,
  LanguageSelectField,
  UploadField,
} from 'components'
import { Performer } from 'types'

const acceptedExtensions = ['pdf']

type PerformerOnboardingProfileFormData = {
  bio?: string
  cv?: File | string
  nativeLanguageId: string
  city: string
  birthday: Date | null
  countryId: string
  genderId: string
  avatar?: File | string
}

type PerformerOnboardingProfileFormProps = {
  performer: Performer | null
  profileId: string
  onSubmit: () => void
}

export const PerformerOnboardingInfoForm = ({
  performer,
  profileId,
  onSubmit: onSubmitAction,
}: PerformerOnboardingProfileFormProps) => {
  const [createPerformer] = useCreatePerformerMutation()
  const [updatePerformer] = useUpdatePerformerMutation()

  const methods = useForm<PerformerOnboardingProfileFormData>({
    defaultValues:
      performer === null
        ? {
            bio: '',
            cv: '',
            nativeLanguageId: '',
            city: '',
            birthday: null,
            countryId: '',
            genderId: '',
            avatar: '',
          }
        : {
            bio: performer.bio || '',
            cv: performer.cv,
            nativeLanguageId: performer.nativeLanguage.id,
            city: performer.profile.city,
            birthday: performer.profile.birthday
              ? new Date(performer.profile.birthday)
              : null,
            countryId: performer.profile.country.id,
            genderId: performer.profile.gender.id,
            avatar: performer.profile.avatar,
          },
    mode: 'onBlur',
  })

  const onSubmit = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault()

      await methods.handleSubmit(async (formData) => {
        const {
          city,
          birthday: rawBirthday,
          nativeLanguageId,
          countryId,
          genderId,
          bio,
          avatar,
          cv,
        } = formData

        const birthday = rawBirthday ? format(rawBirthday, 'yyyy-MM-dd') : ''

        if (performer === null) {
          await createPerformer({
            variables: {
              input: {
                bio,
                nativeLanguageId,
                // URL of existing cv is not valid value
                ...(typeof cv !== 'string' && { cv }),
                profile: {
                  id: profileId,
                  city,
                  birthday,
                  countryId,
                  genderId,
                  // URL of existing avatar is not valid value
                  ...(typeof avatar !== 'string' && { avatar }),
                },
              },
            },
          })
        } else {
          await updatePerformer({
            variables: {
              input: {
                id: performer.id,
                bio,
                nativeLanguageId,
                // URL of existing cv is not valid value
                ...(typeof cv !== 'string' && { cv }),
                profile: {
                  city,
                  birthday,
                  countryId,
                  genderId,
                  // URL of existing avatar is not valid value
                  ...(typeof avatar !== 'string' && { avatar }),
                },
              },
            },
          })
        }

        if (onSubmitAction) onSubmitAction()
      })()
    },
    [
      methods,
      createPerformer,
      updatePerformer,
      onSubmitAction,
      performer,
      profileId,
    ],
  )

  return (
    <FormProvider {...methods}>
      <Grid
        container
        spacing={2}
        noValidate
        autoComplete="off"
        onSubmit={onSubmit}
        component="form"
      >
        <Grid item container alignItems="center" spacing={2}>
          <Grid item xs={12} sm={6}>
            <AvatarField name="avatar" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DateField
                  name="birthday"
                  label="Дата рождения"
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <CountrySelectField
                  name="countryId"
                  rules={{ required: true }}
                  label="Страна проживания"
                  hasEmptyOption
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="city"
                  label="Город проживания"
                  rules={{
                    required: true,
                    maxLength: {
                      value: 255,
                      message: 'Не более 255 символов',
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <LanguageSelectField
            name="nativeLanguageId"
            rules={{ required: true }}
            label="Родной язык"
            hasEmptyOption
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <GenderSelectField
            name="genderId"
            rules={{ required: true }}
            label="Пол"
            hasEmptyOption
          />
        </Grid>
        <Grid item xs={12}>
          <Field name="bio" label="Немного обо мне" multiline rows={3} />
        </Grid>
        <Grid item xs={12}>
          <UploadField
            name="cv"
            label="Загрузить резюме"
            labelActive="Приложите резюме"
            accept={acceptedExtensions}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end" spacing={1}>
            <Grid item>
              <ButtonContainerWithSpinner
                isLoading={methods.formState.isSubmitting}
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={methods.formState.isSubmitting}
                  endIcon={<ArrowForwardIcon />}
                >
                  Далее
                </Button>
              </ButtonContainerWithSpinner>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}
