import { ReactNode } from 'react'
import { styled } from '@mui/material/styles'
import { Container, ContainerProps } from '@mui/material'

import { Navbar } from './'

const Root = styled('div')`
  overflow: hidden;
  min-height: 100vh;
  display: flex;
`

type LayoutProps = {
  maxWidth?: ContainerProps['maxWidth']
  children: ReactNode
}

export const Layout = ({ children, maxWidth = 'lg' }: LayoutProps) => {
  return (
    <Root>
      <Navbar>
        <Container maxWidth={maxWidth}>{children}</Container>
      </Navbar>
    </Root>
  )
}
