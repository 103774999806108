import { ReactNode, memo } from 'react'
import { styled } from '@mui/material/styles'
import {
  Box,
  Paper,
  Grid,
  Typography,
  useMediaQuery,
  Theme,
} from '@mui/material'

import { Performer, PerformerService } from 'types'

import {
  EditablePerformerComments,
  EditablePerformerEducation,
  EditablePerformerExperience,
  EditablePerformerServices,
  EditablePerformerSkills,
  EditablePerformerProfile,
  EditablePerformerInfo,
} from './'

const StyledPaper = styled(Paper)(
  ({ theme }) => `
    padding: ${theme.spacing(3)};
    padding-bottom: ${theme.spacing(5)};
`,
)

type PerformerForm = {
  title: string
  formComponent: ReactNode
}

const isServiceHasSkills = ({ type: { group } }: PerformerService) => {
  return group.hasTopics || group.hasSoftware
}

const getForms = (
  performer: Performer,
  isCurrentUser: boolean,
): PerformerForm[] => [
  {
    title: isCurrentUser ? 'Личные данные' : 'Профиль',
    formComponent: isCurrentUser ? (
      <EditablePerformerInfo performer={performer} />
    ) : (
      <EditablePerformerProfile performer={performer} />
    ),
  },
  {
    title: 'Образование',
    formComponent: <EditablePerformerEducation performer={performer} />,
  },
  {
    title: 'Опыт',
    formComponent: <EditablePerformerExperience performer={performer} />,
  },
  {
    title: 'Услуги',
    formComponent: <EditablePerformerServices performer={performer} />,
  },
  ...(performer.services.some(isServiceHasSkills)
    ? [
        {
          title: 'Навыки',
          formComponent: <EditablePerformerSkills performer={performer} />,
        },
      ]
    : []),
  ...(!isCurrentUser
    ? [
        {
          title: 'Комментарии',
          formComponent: <EditablePerformerComments performer={performer} />,
        },
      ]
    : []),
]

type EditablePerformerProps = {
  performer: Performer
  isCurrentUser?: boolean
}

export const EditablePerformer = memo(
  ({ performer, isCurrentUser = false }: EditablePerformerProps) => {
    const isMatchSm = useMediaQuery((theme: Theme) =>
      theme.breakpoints.up('sm'),
    )

    const forms = getForms(performer, isCurrentUser)

    return (
      <>
        {forms.map(({ title, formComponent }, index) => (
          <Grid
            item
            xs={12}
            sm={10}
            md={8}
            lg={6}
            xl={4}
            key={`${title}-${index}`}
          >
            <StyledPaper>
              <Grid container direction="column" spacing={2}>
                <Grid item xs={12}>
                  <Box my={2}>
                    <Typography
                      variant={isMatchSm ? 'h4' : 'h5'}
                      color="primary"
                      align="center"
                    >
                      {title}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  {formComponent}
                </Grid>
              </Grid>
            </StyledPaper>
          </Grid>
        ))}
      </>
    )
  },
)
