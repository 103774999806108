import { ReactNode } from 'react'
import { useParams } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import {
  Box,
  Paper,
  Grid,
  Typography,
  useMediaQuery,
  Theme,
} from '@mui/material'
import invariant from 'tiny-invariant'

import { useCompanyQuery, useCompanyUpdatedSubscription } from 'api'
import { NotFound404 } from 'pages'
import { LayoutWithBackButton, Spinner } from 'components'
import { Company as TCompany } from 'types'

import { EditableCompany } from './EditableCompany'
import { EditableCompanyContracts } from './EditableCompanyContracts'
import { EditableCompanyEmployees } from './EditableCompanyEmployees'
import { EditableCompanyComments } from './EditableCompanyComments'

const StyledPaper = styled(Paper)(
  ({ theme }) => `
    min-width: ${theme.spacing(40)};
    padding: ${theme.spacing(3)};
    padding-bottom: ${theme.spacing(5)};
`,
)

type CompanyForm = {
  title: string
  formComponent: ReactNode
}

const getForms = (company: TCompany): CompanyForm[] => [
  {
    title: 'Профиль',
    formComponent: <EditableCompany company={company} />,
  },
  {
    title: 'Договоры',
    formComponent: <EditableCompanyContracts company={company} />,
  },
  {
    title: 'Сотрудники',
    formComponent: <EditableCompanyEmployees company={company} />,
  },
  {
    title: 'Комментарии',
    formComponent: <EditableCompanyComments company={company} />,
  },
]

type URLParams = {
  id: string
}

export const Company = () => {
  const isMatchSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
  const { id } = useParams<URLParams>()
  invariant(id, "Company id isn't set")

  const { data: companyData, loading: companyLoading } = useCompanyQuery(id, {
    fetchPolicy: 'network-only',
  })

  const company = companyData?.company
  useCompanyUpdatedSubscription(company?.id)

  if (companyLoading) {
    return (
      <LayoutWithBackButton>
        <Grid item xs={12}>
          <Spinner />
        </Grid>
      </LayoutWithBackButton>
    )
  }

  if (!company) {
    return (
      <LayoutWithBackButton>
        <Grid item xs={12}>
          <NotFound404 withLayout={false} />
        </Grid>
      </LayoutWithBackButton>
    )
  }

  return (
    <LayoutWithBackButton>
      {getForms(company).map(({ title, formComponent }, index) => (
        <Grid item xs={12} key={`${title}-${index}`}>
          <StyledPaper>
            <Grid container direction="column" spacing={2}>
              <Grid item xs={12}>
                <Box my={2}>
                  <Typography
                    variant={isMatchSm ? 'h4' : 'h5'}
                    color="primary"
                    align="center"
                  >
                    {title}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                {formComponent}
              </Grid>
            </Grid>
          </StyledPaper>
        </Grid>
      ))}
    </LayoutWithBackButton>
  )
}
