import { gql, useMutation } from '@apollo/client'

import { ORDER, INPUT_ERROR } from 'api'
import { Order, InputError } from 'types'

export const UPDATE_ORDER = gql`
  mutation UpdateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      order {
        ...Order
      }
      errors {
        ...InputError
      }
    }
  }
  ${ORDER}
  ${INPUT_ERROR}
`

type UpdateOrderDataSuccess = {
  order: Order
  errors: null
}

type UpdateOrderDataInputError = {
  order: null
  errors: InputError<'startAt' | 'endAt' | 'statusId'>[]
}

export type UpdateOrderData = {
  updateOrder: UpdateOrderDataSuccess | UpdateOrderDataInputError
}

export type UpdateOrderVariables = {
  input: {
    id: string
    startAt?: string
    endAt?: string
    customerId?: string
    companyId?: string | null
    managerId?: string
    statusId?: string
  }
}

export const useUpdateOrderMutation = () => {
  return useMutation<UpdateOrderData, UpdateOrderVariables>(UPDATE_ORDER)
}
