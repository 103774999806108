import { useMemo, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { Skeleton } from '@mui/material'

import { SelectField, SelectFieldProps } from 'components'
import { useCurrenciesQuery } from 'api'
import { formatReferenceEntityToSelectOption } from 'utils'

type CurrencySelectFieldProps = Omit<SelectFieldProps, 'options'>

export const CurrencySelectField = ({
  name,
  ...props
}: CurrencySelectFieldProps) => {
  const { getValues, setValue } = useFormContext()
  const { data, loading } = useCurrenciesQuery()

  const options = useMemo(
    () =>
      formatReferenceEntityToSelectOption(
        data?.currencies.map((currency) => ({
          ...currency,
          name: currency.symbol,
        })) || [],
      ),
    [data],
  )

  useEffect(() => {
    if (data) {
      const defaultCurrency = data.currencies.find(
        ({ symbol }) => symbol === '₽',
      )

      if (defaultCurrency && !getValues()[name]) {
        setValue(name, defaultCurrency.id)
      }
    }
  }, [data, name, getValues, setValue])

  return loading ? (
    <Skeleton height={56} />
  ) : (
    <SelectField options={options} name={name} {...props} />
  )
}
