import { Performer, Experience } from 'types'
import { useEditable } from 'utils'

import { PerformerExperience } from './PerformerExperience'
import { PerformerExperienceForm } from './PerformerExperienceForm'

type EditablePerformerExperienceItemProps = {
  experience: Experience
  performer: Performer
  isDeletable: boolean
}

export const EditablePerformerExperienceItem = ({
  experience,
  performer,
  isDeletable,
}: EditablePerformerExperienceItemProps) => {
  const { isEditing, handleEdit, handleClose } = useEditable()

  if (isEditing) {
    return (
      <PerformerExperienceForm
        experience={experience}
        performer={performer}
        isDeletable={isDeletable}
        onClose={handleClose}
      />
    )
  }

  return <PerformerExperience experience={experience} onEdit={handleEdit} />
}
