import { gql, useMutation } from '@apollo/client'

import { GET_PAGINATED_SERVICES, SERVICE, INPUT_ERROR } from 'api'
import { Service, InputError } from 'types'

export const CREATE_SERVICE = gql`
  mutation CreateService($input: CreateServiceInput!) {
    createService(input: $input) {
      service {
        ...Service
      }
      errors {
        ...InputError
      }
    }
  }
  ${SERVICE}
  ${INPUT_ERROR}
`

type CreateServiceDataSuccess = {
  service: Service
  errors: null
}

type CreateServiceDataInputError = {
  service: null
  errors: InputError<
    'name' | 'centsPerUnit' | 'currencyId' | 'languageServices'
  >[]
}

export type CreateServiceData = {
  createService: CreateServiceDataSuccess | CreateServiceDataInputError
}

type CreateLanguageServiceVariables = {
  currencyId: string
  centsPerUnit: number
  sourceLanguageId: string
  targetLanguageId: string
}

export type CreateServiceVariables = {
  input:
    | {
        name: string
        rateId: string
        languageServices: CreateLanguageServiceVariables[]
      }
    | {
        name: string
        centsPerUnit: number
        rateId: string
        currencyId: string
      }
}

export const useCreateServiceMutation = () => {
  return useMutation<CreateServiceData, CreateServiceVariables>(
    CREATE_SERVICE,
    {
      refetchQueries: [GET_PAGINATED_SERVICES],
    },
  )
}
