import { useMemo } from 'react'
import { Skeleton } from '@mui/material'

import { SelectField, SelectFieldProps } from 'components'
import { useRatesQuery } from 'api'
import { formatReferenceEntityToSelectOption } from 'utils'

type RateSelectFieldProps = Omit<SelectFieldProps, 'options'>

export const RateSelectField = (props: RateSelectFieldProps) => {
  const { data, loading } = useRatesQuery()

  const options = useMemo(
    () => formatReferenceEntityToSelectOption(data?.rates || []),
    [data],
  )

  return loading ? (
    <Skeleton height={56} />
  ) : (
    <SelectField options={options} {...props} />
  )
}
