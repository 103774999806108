import { ReactNode } from 'react'
import { useParams } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import {
  Box,
  Paper,
  Grid,
  Typography,
  useMediaQuery,
  Theme,
} from '@mui/material'
import invariant from 'tiny-invariant'

import { useServiceQuery, useServiceUpdatedSubscription } from 'api'
import { NotFound404 } from 'pages'
import { LayoutWithBackButton, Spinner } from 'components'
import { Service as TService } from 'types'

import { EditableService } from './EditableService'

const StyledPaper = styled(Paper)(
  ({ theme }) => `
    min-width: ${theme.spacing(40)};
    padding: ${theme.spacing(3)};
    padding-bottom: ${theme.spacing(5)};
`,
)

type ServiceForm = {
  title: string
  formComponent: ReactNode
}

const getForms = (service: TService): ServiceForm[] => [
  {
    title: 'Услуга',
    formComponent: <EditableService service={service} />,
  },
]

type URLParams = {
  id: string
}

export const Service = () => {
  const isMatchSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
  const { id } = useParams<URLParams>()
  invariant(id, "Service id isn't set")

  const { data: serviceData, loading: serviceLoading } = useServiceQuery(id, {
    fetchPolicy: 'network-only',
  })

  const service = serviceData?.service
  useServiceUpdatedSubscription(service?.id)

  if (serviceLoading) {
    return (
      <LayoutWithBackButton>
        <Grid item xs={12}>
          <Spinner />
        </Grid>
      </LayoutWithBackButton>
    )
  }

  if (!service) {
    return (
      <LayoutWithBackButton>
        <Grid item xs={12}>
          <NotFound404 withLayout={false} />
        </Grid>
      </LayoutWithBackButton>
    )
  }

  return (
    <LayoutWithBackButton>
      {getForms(service).map(({ title, formComponent }, index) => (
        <Grid item xs={12} key={`${title}-${index}`}>
          <StyledPaper>
            <Grid container direction="column" spacing={2}>
              <Grid item xs={12}>
                <Box my={2}>
                  <Typography
                    variant={isMatchSm ? 'h4' : 'h5'}
                    color="primary"
                    align="center"
                  >
                    {title}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                {formComponent}
              </Grid>
            </Grid>
          </StyledPaper>
        </Grid>
      ))}
    </LayoutWithBackButton>
  )
}
