import { gql, useMutation } from '@apollo/client'

import { ORDER_SERVICE, INPUT_ERROR } from 'api'
import { OrderService, InputError } from 'types'

export const UPDATE_ORDER_SERVICE = gql`
  mutation UpdateOrderService($input: UpdateOrderServiceInput!) {
    updateOrderService(input: $input) {
      service {
        ...OrderService
      }
      errors {
        ...InputError
      }
    }
  }
  ${ORDER_SERVICE}
  ${INPUT_ERROR}
`

type UpdateOrderServiceDataSuccess = {
  service: OrderService
  errors: null
}

type UpdateOrderServiceDataInputError = {
  service: null
  errors: InputError<'name' | 'centsPerUnit' | 'units'>[]
}

export type UpdateOrderServiceData = {
  updateOrderService:
    | UpdateOrderServiceDataSuccess
    | UpdateOrderServiceDataInputError
}

export type UpdateOrderServiceVariables = {
  input: {
    id: string
    rateId: string
    currencyId: string
    serviceId: string | null
    languageServiceId: string | null
    name: string
    centsPerUnit: number
    units: number
    discount: number
  }
}

export const useUpdateOrderServiceMutation = () => {
  return useMutation<UpdateOrderServiceData, UpdateOrderServiceVariables>(
    UPDATE_ORDER_SERVICE,
  )
}
