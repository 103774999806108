import { useCallback } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Hidden,
  Link,
  Typography,
} from '@mui/material'
import { Phone as PhoneIcon, Email as EmailIcon } from '@mui/icons-material'

import { getUserFullname } from 'utils'
import { Customer } from 'types'

type CustomerCardProps = {
  customer: Customer
}

export const CustomerCard = ({ customer }: CustomerCardProps) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const search = searchParams.get('search')

  const fullName = getUserFullname(customer.profile)

  const handleClick = useCallback(() => {
    const backButtonTitle = search ? 'К результатам поиска' : 'К клиентам'

    navigate(`/customers/${customer.id}`, {
      state: {
        backButtonTitle,
      },
    })
  }, [navigate, customer, search])

  return (
    <Card>
      <CardActionArea
        onClick={handleClick}
        sx={{ display: 'flex', justifyContent: 'flex-start' }}
      >
        <Hidden mdDown>
          <Avatar
            src={customer.profile.avatar}
            variant="square"
            sx={{ minWidth: 160, width: 160, height: 160 }}
          />
        </Hidden>
        <CardContent sx={{ display: 'flex', flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} container spacing={2} alignItems="center">
              <Hidden mdUp>
                <Grid item>
                  <Avatar
                    src={customer.profile.avatar}
                    sx={{ width: 80, height: 80 }}
                  />
                </Grid>
              </Hidden>
              <Grid item flexGrow={1} container alignItems="center" spacing={1}>
                <Grid item flexGrow={1}>
                  <Typography variant="h6">{fullName}</Typography>
                </Grid>
                <Grid item xs={12} md="auto">
                  <Grid container spacing={1}>
                    <Grid item>
                      <EmailIcon />
                    </Grid>
                    <Grid item>
                      <Link
                        onClick={(e: React.MouseEvent<EventTarget>) => {
                          e.stopPropagation()
                        }}
                        href={`mailto:${customer.profile.email}`}
                        variant="body1"
                      >
                        {customer.profile.email}
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md="auto">
                  <Grid container spacing={1}>
                    <Grid item>
                      <PhoneIcon />
                    </Grid>
                    <Grid item>
                      <Link
                        onClick={(e: React.MouseEvent<EventTarget>) => {
                          e.stopPropagation()
                        }}
                        href={`tel:+${customer.profile.phone}`}
                        variant="body1"
                      >
                        +{customer.profile.phone}
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
