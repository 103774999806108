import { Grid, IconButton, Typography } from '@mui/material'
import { Edit as EditIcon } from '@mui/icons-material'

import { getDollars, getServiceTotal, localizeNumber, pluralize } from 'utils'
import { OrderService as TOrderService } from 'types'

type OrderServiceProps = {
  service: TOrderService
  onEdit?: () => void
}

export const OrderService = ({ service, onEdit }: OrderServiceProps) => {
  return (
    <Grid container spacing={1} alignItems="center" wrap="nowrap">
      <Grid item flexGrow={1}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs={12}>
            <Typography variant="body1" color="primary">
              {service.name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="body2">
              {`${getDollars(service.centsPerUnit)}\u00a0${
                service.currency.symbol
              }\u00a0за\u00a0${service.rate.forOneName}`}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="body2">
              {`${service.units}\u00a0${pluralize(service.units, [
                service.rate.name,
                service.rate.severalName,
                service.rate.multipleName,
              ])}`}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="body2">
              {`${localizeNumber(
                getDollars(getServiceTotal(service), true),
              )}\u00a0${service.currency.symbol}${
                service.discount ? `\u00a0(скидка ${service.discount}%)` : ''
              }`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <IconButton aria-label="Редактировать" onClick={onEdit} size="large">
          <EditIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}
