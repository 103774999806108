import { gql, useMutation } from '@apollo/client'

import { ORDER, INPUT_ERROR } from 'api'
import { Order, InputError } from 'types'

export const CREATE_ORDER_COMMENT = gql`
  mutation CreateOrderComment($input: CreateOrderCommentInput!) {
    createOrderComment(input: $input) {
      order {
        ...Order
      }
      errors {
        ...InputError
      }
    }
  }
  ${ORDER}
  ${INPUT_ERROR}
`

type CreateOrderCommentDataSuccess = {
  order: Order
  errors: null
}

type CreateOrderCommentDataInputError = {
  order: null
  errors: InputError<'content'>[]
}

export type CreateOrderCommentData = {
  createOrderComment:
    | CreateOrderCommentDataSuccess
    | CreateOrderCommentDataInputError
}

export type CreateOrderCommentVariables = {
  input: {
    orderId: string
    content: string
  }
}

export const useCreateOrderCommentMutation = () => {
  return useMutation<CreateOrderCommentData, CreateOrderCommentVariables>(
    CREATE_ORDER_COMMENT,
  )
}
