import { Grid, Typography, IconButton } from '@mui/material'
import { Edit as EditIcon } from '@mui/icons-material'

import { Experience } from 'types'

type PerformerExperienceProps = {
  experience: Experience
  onEdit?: () => void
}

export const PerformerExperience = ({
  experience,
  onEdit,
}: PerformerExperienceProps) => {
  return (
    <Grid container spacing={1} alignItems="center" wrap="nowrap">
      <Grid item sx={{ flexGrow: 1 }}>
        <Grid container spacing={1} direction="column">
          <Grid item xs={12}>
            <Typography variant="body1">
              {experience.organizationName}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">{experience.position}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Grid container justifyContent="flex-start">
                  <Typography variant="body2">{`${experience.startYear}\u00a0-\u00a0`}</Typography>
                  <Typography variant="body2">
                    {experience.endYear
                      ? experience.endYear
                      : 'По настоящее время'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  {experience.country.name}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  {experience.achievements}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <IconButton aria-label="Редактировать" onClick={onEdit} size="large">
          <EditIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}
