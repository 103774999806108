import { KeyboardEvent, MouseEvent, ReactNode } from 'react'
import { styled } from '@mui/material/styles'
import { AppBar, Box, Drawer, Toolbar } from '@mui/material'

import { drawerWidth } from './utils'

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

type MobileNavbarProps = {
  setIsMenuVisible: (value: boolean) => void
  isMenuVisible: boolean
  drawerContent: ReactNode
  toolbarContent: ReactNode
  children: ReactNode
}

export const MobileNavbar = ({
  setIsMenuVisible,
  isMenuVisible,
  drawerContent,
  toolbarContent,
  children,
}: MobileNavbarProps) => {
  const closeDrawer = () => (event: KeyboardEvent | MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as KeyboardEvent).key === 'Tab' ||
        (event as KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    setIsMenuVisible(false)
  }

  return (
    <>
      <AppBar position="fixed">
        <Toolbar>{toolbarContent}</Toolbar>
      </AppBar>
      <Drawer anchor="left" open={isMenuVisible} onClose={closeDrawer()}>
        <Box
          width={drawerWidth}
          role="presentation"
          onClick={closeDrawer()}
          onKeyDown={closeDrawer()}
        >
          {drawerContent}
        </Box>
      </Drawer>
      <Box width="100%">
        <DrawerHeader />
        {children}
      </Box>
    </>
  )
}
