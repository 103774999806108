import { QueryHookOptions, gql, useQuery } from '@apollo/client'

import { CURRENCY } from 'api'
import { Currency } from 'types'

export type CurrenciesData = {
  currencies: Currency[]
}

export const GET_CURRENCIES = gql`
  query GetCurrencies {
    currencies {
      ...Currency
    }
  }
  ${CURRENCY}
`

export const currenciesQueryOptions: QueryHookOptions<CurrenciesData> = {
  errorPolicy: 'all',
}

export function useCurrenciesQuery() {
  return useQuery<CurrenciesData>(GET_CURRENCIES, currenciesQueryOptions)
}
