import { Grid, IconButton, List } from '@mui/material'
import { Edit as EditIcon } from '@mui/icons-material'

import { Company } from 'types'

import { CompanyEmployeeCard } from './CompanyEmployeeCard'

type CompanyEmployeesProps = {
  company: Company
  onEdit?: () => void
}

export const CompanyEmployees = ({
  company: { employees },
  onEdit,
}: CompanyEmployeesProps) => {
  return (
    <Grid container spacing={1} alignItems="center" wrap="nowrap">
      <Grid item sx={{ flexGrow: 1 }}>
        <List>
          {employees.length > 0
            ? employees.map((employee) => (
                <CompanyEmployeeCard employee={employee} key={employee.id} />
              ))
            : 'Пока ни одного сотрудника не добавлено'}
        </List>
      </Grid>
      <Grid item>
        <IconButton aria-label="Редактировать" onClick={onEdit} size="large">
          <EditIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}
