import { ReactNode } from 'react'
import * as Sentry from '@sentry/react'
import { styled } from '@mui/material/styles'
import { Container, Link, Typography } from '@mui/material'

import { useCurrentUserQuery } from 'api'

const StyledContainer = styled(Container)`
  min-height: 100vh;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`

const ErrorMessage = () => {
  return (
    <StyledContainer>
      <Typography variant="body1">
        К сожалению, произошла ошибка. Попробуйте перезагрузить страницу или
        напишите нам на{' '}
        <Link href="mailto:info@proling.ru">info@proling.ru</Link>.
      </Typography>
    </StyledContainer>
  )
}

export const ErrorBoundary = ({ children }: { children: ReactNode }) => {
  const { data } = useCurrentUserQuery()
  const currentUser = data?.currentUser

  return (
    <Sentry.ErrorBoundary
      fallback={<ErrorMessage />}
      showDialog
      {...(currentUser && {
        dialogOptions: {
          user: {
            name: `${currentUser.firstName} ${currentUser.lastName}`,
            email: currentUser.email,
          },
          title: 'Кажется, у нас что-то сломалось 🤒',
          subtitle: 'Мы уже начали изучать эту ошибку.',
          subtitle2:
            'Если желаете нам помочь - подскажите, что вы пытались сделать.',
          labelSubmit: 'Отправить',
        },
      })}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}
