import { QueryHookOptions, gql, useQuery } from '@apollo/client'

import { COMPANY } from 'api'
import { Company } from 'types'

export type CompanyData = {
  company: Company
}

export type CompanyVariables = {
  id: string
}

export const GET_SERVICES = gql`
  query GetCompany($id: ID!) {
    company(id: $id) {
      ...Company
    }
  }
  ${COMPANY}
`

export const defaultCompanyQueryOptions: QueryHookOptions<
  CompanyData,
  CompanyVariables
> = {
  errorPolicy: 'all',
}

export function useCompanyQuery(
  companyId: string,
  companyQueryOptions?: Omit<
    QueryHookOptions<CompanyData, CompanyVariables>,
    'variables'
  >,
) {
  return useQuery<CompanyData, CompanyVariables>(GET_SERVICES, {
    ...defaultCompanyQueryOptions,
    ...companyQueryOptions,
    variables: {
      id: companyId,
    },
  })
}
