import { useCallback, useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { Grid, IconButton, InputAdornment, Typography } from '@mui/material'
import { Clear as ClearIcon } from '@mui/icons-material'
import useInfiniteScroll from 'react-infinite-scroll-hook'

import { usePaginatedCompaniesQuery } from 'api'
import { Field, FixedButton, Spinner } from 'components'
import { useDebounce, useInitialSearchParams } from 'utils'
import { CompanyCard } from './CompanyCard'

const getCompaniesFooterText = (itemsLength: number, totalCount: number) => {
  if (totalCount === 0) return 'К сожалению, никого не найдено по запросу'
  if (itemsLength === totalCount)
    return 'Это все компании, найденные по запросу'
  return ''
}

type CompaniesSearchFormData = {
  search: string
}

export const CompaniesList = () => {
  const initialSearchParams = useInitialSearchParams()
  const [, setSearchParams] = useSearchParams()

  const methods = useForm<CompaniesSearchFormData>({
    defaultValues: {
      search: initialSearchParams.get('search') || '',
    },
    mode: 'onBlur',
    shouldUnregister: false,
  })

  const { search } = useDebounce<CompaniesSearchFormData>(methods.watch(), 300)

  const variables = useMemo(
    () => ({
      ...(search && { search }),
    }),
    [search],
  )

  const {
    data: paginatedCompaniesData,
    error: paginatedCompaniesError,
    loading: paginatedCompaniesLoading,
    fetchMore,
  } = usePaginatedCompaniesQuery({
    variables,
  })

  const paginatedCompaniesHasNextPage = Boolean(
    paginatedCompaniesData?.paginatedCompanies.pageInfo.endCursor,
  )

  const [sentryRef] = useInfiniteScroll({
    loading: paginatedCompaniesLoading,
    hasNextPage: paginatedCompaniesHasNextPage,
    onLoadMore: fetchMore,
    disabled: Boolean(paginatedCompaniesError),
    rootMargin: '0px 0px 400px 0px',
  })

  useEffect(() => {
    setSearchParams({ ...(search && { search }) })
  }, [setSearchParams, search])

  const resetSearchField = useCallback(() => {
    methods.resetField('search')
  }, [methods])

  return (
    <>
      <FormProvider {...methods}>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="stretch"
          sx={{ mt: 1 }}
        >
          <Grid item xs={12} flexGrow={1}>
            <Field
              name="search"
              label="Поиск"
              withoutHelperText
              {...(search && {
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="clear search input"
                        onClick={resetSearchField}
                        size="small"
                        edge="end"
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              })}
            />
          </Grid>
          {paginatedCompaniesLoading && !paginatedCompaniesData ? (
            <Grid item xs={12}>
              <Spinner />
            </Grid>
          ) : (
            paginatedCompaniesData && (
              <>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {paginatedCompaniesData.paginatedCompanies.items.map(
                      (company) => (
                        <Grid item xs={12} key={company.id}>
                          <CompanyCard company={company} />
                        </Grid>
                      ),
                    )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  alignItems="flex-start"
                  justifyContent="center"
                  sx={{ height: 80 }}
                >
                  {paginatedCompaniesHasNextPage ||
                  paginatedCompaniesLoading ? (
                    <div ref={sentryRef} />
                  ) : null}
                  {paginatedCompaniesLoading ? (
                    <Spinner />
                  ) : (
                    <Typography variant="body1" color="primary">
                      {getCompaniesFooterText(
                        paginatedCompaniesData.paginatedCompanies.items.length,
                        paginatedCompaniesData.paginatedCompanies.pageInfo
                          .totalCount,
                      )}
                    </Typography>
                  )}
                </Grid>
              </>
            )
          )}
        </Grid>
      </FormProvider>
      <FixedButton ariaLabel="Add company" to="/companies/create" />
    </>
  )
}
