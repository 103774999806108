import { MultipleEditableRoot, MultipleEditableItem } from 'components'
import { useMultipleForms } from 'utils'
import { Performer } from 'types'

import { PerformerEducationForm } from './PerformerEducationForm'
import { EditablePerformerEducationItem } from './EditablePerformerEducationItem'

type EditablePerformerEducationProps = {
  performer: Performer
}

export const EditablePerformerEducation = ({
  performer,
}: EditablePerformerEducationProps) => {
  const { forms, addForm, removeForm } = useMultipleForms()

  const buttonTitle =
    performer.education.length > 0 || forms.length > 0
      ? 'Добавить ещё'
      : 'Добавить'

  const isDeletable = performer.education.length > 1

  return (
    <MultipleEditableRoot onAddForm={addForm} buttonTitle={buttonTitle}>
      {performer.education.map((education) => (
        <MultipleEditableItem key={education.id}>
          <EditablePerformerEducationItem
            education={education}
            performer={performer}
            isDeletable={isDeletable}
          />
        </MultipleEditableItem>
      ))}
      {forms.map((itemKey) => (
        <MultipleEditableItem key={itemKey}>
          <PerformerEducationForm
            performer={performer}
            onClose={removeForm(itemKey)}
          />
        </MultipleEditableItem>
      ))}
    </MultipleEditableRoot>
  )
}
