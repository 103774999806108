import { useEditable } from 'utils'

import { PerformerSkills } from './PerformerSkills'
import { PerformerSkillsForm } from './PerformerSkillsForm'
import { Performer } from 'types'

type EditablePerformerSkillsProps = {
  performer: Performer
  isEditingInitial?: boolean
  onSubmit?: () => void
}

export const EditablePerformerSkills = ({
  performer,
  isEditingInitial = false,
}: EditablePerformerSkillsProps) => {
  const { isEditing, handleEdit, handleClose } = useEditable(isEditingInitial)

  if (isEditing) {
    return <PerformerSkillsForm performer={performer} onClose={handleClose} />
  }

  return <PerformerSkills performer={performer} onEdit={handleEdit} />
}
