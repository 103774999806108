import { Routes, Route } from 'react-router-dom'

import { Layout, RequireAuth } from 'components'
import { OrdersList } from './OrdersList'
import { Order } from './Order'
import { CreateOrder } from './CreateOrder'

export const Orders = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequireAuth roles={['employee', 'admin']}>
            <Layout>
              <OrdersList />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path="create"
        element={
          <RequireAuth roles={['employee', 'admin']}>
            <Layout maxWidth="md">
              <CreateOrder />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path=":id"
        element={
          <RequireAuth roles={['employee', 'admin']}>
            <Layout maxWidth="md">
              <Order />
            </Layout>
          </RequireAuth>
        }
      />
    </Routes>
  )
}
