import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from '@mui/material'
import { Store as StoreIcon } from '@mui/icons-material'
import { format } from 'date-fns'

import { getServicesTotalDollars, localizeNumber } from 'utils'
import type { Order } from 'types'

type OrderCardProps = {
  order: Order
}

export const OrderCard = ({ order }: OrderCardProps) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const search = searchParams.get('search')

  const handleClick = useCallback(() => {
    const backButtonTitle = search ? 'К результатам поиска' : 'К заказам'

    navigate(`/orders/${order.id}`, {
      state: {
        backButtonTitle,
      },
    })
  }, [navigate, order, search])

  const total = useMemo(
    () => getServicesTotalDollars(order.services),
    [order.services],
  )

  return (
    <Card>
      <CardActionArea
        onClick={handleClick}
        sx={{ display: 'flex', justifyContent: 'flex-start' }}
      >
        <CardContent sx={{ display: 'flex', flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} container spacing={2} alignItems="center">
              <Grid item flexGrow={1} container spacing={1}>
                <Grid item xs={12} md={3}>
                  <Typography variant="caption" color="primary">
                    №
                  </Typography>
                  <Typography variant="h6">{order.id}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography variant="caption" color="primary">
                    Статус
                  </Typography>
                  <Typography variant="body1">{order.status.name}</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="caption" color="primary">
                    Время выполнения
                  </Typography>
                  <Typography variant="body1">
                    {format(new Date(order.startAt), 'dd.MM.yyyy, kk:mm')}
                    {' - '}
                    {format(new Date(order.endAt), 'dd.MM.yyyy, kk:mm')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography variant="caption" color="primary">
                    Итоговая стоимость
                  </Typography>
                  <Typography variant="body1">
                    {localizeNumber(total)}
                  </Typography>
                </Grid>
              </Grid>
              {order.customer && (
                <Grid item xs={12} md={3}>
                  <Typography variant="caption" color="primary">
                    Заказчик
                  </Typography>
                  <Grid
                    container
                    gap={1}
                    alignItems="center"
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    <Avatar
                      src={order.customer.profile.avatar}
                      sx={{ width: 40, height: 40 }}
                    />
                    <Typography variant="body1">
                      {`${order.customer.profile.firstName} ${order.customer.profile.lastName}`}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {order.company && (
                <Grid item xs={12} md={3}>
                  <Typography variant="caption" color="primary">
                    Компания
                  </Typography>
                  <Grid
                    container
                    gap={1}
                    alignItems="center"
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    <Avatar
                      src={order.company.logo}
                      sx={{ width: 40, height: 40 }}
                    >
                      <StoreIcon sx={{ width: '75%', height: '75%' }} />
                    </Avatar>
                    <Typography variant="body1">
                      {order.company.name}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
