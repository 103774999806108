import { useCallback, SyntheticEvent } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Grid, Button, useMediaQuery, Theme } from '@mui/material'

import {
  useCreateEducationMutation,
  useUpdateEducationMutation,
  useDeleteEducationMutation,
} from 'api'
import {
  ButtonContainerWithSpinner,
  Field,
  CountrySelectField,
  YearField,
  UploadField,
} from 'components'
import { Education, Performer } from 'types'

const acceptedExtensions = ['pdf', 'jpg', 'jpeg', 'png']

type PerformerEducationFormData = {
  organizationName: string
  graduationYear: string
  countryId: string
  major?: string
  diploma?: File | string
}

type PerformerEducationFormProps = {
  onClose: () => void
  performer: Performer
  education?: Education
  isDeletable?: boolean
}

export const PerformerEducationForm = ({
  onClose,
  education,
  performer,
  isDeletable,
}: PerformerEducationFormProps) => {
  const isMatchSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
  const [createEducation, { loading: createEducationLoading }] =
    useCreateEducationMutation()
  const [updateEducation, { loading: updateEducationLoading }] =
    useUpdateEducationMutation()
  const [deleteEducation, { loading: deleteEducationLoading }] =
    useDeleteEducationMutation()

  const isSubmittingCreateOrUpdate =
    createEducationLoading || updateEducationLoading

  const methods = useForm<PerformerEducationFormData>({
    defaultValues: education
      ? {
          organizationName: education.organizationName,
          graduationYear: String(education.graduationYear),
          major: education.major,
          countryId: education.country.id,
          diploma: education.diploma,
        }
      : {
          organizationName: '',
          graduationYear: '',
          major: '',
          countryId: '',
          diploma: '',
        },
    mode: 'onBlur',
  })

  const onSubmit = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault()

      await methods.handleSubmit(
        async ({ diploma, graduationYear, ...formData }) => {
          const data = {
            ...formData,
            graduationYear: Number.parseInt(graduationYear),
            ...(typeof diploma !== 'string' && { diploma }),
          }

          if (education) {
            await updateEducation({
              variables: { input: { id: education.id, ...data } },
            })
          } else if (performer) {
            await createEducation({
              variables: { input: { performerId: performer.id, ...data } },
            })
          }

          onClose()
        },
      )()
    },
    [methods, education, onClose, createEducation, updateEducation, performer],
  )

  const handleDelete = useCallback(() => {
    if (education) {
      deleteEducation({ variables: { id: education.id } })
    }
  }, [education, deleteEducation])

  return (
    <FormProvider {...methods}>
      <Grid
        container
        spacing={2}
        noValidate
        autoComplete="off"
        onSubmit={onSubmit}
        component="form"
      >
        <Grid item xs={12}>
          <Field
            name="organizationName"
            label="Учебное заведение"
            rules={{
              required: true,
              maxLength: {
                value: 255,
                message: 'Не более 255 символов',
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <YearField
            name="graduationYear"
            label="Год выпуска"
            placeholder="2000"
            rules={{
              minLength: 4,
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CountrySelectField
            name="countryId"
            rules={{ required: true }}
            label="Страна"
            hasEmptyOption
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="major"
            label="Специализация"
            rules={{
              maxLength: {
                value: 255,
                message: 'Не более 255 символов',
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <UploadField
            name="diploma"
            label="Загрузить диплом"
            labelActive="Приложите диплом"
            accept={acceptedExtensions}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            spacing={isMatchSm ? 2 : 0}
            wrap="nowrap"
          >
            {education && isDeletable && (
              <Grid item>
                <ButtonContainerWithSpinner isLoading={deleteEducationLoading}>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={handleDelete}
                    disabled={deleteEducationLoading}
                  >
                    Удалить
                  </Button>
                </ButtonContainerWithSpinner>
              </Grid>
            )}
            <Grid item>
              <Button variant="text" color="primary" onClick={onClose}>
                Отменить
              </Button>
            </Grid>
            <Grid item>
              <ButtonContainerWithSpinner
                isLoading={isSubmittingCreateOrUpdate}
              >
                <Button
                  variant="text"
                  color="primary"
                  type="submit"
                  disabled={isSubmittingCreateOrUpdate}
                >
                  Сохранить
                </Button>
              </ButtonContainerWithSpinner>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}
