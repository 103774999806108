import { gql } from '@apollo/client'

import {
  COUNTRY,
  CURRENCY,
  GENDER,
  LANGUAGE,
  MANAGER,
  RATE,
  SOFTWARE,
  TOPIC,
} from 'api/fragments'

export const EDUCATION = gql`
  fragment Education on Education {
    id
    diploma
    organizationName
    graduationYear
    major
    country {
      ...Country
    }
  }
  ${COUNTRY}
`

export const EXPERIENCE = gql`
  fragment Experience on Experience {
    id
    organizationName
    position
    achievements
    startYear
    endYear
    country {
      ...Country
    }
  }
  ${COUNTRY}
`

export const PERFORMER_COMMENT = gql`
  fragment PerformerComment on PerformerComment {
    id
    content
    insertedAt
    updatedAt
    manager {
      ...Manager
    }
  }
  ${MANAGER}
`

export const PERFORMER_STATUS = gql`
  fragment PerformerStatus on PerformerStatus {
    id
    name
  }
`

export const PERFORMER_SERVICE_GROUP = gql`
  fragment PerformerServiceGroup on PerformerServiceGroup {
    id
    name
    isLanguageService
    hasTopics
    hasSoftware
  }
`

export const PERFORMER_LANGUAGE_SERVICE = gql`
  fragment PerformerLanguageService on PerformerLanguageService {
    id
    centsPerUnit
    currency {
      ...Currency
    }
    sourceLanguage {
      ...Language
    }
    targetLanguage {
      ...Language
    }
  }
  ${LANGUAGE}
  ${CURRENCY}
`

export const PERFORMER_SERVICE_TYPE = gql`
  fragment PerformerServiceType on PerformerServiceType {
    id
    name
    group {
      ...PerformerServiceGroup
    }
    rate {
      ...Rate
    }
  }
  ${PERFORMER_SERVICE_GROUP}
  ${RATE}
`

export const PERFORMER_SERVICE = gql`
  fragment PerformerService on PerformerService {
    id
    centsPerUnit
    type {
      ...PerformerServiceType
    }
    currency {
      ...Currency
    }
    languageServices {
      ...PerformerLanguageService
    }
  }
  ${PERFORMER_SERVICE_TYPE}
  ${PERFORMER_LANGUAGE_SERVICE}
  ${CURRENCY}
`

export const PERFORMER = gql`
  fragment Performer on Performer {
    id
    bio
    nativeLanguage {
      ...Language
    }
    cv
    isOnboardingFinished
    profile {
      id
      avatar
      firstName
      lastName
      email
      phone
      birthday
      city
      gender {
        ...Gender
      }
      country {
        ...Country
      }
    }
    education {
      ...Education
    }
    experience {
      ...Experience
    }
    topics {
      ...Topic
    }
    software {
      ...Software
    }
    services {
      ...PerformerService
    }
    comments {
      ...PerformerComment
    }
    status {
      ...PerformerStatus
    }
  }
  ${GENDER}
  ${COUNTRY}
  ${LANGUAGE}
  ${TOPIC}
  ${SOFTWARE}
  ${EDUCATION}
  ${EXPERIENCE}
  ${PERFORMER_COMMENT}
  ${PERFORMER_SERVICE}
  ${PERFORMER_STATUS}
`
