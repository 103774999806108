import { useCallback, useMemo, ChangeEvent, FocusEvent } from 'react'
import {
  useFormContext,
  useController,
  get,
  RegisterOptions,
} from 'react-hook-form'
import { TextFieldProps } from '@mui/material'
import MuiPhoneNumber from 'material-ui-phone-number'

import { mergeRefs } from 'utils'

export type PhoneFieldProps = TextFieldProps & {
  name: string
  rules?: RegisterOptions
  defaultValue?: unknown
}

export const PhoneField = ({
  name,
  rules,
  onBlur,
  onChange,
  defaultValue,
  inputRef,
  inputProps,
  ...props
}: PhoneFieldProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const error = get(errors, name)

  const { field } = useController({ name, control, rules, defaultValue })

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      field.onBlur()
      onBlur?.(e)
    },
    [field, onBlur],
  )

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      field.onChange(e)
      onChange?.(e)
    },
    [field, onChange],
  )

  const memoizedInputProps = useMemo(
    () => ({
      ...inputProps,
      ref: inputRef ? mergeRefs([field.ref, inputRef]) : field.ref,
    }),
    [field, inputRef, inputProps],
  )

  return (
    <MuiPhoneNumber
      defaultCountry="ru"
      fullWidth
      margin="none"
      variant="outlined"
      helperText={error?.message || ' '}
      id={name}
      name={name}
      inputProps={memoizedInputProps}
      value={field.value}
      onChange={handleChange}
      onBlur={handleBlur}
      {...(error && { error: Boolean(error) })}
      {...props}
    />
  )
}
