import { QueryHookOptions, gql, useQuery } from '@apollo/client'

import { LANGUAGE } from 'api'
import { Language } from 'types'

export type LanguagesData = {
  languages: Language[]
}

export const GET_LANGUAGES = gql`
  query GetLanguages {
    languages {
      ...Language
    }
  }
  ${LANGUAGE}
`

export const languagesQueryOptions: QueryHookOptions<LanguagesData> = {
  errorPolicy: 'all',
}

export function useLanguagesQuery() {
  return useQuery<LanguagesData>(GET_LANGUAGES, languagesQueryOptions)
}
