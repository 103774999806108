import { gql, useMutation } from '@apollo/client'

import { SERVICE, INPUT_ERROR } from 'api'
import { Service, InputError } from 'types'

export const UPDATE_SERVICE = gql`
  mutation UpdateService($input: UpdateServiceInput!) {
    updateService(input: $input) {
      service {
        ...Service
      }
      errors {
        ...InputError
      }
    }
  }
  ${SERVICE}
  ${INPUT_ERROR}
`

type UpdateServiceDataSuccess = {
  service: Service
  errors: null
}

type UpdateServiceDataInputError = {
  service: null
  errors: InputError<
    | 'name'
    | 'centsPerUnit'
    | 'currencyId'
    | `language_services.${number}.source_language_id`
  >[]
}

export type UpdateServiceData = {
  updateService: UpdateServiceDataSuccess | UpdateServiceDataInputError
}

type UpdateLanguageServiceVariables = {
  id?: string
  centsPerUnit: number
  currencyId: string
  sourceLanguageId: string
  targetLanguageId: string
}

export type UpdateServiceVariables = {
  input: {
    id: string
    name: string
    centsPerUnit: number | null
    rateId: string
    currencyId: string | null
    languageServices: UpdateLanguageServiceVariables[]
  }
}

export const useUpdateServiceMutation = () => {
  return useMutation<UpdateServiceData, UpdateServiceVariables>(UPDATE_SERVICE)
}
