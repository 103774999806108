import { QueryHookOptions, gql, useQuery } from '@apollo/client'

import { PERFORMER_SERVICE_GROUP } from 'api'
import { PerformerServiceGroup } from 'types'

export type PerformerServiceGroupsData = {
  performerServiceGroups: PerformerServiceGroup[]
}

export const GET_PERFORMER_SERVICE_GROUPS = gql`
  query GetPerformerServiceGroups {
    performerServiceGroups {
      ...PerformerServiceGroup
    }
  }
  ${PERFORMER_SERVICE_GROUP}
`

export const performerServiceGroupsQueryOptions: QueryHookOptions<PerformerServiceGroupsData> =
  {
    errorPolicy: 'all',
  }

export function usePerformerServiceGroupsQuery() {
  return useQuery<PerformerServiceGroupsData>(
    GET_PERFORMER_SERVICE_GROUPS,
    performerServiceGroupsQueryOptions,
  )
}
