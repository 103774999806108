import { Box, Button, Grid, IconButton, Typography } from '@mui/material'
import { GetApp as GetAppIcon, Edit as EditIcon } from '@mui/icons-material'

import { Performer } from 'types'

type PerformerInfoProps = {
  onEdit?: () => void
  performer: Performer
}

export const PerformerInfo = ({ onEdit, performer }: PerformerInfoProps) => {
  const {
    bio,
    nativeLanguage,
    cv,
    profile: { city, birthday, country, gender },
  } = performer

  return (
    <Grid container direction="column">
      <Grid container spacing={1} alignItems="center" wrap="nowrap">
        <Grid item flexGrow={1}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="caption" color="primary">
                Дата рождения
              </Typography>
              <Typography variant="body1">{birthday}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" color="primary">
                Город
              </Typography>
              <Typography variant="body1">{city}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" color="primary">
                Страна проживания
              </Typography>
              <Typography variant="body1">{country?.name}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" color="primary">
                Родной язык
              </Typography>
              <Typography variant="body1">{nativeLanguage?.name}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" color="primary">
                Пол
              </Typography>
              <Typography variant="body1">{gender?.name}</Typography>
            </Grid>
            {bio && (
              <Grid item xs={12}>
                <Typography variant="caption" color="primary">
                  О себе
                </Typography>
                <Typography variant="body1">{bio}</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <IconButton aria-label="Редактировать" onClick={onEdit} size="large">
            <EditIcon />
          </IconButton>
        </Grid>
      </Grid>
      {cv && (
        <Box mt={3} textAlign="center">
          <Button
            variant="outlined"
            color="primary"
            href={cv}
            startIcon={<GetAppIcon />}
          >
            Скачать резюме
          </Button>
        </Box>
      )}
    </Grid>
  )
}
