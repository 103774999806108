import { useEffect, useMemo, useCallback } from 'react'
import { useFormContext, get, RegisterOptions } from 'react-hook-form'
import { useDropzone, FileWithPath, FileRejection } from 'react-dropzone'
import { styled } from '@mui/material/styles'
import {
  Chip,
  Grid,
  GridProps,
  FormHelperText,
  Typography,
  TypographyProps,
} from '@mui/material'

import { getAcceptExtensions, getFilenameFromS3Path } from 'utils'

// Styles applied for matching regular OutlinedInput 56px height in Material-UI
export const UploadFieldZone = styled(Grid, {
  shouldForwardProp: (prop) =>
    !['isDragActive', 'error'].includes(prop as string),
})<GridProps & { isDragActive: boolean; error: boolean }>(
  ({ theme, isDragActive, error }) => `
    position: relative;
    border: 1px dashed;
    box-sizing: border-box;
    cursor: pointer;
    padding: 17.5px 14px;
    border-radius: ${theme.shape.borderRadius};
    border-color: ${
      error
        ? theme.palette.error.main
        : isDragActive
        ? theme.palette.primary.main
        : theme.palette.mode === 'light' // Matching Material-UI OutlinedInput border-color
        ? 'rgba(0, 0, 0, 0.23)'
        : 'rgba(255, 255, 255, 0.23)'
    };
    outline: none;

    &:hover {
      border-color: ${!error ? theme.palette.text.primary : 'inherit'};
    }
`,
)

// Reset to 19px, match the native input line-height
export const UploadFieldLabel = styled(Typography, {
  shouldForwardProp: (prop) =>
    !['isDragActive', 'error'].includes(prop as string),
})<TypographyProps & { isDragActive: boolean; error: boolean }>(
  ({ theme, isDragActive, error }) => `
  height: 1.1876em; 
  color: ${
    error
      ? theme.palette.error.main
      : isDragActive
      ? theme.palette.primary.main
      : 'inherit'
  };
`,
)

export const getErrorMessageByCode = (code: string, maxFileSize: number) => {
  switch (code) {
    case 'file-invalid-type':
      return 'Нельзя загрузить файл такого формата'
    case 'file-too-large':
      return `Нельзя загрузить файл больше ${maxFileSize / 1024 / 1024} Мб`
    case 'too-many-files':
      return 'Нельзя загрузить несколько файлов'
    default:
      return 'Нельзя загрузить такой файл'
  }
}

export type UploadFieldProps = {
  name: string
  label: string
  labelActive: string
  accept?: string[]
  helperText?: string
  rules?: RegisterOptions
  maxFileSize?: number
}

export const UploadField = ({
  name,
  label,
  labelActive,
  accept,
  rules,
  helperText = ' ',
  maxFileSize = 5 * 1024 * 1024,
}: UploadFieldProps) => {
  const {
    register,
    setError,
    clearErrors,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext()

  useEffect(() => {
    register(name, rules)
  }, [name, register, rules])

  const value = watch(name)

  const error = get(errors, name)

  const acceptExtensions = useMemo(
    () => getAcceptExtensions(accept || []),
    [accept],
  )

  const onDrop = (acceptedFiles: FileWithPath[]) => {
    if (error) clearErrors(name)
    setValue(name, acceptedFiles[0] || null)
  }

  const onDropRejected = useCallback(
    (fileRejections: FileRejection[]) => {
      const errorCode = fileRejections[0].errors[0].code

      setError(name, {
        type: errorCode,
        message: getErrorMessageByCode(errorCode, maxFileSize),
      })
    },
    [name, maxFileSize, setError],
  )

  const onFileRemove = useCallback(() => {
    setValue(name, null)
  }, [name, setValue])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    maxSize: maxFileSize,
    multiple: false,
    ...(accept && { accept: acceptExtensions }),
  })

  return (
    <Grid container spacing={2}>
      {!value ? (
        <Grid item xs={12}>
          <UploadFieldZone
            container
            justifyContent="center"
            isDragActive={isDragActive}
            error={Boolean(error)}
            {...getRootProps()}
          >
            <input data-testid="upload-input" {...getInputProps()} />
            <UploadFieldLabel
              variant="body1"
              isDragActive={isDragActive}
              error={Boolean(error)}
            >
              {isDragActive ? (labelActive ? labelActive : label) : label}
            </UploadFieldLabel>
          </UploadFieldZone>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Grid
            container
            direction="column"
            alignItems="center"
            sx={{ my: 1.5 }}
          >
            <Chip
              variant="outlined"
              label={
                value && typeof value !== 'string'
                  ? value.path
                  : getFilenameFromS3Path(value)
              }
              onDelete={onFileRemove}
            />
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} container justifyContent="center">
        <FormHelperText error>
          {(typeof error?.message === 'string' && error.message) || helperText}
        </FormHelperText>
      </Grid>
    </Grid>
  )
}
