import { ReactNode } from 'react'
import { styled } from '@mui/material/styles'
import { CircularProgress } from '@mui/material'

const StyledContainer = styled('div')`
  position: relative;
`

const StyledSpinner = styled(CircularProgress)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
`

export type ButtonSpinnerContainer = {
  isLoading: boolean
  children: ReactNode
}

export const ButtonContainerWithSpinner = ({
  isLoading,
  children,
}: ButtonSpinnerContainer) => {
  return (
    <StyledContainer>
      {children}
      {isLoading && <StyledSpinner size={24} color="primary" />}
    </StyledContainer>
  )
}
