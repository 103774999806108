import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Avatar, Grid, IconButton, Typography } from '@mui/material'
import { Edit as EditIcon, Store as StoreIcon } from '@mui/icons-material'

import { format } from 'date-fns'

import { getServicesTotalDollars, localizeNumber } from 'utils'
import { Order as TOrder } from 'types'

type OrderProps = {
  onEdit?: () => void
  order: TOrder
}

export const Order = ({ onEdit, order }: OrderProps) => {
  const navigate = useNavigate()

  const { startAt, endAt, customer, company, services } = order

  const onCustomerClick = useCallback(() => {
    if (customer) {
      navigate(`/customers/${customer.id}`, {
        state: {
          backButtonTitle: 'К заказу',
        },
      })
    }
  }, [customer, navigate])

  const total = useMemo(() => getServicesTotalDollars(services), [services])

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Grid container spacing={1} alignItems="center" wrap="nowrap">
          <Grid item flexGrow={1}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="caption" color="primary">
                  Статус
                </Typography>
                <Typography variant="body1">{order.status.name}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" color="primary">
                  Время начала
                </Typography>
                <Typography variant="body1">
                  {format(new Date(startAt), 'dd.MM.yyyy, kk:mm')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" color="primary">
                  Время завершения
                </Typography>
                <Typography variant="body1">
                  {format(new Date(endAt), 'dd.MM.yyyy, kk:mm')}
                </Typography>
              </Grid>
              {customer && (
                <Grid item xs={12}>
                  <Typography variant="caption" color="primary">
                    Заказчик
                  </Typography>
                  <Grid
                    container
                    onClick={onCustomerClick}
                    gap={1}
                    alignItems="center"
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    <Avatar
                      src={customer.profile.avatar}
                      sx={{ width: 40, height: 40 }}
                    />
                    <Typography variant="body1">
                      {`${customer.profile.firstName} ${customer.profile.lastName}`}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {company && (
                <Grid item xs={12}>
                  <Typography variant="caption" color="primary">
                    Компания
                  </Typography>
                  <Grid
                    container
                    onClick={onCustomerClick}
                    gap={1}
                    alignItems="center"
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    <Avatar src={company.logo} sx={{ width: 40, height: 40 }}>
                      <StoreIcon sx={{ width: '75%', height: '75%' }} />
                    </Avatar>
                    <Typography variant="body1">{company.name}</Typography>
                  </Grid>
                </Grid>
              )}
              {total && (
                <Grid item xs={12}>
                  <Typography variant="caption" color="primary">
                    Итоговая стоимость
                  </Typography>
                  <Typography variant="body1">
                    {localizeNumber(total)}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="Редактировать"
              onClick={onEdit}
              size="large"
            >
              <EditIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
