import { useCallback, useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { Grid, IconButton, InputAdornment, Typography } from '@mui/material'
import { Clear as ClearIcon } from '@mui/icons-material'
import useInfiniteScroll from 'react-infinite-scroll-hook'

import { usePaginatedOrdersQuery } from 'api'
import { Field, FixedButton, Spinner } from 'components'
import { useDebounce, useInitialSearchParams } from 'utils'
import { OrderCard } from './OrderCard'

const getOrdersFooterText = (itemsLength: number, totalCount: number) => {
  if (totalCount === 0) return 'К сожалению, ничего не найдено по запросу'
  if (itemsLength === totalCount) return 'Это все заказы, найденные по запросу'
  return ''
}

type OrdersSearchFormData = {
  search: string
}

export const OrdersList = () => {
  const initialSearchParams = useInitialSearchParams()
  const [, setSearchParams] = useSearchParams()

  const methods = useForm<OrdersSearchFormData>({
    defaultValues: {
      search: initialSearchParams.get('search') || '',
    },
    mode: 'onBlur',
    shouldUnregister: false,
  })

  const { search } = useDebounce<OrdersSearchFormData>(methods.watch(), 300)

  const variables = useMemo(
    () => ({
      ...(search && { search }),
    }),
    [search],
  )

  const {
    data: paginatedOrdersData,
    error: paginatedOrdersError,
    loading: paginatedOrdersLoading,
    fetchMore,
  } = usePaginatedOrdersQuery({
    variables,
  })

  const paginatedOrdersHasNextPage = Boolean(
    paginatedOrdersData?.paginatedOrders.pageInfo.endCursor,
  )

  const [sentryRef] = useInfiniteScroll({
    loading: paginatedOrdersLoading,
    hasNextPage: paginatedOrdersHasNextPage,
    onLoadMore: fetchMore,
    disabled: Boolean(paginatedOrdersError),
    rootMargin: '0px 0px 400px 0px',
  })

  useEffect(() => {
    setSearchParams({ ...(search && { search }) })
  }, [setSearchParams, search])

  const resetSearchField = useCallback(() => {
    methods.resetField('search')
  }, [methods])

  return (
    <>
      <FormProvider {...methods}>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="stretch"
          sx={{ mt: 1 }}
        >
          <Grid item xs={12} flexGrow={1}>
            <Field
              name="search"
              label="Поиск"
              withoutHelperText
              {...(search && {
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="clear search input"
                        onClick={resetSearchField}
                        size="small"
                        edge="end"
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              })}
            />
          </Grid>
          {paginatedOrdersLoading && !Boolean(paginatedOrdersData) ? (
            <Grid item xs={12}>
              <Spinner />
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {paginatedOrdersData!.paginatedOrders.items.map((order) => (
                    <Grid item xs={12} key={order.id}>
                      <OrderCard order={order} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                container
                alignItems="flex-start"
                justifyContent="center"
                sx={{ height: 80 }}
              >
                {paginatedOrdersHasNextPage || paginatedOrdersLoading ? (
                  <div ref={sentryRef} />
                ) : null}
                {paginatedOrdersLoading ? (
                  <Spinner />
                ) : (
                  <Typography variant="body1" color="primary">
                    {getOrdersFooterText(
                      paginatedOrdersData!.paginatedOrders.items.length,
                      paginatedOrdersData!.paginatedOrders.pageInfo.totalCount,
                    )}
                  </Typography>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </FormProvider>
      <FixedButton ariaLabel="Add order" to="/orders/create" />
    </>
  )
}
