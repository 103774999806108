import { QueryHookOptions, gql, useQuery } from '@apollo/client'

import { TOPIC } from 'api'
import { Topic } from 'types'

export type TopicsData = {
  topics: Topic[]
}

export const GET_TOPICS = gql`
  query GetTopics {
    topics {
      ...Topic
    }
  }
  ${TOPIC}
`

export const topicsQueryOptions: QueryHookOptions<TopicsData> = {
  errorPolicy: 'all',
}

export function useTopicsQuery() {
  return useQuery<TopicsData>(GET_TOPICS, topicsQueryOptions)
}
