import { gql, useQuery } from '@apollo/client'
import * as Sentry from '@sentry/react'

import { USER, PERFORMER } from 'api'
import { User } from 'types'

export type CurrentUserData = {
  currentUser: User | null
}

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      ...User
      performer {
        ...Performer
      }
    }
  }
  ${USER}
  ${PERFORMER}
`

export function useCurrentUserQuery() {
  return useQuery<CurrentUserData>(GET_CURRENT_USER, {
    onCompleted: (data) => {
      if (process.env.NODE_ENV === 'production') {
        Sentry.setUser(data.currentUser)
      }
    },
  })
}
