import { useEditable } from 'utils'
import { Comment } from 'types'

import { CustomerComment } from './CustomerComment'
import { CustomerCommentForm } from './CustomerCommentForm'

type EditableCustomerCommentProps = {
  comment: Comment
}

export const EditableCustomerComment = ({
  comment,
}: EditableCustomerCommentProps) => {
  const { isEditing, handleEdit, handleClose } = useEditable()

  if (isEditing) {
    return <CustomerCommentForm comment={comment} onClose={handleClose} />
  }

  return <CustomerComment comment={comment} onEdit={handleEdit} />
}
