import { gql, useMutation } from '@apollo/client'

import { PERFORMER } from 'api'
import { Performer } from 'types'

export const DELETE_PERFORMER_SERVICE = gql`
  mutation DeletePerformerService($id: ID!) {
    deletePerformerService(id: $id) {
      performer {
        ...Performer
      }
    }
  }
  ${PERFORMER}
`

export type DeletePerformerServiceData = {
  deletePerformerService: { performer: Performer }
}

export type DeletePerformerServiceVariables = {
  id: string
}

export const useDeletePerformerServiceMutation = () => {
  return useMutation<
    DeletePerformerServiceData,
    DeletePerformerServiceVariables
  >(DELETE_PERFORMER_SERVICE)
}
