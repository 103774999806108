import { Link as RouterLink, useParams } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import {
  Box,
  Button,
  Grid,
  FormHelperText,
  Link,
  Typography,
} from '@mui/material'
import invariant from 'tiny-invariant'

import { useResetPasswordMutation } from 'api'
import { ButtonContainerWithSpinner, PasswordField, Logo } from 'components'
import { setFormErrors } from 'utils'

const errorsMessages = {
  password: {
    default: 'Нельзя установить такой пароль',
  },
}

type URLParams = {
  token: 'string'
}

type FormData = {
  password: string
}

export const ResetPassword = () => {
  const { token } = useParams<URLParams>()
  invariant(token, "Token isn't set")

  const [resetPassword, { loading, error }] = useResetPasswordMutation()
  const methods = useForm<FormData>({
    defaultValues: { password: '' },
    shouldFocusError: true,
  })

  const onSubmit = methods.handleSubmit(async ({ password }) => {
    const { data } = await resetPassword({
      variables: { input: { password: password, token } },
    })

    if (data?.resetPassword?.errors) {
      setFormErrors<FormData>(
        methods.setError,
        data.resetPassword.errors,
        errorsMessages,
      )
    }
  })

  return (
    <FormProvider {...methods}>
      <Grid
        container
        spacing={2}
        noValidate
        autoComplete="off"
        onSubmit={onSubmit}
        component="form"
      >
        <Grid item xs={12} container justifyContent="center">
          <Logo />
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <Box my={2}>
            <Typography variant="body1" color="primary">
              Введите новый пароль
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <PasswordField
            name="password"
            label="Пароль"
            rules={{
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <FormHelperText error>
            {error?.graphQLErrors.map(
              ({ message }: { message: string }, i: number) => (
                <span key={i}>
                  {message === 'invalid token'
                    ? 'Сначала нужно запросить восстановление пароля'
                    : 'Произошла неизвестная ошибка. Попробуйте ещё раз позднее'}
                </span>
              ),
            ) || ' '}
          </FormHelperText>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
        >
          <ButtonContainerWithSpinner isLoading={loading}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
            >
              Сменить пароль
            </Button>
          </ButtonContainerWithSpinner>
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <Link
            variant="body2"
            color="primary"
            component={RouterLink}
            to="/auth/sign-in"
          >
            Помню старый пароль
          </Link>
        </Grid>
      </Grid>
    </FormProvider>
  )
}
