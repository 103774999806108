import { useCallback } from 'react'
import { QueryHookOptions, gql, useQuery } from '@apollo/client'

import { PAGE_INFO } from 'api'
import { Company, SortOrder, PageInfo } from 'types'

const ITEMS_PER_PAGE = 30

export const cachePaginatedCompaniesKeyArgs = ['search', 'sortOrder']
export const cachePaginatedCompaniesItemsMerge = (
  existing: Company[],
  incoming: Company[],
) => {
  return existing ? [...existing, ...incoming] : incoming
}
export type PaginatedCompaniesData = {
  paginatedCompanies: {
    items: Company[]
    pageInfo: PageInfo
  }
}

export type PaginatedCompaniesVariables = {
  sortOrder?: {
    name?: SortOrder
  }
  search?: string
  pagination?: {
    limit?: number
    endCursor?: string
    startCursor?: string
  }
}

export const GET_PAGINATED_COMPANIES = gql`
  query GetPaginatedCompanies(
    $search: String
    $sortOrder: CompaniesSortOrder
    $pagination: PaginationInput
  ) {
    paginatedCompanies(
      search: $search
      sortOrder: $sortOrder
      pagination: $pagination
    ) {
      items {
        id
        name
        logo
        inn
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PAGE_INFO}
`

export const defaultPaginatedCompaniesQueryOptions: QueryHookOptions = {
  errorPolicy: 'all',
  notifyOnNetworkStatusChange: true,
}

export function usePaginatedCompaniesQuery(
  paginatedCompaniesQueryOptions?: QueryHookOptions,
) {
  const { data, loading, error, fetchMore, ...rest } = useQuery<
    PaginatedCompaniesData,
    PaginatedCompaniesVariables
  >(GET_PAGINATED_COMPANIES, {
    ...defaultPaginatedCompaniesQueryOptions,
    ...paginatedCompaniesQueryOptions,
    variables: {
      ...paginatedCompaniesQueryOptions?.variables,
      pagination: {
        limit: ITEMS_PER_PAGE,
        ...paginatedCompaniesQueryOptions?.variables?.pagination,
      },
    },
  })

  const endCursor = data?.paginatedCompanies.pageInfo.endCursor

  const patchedFetchMore = useCallback(() => {
    if (!loading && !error && endCursor) {
      fetchMore({
        variables: {
          pagination: {
            limit: ITEMS_PER_PAGE,
            ...paginatedCompaniesQueryOptions?.variables?.pagination,
            endCursor,
          },
        },
      })
    }
  }, [
    fetchMore,
    endCursor,
    loading,
    error,
    paginatedCompaniesQueryOptions?.variables?.pagination,
  ])

  return { data, loading, error, fetchMore: patchedFetchMore, ...rest }
}
