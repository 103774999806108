import { useCallback, useEffect, useMemo, SyntheticEvent } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Grid, Button, useMediaQuery, Theme } from '@mui/material'

import {
  useRatesQuery,
  useCreateOrderServiceMutation,
  useUpdateOrderServiceMutation,
  useDeleteOrderServiceMutation,
  HybridService,
} from 'api'
import {
  ButtonContainerWithSpinner,
  CurrencySelectField,
  HybridServiceField,
  RateSelectField,
  PercentageField,
  Field,
} from 'components'
import { setFormErrors, getCents, getDollars } from 'utils'
import { Order, OrderService } from 'types'
import invariant from 'tiny-invariant'

const errorsMessages = {
  name: {
    key: 'name' as const,
    default: 'Нельзя создать услугу с таким названием',
  },
  languageServicesId: {
    default: 'Нельзя сохранить с такой языковой парой',
  },
}

type OrderServiceFormData = {
  name: HybridService | string | null
  centsPerUnit: string
  units: string
  discount?: string
  rateId: string
  currencyId: string
  serviceId: string
  languageServiceId: string
}

type OrderServiceItemFormProps = {
  onClose: () => void
  service?: OrderService
  isDeletable?: boolean
  order: Order
}

export const OrderServiceForm = ({
  onClose,
  service,
  isDeletable,
  order,
}: OrderServiceItemFormProps) => {
  const isMatchSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
  const { data: ratesData } = useRatesQuery()

  const [createOrderService, { loading: createOrderServiceLoading }] =
    useCreateOrderServiceMutation()
  const [updateOrderService, { loading: updateOrderServiceLoading }] =
    useUpdateOrderServiceMutation()
  const [deleteOrderService, { loading: deleteOrderServiceLoading }] =
    useDeleteOrderServiceMutation()

  const isSubmittingCreateOrUpdate =
    createOrderServiceLoading || updateOrderServiceLoading

  const methods = useForm<OrderServiceFormData>({
    defaultValues: service
      ? {
        name: service.name,
        centsPerUnit: service.centsPerUnit
          ? String(getDollars(service.centsPerUnit))
          : '',
        units: String(service.units),
        discount: service.discount ? String(service.discount) : '',
        currencyId: service.currency.id,
        rateId: service.rate.id,
        serviceId: service.service?.id || '',
        languageServiceId: service.languageService?.id || '',
      }
      : {
        name: null,
        centsPerUnit: '',
        units: '',
        discount: '',
        rateId: '',
        currencyId: '',
        serviceId: '',
        languageServiceId: '',
      },
    mode: 'onChange',
    shouldUnregister: false,
  })

  const onSubmit = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault()

      await methods.handleSubmit(
        async ({
          name,
          units,
          discount,
          centsPerUnit,
          serviceId,
          languageServiceId,
          ...formData
        }) => {
          invariant(name, "Name can't be null")

          const payload = {
            ...formData,
            name:
              typeof name === 'string'
                ? name
                : name.__typename === 'Service'
                  ? name.name
                  : `${name.service.name} (${name.sourceLanguage.name} - ${name.targetLanguage.name})`,
            centsPerUnit: getCents(centsPerUnit),
            units: Number(units),
            discount: Number(discount),
            serviceId: (!languageServiceId && serviceId) || null,
            languageServiceId: (!serviceId && languageServiceId) || null,
          }

          if (service) {
            const { data } = await updateOrderService({
              variables: { input: { id: service.id, ...payload } },
            })

            if (data?.updateOrderService) {
              if (data.updateOrderService.errors) {
                setFormErrors(
                  methods.setError,
                  data.updateOrderService.errors,
                  errorsMessages,
                )
              } else {
                onClose()
              }
            }
          } else {
            const { data } = await createOrderService({
              variables: { input: { orderId: order.id, ...payload } },
            })
            if (data?.createOrderService) {
              if (data.createOrderService.errors) {
                setFormErrors(
                  methods.setError,
                  data.createOrderService.errors,
                  errorsMessages,
                )
              } else {
                onClose()
              }
            }
          }
        },
      )()
    },
    [methods, service, onClose, createOrderService, updateOrderService, order],
  )

  const handleDelete = useCallback(() => {
    if (service) {
      deleteOrderService({
        variables: { id: service.id },
      })
    }
  }, [service, deleteOrderService])

  const [name, rateId] = methods.watch(['name', 'rateId'])

  const rate = useMemo(
    () => ratesData?.rates.find(({ id }) => id === rateId),
    [ratesData, rateId],
  )

  useEffect(() => {
    if (typeof name === 'string' || name === null) return

    if (name.__typename === 'Service') {
      invariant(name.centsPerUnit, "Cents per units can't be null")
      invariant(name.currency, "Currency can't be null")

      methods.setValue('serviceId', name.id)
      methods.setValue('languageServiceId', '')
      methods.setValue('centsPerUnit', String(getDollars(name.centsPerUnit)))
      methods.setValue('currencyId', name.currency.id)
      methods.setValue('rateId', name.rate.id)
    } else {
      methods.setValue('serviceId', '')
      methods.setValue('languageServiceId', name.id)
      methods.setValue('centsPerUnit', String(getDollars(name.centsPerUnit)))
      methods.setValue('currencyId', name.currency.id)
      methods.setValue('rateId', name.service.rate.id)
    }

    if (!service) {
      methods.setFocus('units')
    }
  }, [name, methods, service])

  return (
    <FormProvider {...methods}>
      <Grid
        container
        spacing={2}
        noValidate
        autoComplete="off"
        onSubmit={onSubmit}
        component="form"
        sx={{ mt: 1 }}
      >
        <Grid item xs={12}>
          <HybridServiceField
            name="name"
            rules={{ required: true }}
            label="Название услуги"
          />
        </Grid>
        <Grid item xs={7} sm={4} md={3}>
          <Field
            name="centsPerUnit"
            rules={{ required: true }}
            label={`Стоимость${rate ? ' за ' + rate.forOneName : ''}`}
          />
        </Grid>
        <Grid item xs={5} sm={4} md={2}>
          <CurrencySelectField
            name="currencyId"
            rules={{ required: true }}
            label="Валюта"
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <RateSelectField
            name="rateId"
            rules={{ required: true }}
            label="Тип тарифа"
            hasEmptyOption
          />
        </Grid>
        <Grid item xs={6} sm={6} md={2}>
          <Field name="units" rules={{ required: true }} label="Количество" />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <PercentageField name="discount" label="Скидка" />
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            spacing={isMatchSm ? 2 : 0}
            wrap="nowrap"
          >
            {service && isDeletable && (
              <Grid item>
                <ButtonContainerWithSpinner
                  isLoading={deleteOrderServiceLoading}
                >
                  <Button
                    variant="text"
                    color="primary"
                    onClick={handleDelete}
                    disabled={deleteOrderServiceLoading}
                  >
                    Удалить
                  </Button>
                </ButtonContainerWithSpinner>
              </Grid>
            )}
            <Grid item>
              <Button variant="text" color="primary" onClick={onClose}>
                Отменить
              </Button>
            </Grid>
            <Grid item>
              <ButtonContainerWithSpinner
                isLoading={isSubmittingCreateOrUpdate}
              >
                <Button
                  variant="text"
                  color="primary"
                  type="submit"
                  disabled={isSubmittingCreateOrUpdate}
                >
                  Сохранить
                </Button>
              </ButtonContainerWithSpinner>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}
