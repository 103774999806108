import { useCallback, SyntheticEvent } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { format } from 'date-fns'
import { Grid, Button, useMediaQuery, Theme } from '@mui/material'

import {
  useCreateCompanyContractMutation,
  useUpdateCompanyContractMutation,
  useDeleteCompanyContractMutation,
} from 'api'
import {
  ButtonContainerWithSpinner,
  Field,
  DateField,
  UploadField,
} from 'components'
import { CompanyContract } from 'types'

const acceptedExtensions = ['pdf']

type CompanyContractFormData = {
  name: string
  signedAt: Date
  document?: File | string
}

type CompanyContractFormProps = {
  onClose: () => void
  contract?: CompanyContract
  companyId?: string
  isDeletable?: boolean
}

export const CompanyContractForm = ({
  onClose,
  contract,
  companyId,
  isDeletable,
}: CompanyContractFormProps) => {
  const isMatchSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))

  const [createCompanyContract, { loading: createCompanyContractLoading }] =
    useCreateCompanyContractMutation()
  const [updateCompanyContract, { loading: updateCompanyContractLoading }] =
    useUpdateCompanyContractMutation()
  const [deleteCompanyContract, { loading: deleteCompanyContractLoading }] =
    useDeleteCompanyContractMutation()

  const isSubmittingCreateOrUpdate =
    createCompanyContractLoading || updateCompanyContractLoading

  const methods = useForm<CompanyContractFormData>({
    defaultValues: contract
      ? {
          name: contract.name,
          signedAt: new Date(contract.signedAt),
          document: contract.document,
        }
      : {
          name: '',
          signedAt: new Date(),
          document: '',
        },
    mode: 'onBlur',
  })

  const onSubmit = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault()

      await methods.handleSubmit(
        async ({ document, signedAt, ...formData }) => {
          const data = {
            ...formData,
            signedAt: format(signedAt, 'yyyy-MM-dd'),
            ...(typeof document !== 'string' && { document }),
          }

          if (contract) {
            await updateCompanyContract({
              variables: { input: { id: contract.id, ...data } },
            })
          } else if (companyId) {
            await createCompanyContract({
              variables: { input: { companyId, ...data } },
            })
          }

          onClose()
        },
      )()
    },
    [
      methods,
      contract,
      onClose,
      createCompanyContract,
      updateCompanyContract,
      companyId,
    ],
  )

  const handleDelete = useCallback(() => {
    if (contract) {
      deleteCompanyContract({ variables: { id: contract.id } })
    }
  }, [contract, deleteCompanyContract])

  return (
    <FormProvider {...methods}>
      <Grid
        container
        spacing={2}
        noValidate
        autoComplete="off"
        onSubmit={onSubmit}
        component="form"
      >
        <Grid item xs={12} sm={6}>
          <Field
            name="name"
            label="Договор"
            rules={{
              required: true,
              maxLength: {
                value: 255,
                message: 'Не более 255 символов',
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateField
            name="signedAt"
            label="Дата подписания"
            rules={{ required: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <UploadField
            name="document"
            label="Загрузить документ"
            labelActive="Приложите документ"
            accept={acceptedExtensions}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            spacing={isMatchSm ? 2 : 0}
            wrap="nowrap"
          >
            {contract && isDeletable && (
              <Grid item>
                <ButtonContainerWithSpinner
                  isLoading={deleteCompanyContractLoading}
                >
                  <Button
                    variant="text"
                    color="primary"
                    onClick={handleDelete}
                    disabled={deleteCompanyContractLoading}
                  >
                    Удалить
                  </Button>
                </ButtonContainerWithSpinner>
              </Grid>
            )}
            <Grid item>
              <Button variant="text" color="primary" onClick={onClose}>
                Отменить
              </Button>
            </Grid>
            <Grid item>
              <ButtonContainerWithSpinner
                isLoading={isSubmittingCreateOrUpdate}
              >
                <Button
                  variant="text"
                  color="primary"
                  type="submit"
                  disabled={isSubmittingCreateOrUpdate}
                >
                  Сохранить
                </Button>
              </ButtonContainerWithSpinner>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}
