import type { MutableRefObject, LegacyRef, RefCallback } from 'react'

// This function has been taken from `react-merge-refs@2.0.0` npm library
// https://github.com/gregberge/react-merge-refs/blob/main/src/index.tsx

export function mergeRefs<T = any>(
  refs: Array<MutableRefObject<T> | LegacyRef<T>>,
): RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value)
      } else if (ref != null) {
        ;(ref as MutableRefObject<T | null>).current = value
      }
    })
  }
}
