import { gql, useMutation } from '@apollo/client'

import { PERFORMER, INPUT_ERROR } from 'api'
import { Performer, InputError } from 'types'

export const CREATE_PERFORMER_COMMENT = gql`
  mutation CreatePerformerComment($input: CreatePerformerCommentInput!) {
    createPerformerComment(input: $input) {
      performer {
        ...Performer
      }
      errors {
        ...InputError
      }
    }
  }
  ${PERFORMER}
  ${INPUT_ERROR}
`

type CreatePerformerCommentDataSuccess = {
  performer: Performer
  errors: null
}

type CreatePerformerCommentDataInputError = {
  performer: null
  errors: InputError<'content'>[]
}

export type CreatePerformerCommentData = {
  createPerformerComment:
    | CreatePerformerCommentDataSuccess
    | CreatePerformerCommentDataInputError
}

export type CreatePerformerCommentVariables = {
  input: {
    performerId: string
    content: string
  }
}

export const useCreatePerformerCommentMutation = () => {
  return useMutation<
    CreatePerformerCommentData,
    CreatePerformerCommentVariables
  >(CREATE_PERFORMER_COMMENT)
}
