import { useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

type LocationState =
  | {
      backButtonTitle?: string
    }
  | undefined

export function useBackClick() {
  const navigate = useNavigate()
  const location = useLocation()
  const backTitle =
    (location.state as LocationState)?.backButtonTitle || 'Назад'

  const handleBackClick = useCallback(() => {
    navigate(-1)
  }, [navigate])

  return { backTitle, handleBackClick }
}
