import { Grid, Typography, Link, IconButton } from '@mui/material'
import { Edit as EditIcon } from '@mui/icons-material'

import { CompanyContract as TCompanyContract } from 'types'

type CompanyContractProps = {
  contract: TCompanyContract
  onEdit?: () => void
}

export const CompanyContract = ({ contract, onEdit }: CompanyContractProps) => {
  return (
    <Grid container spacing={1} alignItems="center" wrap="nowrap">
      <Grid item flexGrow={1}>
        <Grid container spacing={1} direction="column">
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Typography variant="body2">{contract.name}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">от</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{contract.signedAt}</Typography>
              </Grid>
            </Grid>
          </Grid>
          {contract.document && (
            <Grid item>
              <Link variant="body2" href={contract.document}>
                Скачать документ
              </Link>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <IconButton aria-label="Редактировать" onClick={onEdit} size="large">
          <EditIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}
