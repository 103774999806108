import {
  useState,
  useEffect,
  useCallback,
  useRef,
  ChangeEvent,
  ReactNode,
} from 'react'
import { useFormContext, get, RegisterOptions } from 'react-hook-form'
import { styled } from '@mui/material/styles'
import { Avatar, Grid, IconButton, FormHelperText } from '@mui/material'
import {
  Delete as DeleteIcon,
  PhotoCamera as PhotoCameraIcon,
} from '@mui/icons-material'

const StyledInput = styled('input')`
  display: none;
`

export type AvatarFieldProps = {
  name: string
  rules?: RegisterOptions
  maxFileSize?: number
  children?: ReactNode
}

export const AvatarField = ({
  name,
  rules,
  maxFileSize = 5 * 1024 * 1024,
  children,
}: AvatarFieldProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const {
    control,
    register,
    setError,
    clearErrors,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext()

  const defaultValue = getValues()[name] || control._defaultValues[name]

  const defaultAvatarUrl =
    defaultValue instanceof File
      ? URL.createObjectURL(defaultValue)
      : defaultValue

  const [avatarUrl, setAvatarUrl] = useState<string>(defaultAvatarUrl)

  useEffect(() => {
    register(name, rules)
  }, [name, register, rules])

  const error = get(errors, name)

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target

      if (files && files.length > 0) {
        const file = files[0]
        if (error) clearErrors(name)
        if (file.size > maxFileSize) {
          setError(name, {
            type: 'maxFileSize',
            message: `Нельзя загрузить файл больше ${
              maxFileSize / 1024 / 1024
            } Мб`,
          })
        } else {
          setValue(name, file)
          const dataUrl = URL.createObjectURL(file)
          setAvatarUrl(dataUrl)
        }
      }
    },
    [name, maxFileSize, error, setValue, setAvatarUrl, clearErrors, setError],
  )

  useEffect(() => {
    // Free memory on unmount
    return () => {
      URL.revokeObjectURL(avatarUrl)
    }
  }, [avatarUrl])

  const onDelete = useCallback(() => {
    const nativeInput = inputRef.current
    if (nativeInput !== null) nativeInput.value = ''
    if (error) clearErrors(name)
    setValue(name, null)
    setAvatarUrl('')
  }, [name, error, clearErrors, setValue, setAvatarUrl])

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      sx={{
        position: 'relative',
        py: 4,
      }}
    >
      <StyledInput
        accept="image/*"
        id={name}
        name={name}
        type="file"
        onChange={handleChange}
        ref={inputRef}
        data-testid="upload input"
      />
      <label htmlFor={name} aria-label="Загрузить">
        <Avatar
          src={avatarUrl}
          sx={{ width: 120, height: 120 }}
          data-testid="preview"
        >
          {children}
        </Avatar>
      </label>
      {avatarUrl ? (
        <IconButton
          aria-label="Удалить фото"
          component="span"
          sx={{ position: 'absolute', bottom: 8 }}
          onClick={onDelete}
          size="large"
        >
          <DeleteIcon fontSize="large" />
        </IconButton>
      ) : (
        <IconButton
          color="primary"
          aria-label="Загрузить фото"
          component="label"
          sx={{ position: 'absolute', bottom: 8 }}
          htmlFor={name}
          size="large"
        >
          <PhotoCameraIcon fontSize="large" />
        </IconButton>
      )}
      {typeof error?.message === 'string' && (
        <FormHelperText error sx={{ position: 'absolute', bottom: 0 }}>
          {error.message}
        </FormHelperText>
      )}
    </Grid>
  )
}
