import { useCallback, SyntheticEvent } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Button, Grid } from '@mui/material'

import { useUpdateOrderMutation } from 'api'
import {
  ButtonContainerWithSpinner,
  CustomerField,
  CompanyField,
  DateTimeField,
  OrderStatusSelectField,
} from 'components'
import { Order as TOrder } from 'types'
import { setFormErrors } from 'utils'

const errorsMessages = {
  startAt: {
    key: 'startAt' as const,
    default: 'Нельзя создать заказ с такой датой начала',
  },
  endAt: {
    key: 'endAt' as const,
    default: 'Нельзя создать заказ с такой датой окончания',
  },
}

type OrderFormData = {
  startAt: Date
  endAt: Date
  statusId: string
  customer: { id: string }
  company?: { id: string } | null
}

type OrderFormProps = {
  order: TOrder
  onSubmit?: () => void
  onCancel?: () => void
}

export const OrderForm = ({
  order,
  onSubmit: onSubmitAction,
  onCancel,
}: OrderFormProps) => {
  const [updateOrder] = useUpdateOrderMutation()

  const methods = useForm<OrderFormData>({
    defaultValues: {
      statusId: order.status.id,
      startAt: new Date(order.startAt),
      endAt: new Date(order.endAt),
      customer: order.customer,
      company: order.company,
    },
    mode: 'onBlur',
  })

  const onSubmit = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault()

      methods.handleSubmit(async (formData) => {
        const { statusId, startAt, endAt, customer, company } = formData

        const { data } = await updateOrder({
          variables: {
            input: {
              id: order.id,
              statusId,
              startAt: startAt.toISOString(),
              endAt: endAt.toISOString(),
              customerId: customer.id,
              companyId: company?.id || null,
            },
          },
        })

        if (data?.updateOrder?.errors) {
          setFormErrors<Pick<OrderFormData, 'startAt' | 'endAt'>>(
            methods.setError,
            data.updateOrder.errors,
            errorsMessages,
          )
        }

        if (data?.updateOrder?.order) {
          if (onSubmitAction) onSubmitAction()
          if (onCancel) onCancel()
        }
      })()
    },
    [methods, updateOrder, onSubmitAction, onCancel, order.id],
  )

  return (
    <FormProvider {...methods}>
      <Grid
        container
        spacing={2}
        noValidate
        autoComplete="off"
        onSubmit={onSubmit}
        component="form"
      >
        <Grid item xs={12} sm={6}>
          <DateTimeField
            name="startAt"
            label="Время запуска"
            rules={{
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateTimeField
            name="endAt"
            label="Время готовности"
            rules={{
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <OrderStatusSelectField
            name="statusId"
            label="Статус"
            rules={{
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CompanyField name="company" label="Компания" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomerField
            name="customer"
            label="Заказчик"
            rules={{
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={1}>
            {onCancel && (
              <Grid item>
                <Button variant="text" color="primary" onClick={onCancel}>
                  Отмена
                </Button>
              </Grid>
            )}
            <Grid item>
              <ButtonContainerWithSpinner
                isLoading={methods.formState.isSubmitting}
              >
                <Button
                  variant="text"
                  color="primary"
                  type="submit"
                  disabled={methods.formState.isSubmitting}
                >
                  Сохранить
                </Button>
              </ButtonContainerWithSpinner>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}
