import { useEffect } from 'react'
import { gql, useApolloClient } from '@apollo/client'

import { COMPANY } from 'api'
import { Company } from 'types'

type CompanyUpdatedData = {
  company: Company
}

type CompanyUpdatedVariables = {
  companyId: string
}

export const COMPANY_UPDATED = gql`
  subscription($companyId: ID!) {
    companyUpdated(companyId: $companyId) {
      ...Company
    }
  }
  ${COMPANY}
`

export const useCompanyUpdatedSubscription = (companyId?: string) => {
  const client = useApolloClient()

  useEffect(() => {
    if (companyId) {
      const updatedCompanySubscription = client
        .subscribe<CompanyUpdatedData, CompanyUpdatedVariables>({
          query: COMPANY_UPDATED,
          variables: { companyId },
        })
        .subscribe(() => {})

      return () => {
        updatedCompanySubscription.unsubscribe()
      }
    }
  })
}
