import { MultipleEditableRoot, MultipleEditableItem } from 'components'
import { useMultipleForms } from 'utils'
import { Customer } from 'types'

import { CustomerCommentForm } from './CustomerCommentForm'
import { EditableCustomerComment } from './EditableCustomerComment'

type EditableCustomerCommentsProps = {
  customer: Customer
}

export const EditableCustomerComments = ({
  customer,
}: EditableCustomerCommentsProps) => {
  const { forms, addForm, removeForm } = useMultipleForms()

  return (
    <MultipleEditableRoot
      onAddForm={addForm}
      buttonTitle="Оставить комментарий"
    >
      {customer.comments.map((comment) => (
        <MultipleEditableItem key={comment.id}>
          <EditableCustomerComment comment={comment} />
        </MultipleEditableItem>
      ))}
      {forms.map((itemKey) => (
        <MultipleEditableItem key={itemKey}>
          <CustomerCommentForm
            customerId={customer.id}
            onClose={removeForm(itemKey)}
          />
        </MultipleEditableItem>
      ))}
    </MultipleEditableRoot>
  )
}
