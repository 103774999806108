import { useCallback, SyntheticEvent } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Grid, Button, useMediaQuery, Theme } from '@mui/material'

import {
  useCreateExperienceMutation,
  useUpdateExperienceMutation,
  useDeleteExperienceMutation,
} from 'api'
import {
  ButtonContainerWithSpinner,
  Field,
  CountrySelectField,
  YearField,
} from 'components'
import { Performer, Experience } from 'types'

type PerformerExperienceFormData = {
  organizationName: string
  position: string
  countryId: string
  startYear: string
  endYear: string
  achievements?: string
}

type PerformerExperienceFormProps = {
  performer: Performer
  experience?: Experience
  onClose: () => void
  isDeletable?: boolean
}

export const PerformerExperienceForm = ({
  experience,
  onClose,
  isDeletable,
  performer,
}: PerformerExperienceFormProps) => {
  const isMatchSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))

  const [createExperience, { loading: createExperienceLoading }] =
    useCreateExperienceMutation()
  const [updateExperience, { loading: updateExperienceLoading }] =
    useUpdateExperienceMutation()
  const [deleteExperience, { loading: deleteExperienceLoading }] =
    useDeleteExperienceMutation()

  const isSubmittingCreateOrUpdate =
    createExperienceLoading || updateExperienceLoading

  const methods = useForm<PerformerExperienceFormData>({
    defaultValues: experience
      ? {
          organizationName: experience.organizationName,
          position: experience.position,
          countryId: experience.country.id,
          startYear: String(experience.startYear),
          endYear:
            experience.endYear !== null ? String(experience.endYear) : '',
          achievements: experience.achievements,
        }
      : {
          organizationName: '',
          position: '',
          countryId: '',
          startYear: '',
          endYear: '',
          achievements: '',
        },
    mode: 'onBlur',
  })

  const onSubmit = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault()

      await methods.handleSubmit(
        async ({ startYear, endYear, ...formData }) => {
          const data = {
            ...formData,
            startYear: Number.parseInt(startYear),
            endYear: endYear ? Number.parseInt(endYear) : null,
          }

          if (experience) {
            await updateExperience({
              variables: { input: { id: experience.id, ...data } },
            })
          } else if (performer) {
            await createExperience({
              variables: { input: { performerId: performer.id, ...data } },
            })
          }

          onClose()
        },
      )()
    },
    [
      methods,
      experience,
      onClose,
      createExperience,
      updateExperience,
      performer,
    ],
  )

  const handleDelete = useCallback(() => {
    if (experience) {
      deleteExperience({ variables: { id: experience.id } })
    }
  }, [experience, deleteExperience])

  return (
    <FormProvider {...methods}>
      <Grid
        container
        spacing={2}
        noValidate
        autoComplete="off"
        onSubmit={onSubmit}
        component="form"
      >
        <Grid item xs={12}>
          <Field
            name="organizationName"
            label="Название организации"
            rules={{
              required: true,
              maxLength: {
                value: 255,
                message: 'Не более 255 символов',
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="position"
            label="Позиция"
            rules={{
              required: true,
              maxLength: {
                value: 255,
                message: 'Не более 255 символов',
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CountrySelectField
            name="countryId"
            rules={{ required: true }}
            label="Страна"
            hasEmptyOption
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <YearField
            name="startYear"
            label="Год начала"
            placeholder="2000"
            rules={{
              min: { value: 1900, message: 'Не может быть меньше 1900' },
              max: {
                value: new Date().getFullYear(),
                message: 'Не может быть позднее текущего',
              },
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <YearField
            name="endYear"
            label="Год окончания"
            placeholder="2000"
            rules={{
              min: { value: 1900, message: 'Не может быть меньше 1900' },
              max: {
                value: new Date().getFullYear(),
                message: 'Не может быть позднее текущего',
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="achievements"
            label="Немного про задачи"
            multiline
            minRows={3}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            spacing={isMatchSm ? 2 : 0}
            wrap="nowrap"
          >
            {experience && isDeletable && (
              <Grid item>
                <ButtonContainerWithSpinner isLoading={deleteExperienceLoading}>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={handleDelete}
                    disabled={deleteExperienceLoading}
                  >
                    Удалить
                  </Button>
                </ButtonContainerWithSpinner>
              </Grid>
            )}
            <Grid item>
              <Button variant="text" color="primary" onClick={onClose}>
                Отменить
              </Button>
            </Grid>
            <Grid item>
              <ButtonContainerWithSpinner
                isLoading={isSubmittingCreateOrUpdate}
              >
                <Button
                  variant="text"
                  color="primary"
                  type="submit"
                  disabled={isSubmittingCreateOrUpdate}
                >
                  Сохранить
                </Button>
              </ButtonContainerWithSpinner>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}
