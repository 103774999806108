import { gql, MutationFunctionOptions, useMutation } from '@apollo/client'

import {
  CUSTOMER,
  GET_PAGINATED_CUSTOMERS,
  GET_CURRENT_USER,
  INPUT_ERROR,
  CurrentUserData,
  useCurrentUserQuery,
} from 'api'
import { Customer, InputError } from 'types'

export const CREATE_CUSTOMER = gql`
  mutation CreateCustomer($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      customer {
        ...Customer
      }
      errors {
        ...InputError
      }
    }
  }
  ${CUSTOMER}
  ${INPUT_ERROR}
`

type CreateCustomerDataSuccess = {
  customer: Customer
  errors: null
}

type CreateCustomerDataInputError = {
  customer: null
  errors: InputError<'profile.email' | 'profile.phone'>[]
}

export type CreateCustomerData = {
  createCustomer: CreateCustomerDataSuccess | CreateCustomerDataInputError
}

export type CreateCustomerVariables = {
  input: {
    profile:
      | {
          id: string
          birthday?: string
          avatar?: File | null
        }
      | {
          firstName: string
          lastName: string
          email: string
          phone: string
          birthday?: string
          avatar?: File | null
        }
  }
}

export const useCreateCustomerMutation = () => {
  const [createCustomer, fetchResult] = useMutation<
    CreateCustomerData,
    CreateCustomerVariables
  >(CREATE_CUSTOMER, {
    refetchQueries: [GET_PAGINATED_CUSTOMERS],
  })

  const { data, client } = useCurrentUserQuery()
  const currentUser = data!.currentUser!

  const sendCreateCustomer = async (
    mutationOptions: MutationFunctionOptions<
      CreateCustomerData,
      CreateCustomerVariables
    >,
  ) => {
    const result = await createCustomer(mutationOptions)

    if (result.data?.createCustomer.customer?.profile.id === currentUser.id) {
      await client.query<CurrentUserData>({
        query: GET_CURRENT_USER,
        fetchPolicy: 'network-only',
      })
    }

    return result
  }

  return [sendCreateCustomer, fetchResult] as const
}
