import type {
  Performer as TPerformer,
  PerformerService as TPerformerService,
} from 'types'
import { useEditable } from 'utils'

import { PerformerService } from './PerformerService'
import { PerformerServiceForm } from './PerformerServiceForm'

type EditablePerformerServiceProps = {
  service: TPerformerService
  performer: TPerformer
  isDeletable: boolean
}

export const EditablePerformerService = ({
  service,
  performer,
  isDeletable,
}: EditablePerformerServiceProps) => {
  const { isEditing, handleEdit, handleClose } = useEditable()

  if (isEditing) {
    return (
      <PerformerServiceForm
        service={service}
        performer={performer}
        onClose={handleClose}
        isDeletable={isDeletable}
      />
    )
  }

  return <PerformerService service={service} onEdit={handleEdit} />
}
