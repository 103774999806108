import { QueryHookOptions, gql, useQuery } from '@apollo/client'

import { SERVICE } from 'api'
import { Service } from 'types'

export type ServiceData = {
  service: Service
}

export type ServiceVariables = {
  id: string
}

export const GET_SERVICE = gql`
  query GetService($id: ID!) {
    service(id: $id) {
      ...Service
    }
  }
  ${SERVICE}
`

export const defaultServiceQueryOptions: QueryHookOptions<
  ServiceData,
  ServiceVariables
> = {
  errorPolicy: 'all',
}

export function useServiceQuery(
  serviceId: string,
  serviceQueryOptions?: Omit<
    QueryHookOptions<ServiceData, ServiceVariables>,
    'variables'
  >,
) {
  return useQuery<ServiceData, ServiceVariables>(GET_SERVICE, {
    ...defaultServiceQueryOptions,
    ...serviceQueryOptions,
    variables: {
      id: serviceId,
    },
  })
}
