import { gql } from '@apollo/client'

import { Session } from 'types'

export const RENEW_TOKEN = gql`
  mutation RenewToken($input: RenewTokenInput!) {
    renewToken(input: $input) {
      session {
        accessToken
        renewalToken
      }
    }
  }
`

export type RenewTokenData = {
  renewToken: {
    session: Session
  }
}

export type RenewTokenVariables = {
  input: {
    renewalToken: string
  }
}
