import type { Order as TOrder, OrderService as TOrderService } from 'types'
import { useEditable } from 'utils'

import { OrderService } from './OrderService'
import { OrderServiceForm } from './OrderServiceForm'

type EditableOrderServiceProps = {
  service: TOrderService
  order: TOrder
  isDeletable: boolean
}

export const EditableOrderService = ({
  service,
  order,
  isDeletable,
}: EditableOrderServiceProps) => {
  const { isEditing, handleEdit, handleClose } = useEditable()

  if (isEditing) {
    return (
      <OrderServiceForm
        service={service}
        order={order}
        onClose={handleClose}
        isDeletable={isDeletable}
      />
    )
  }

  return <OrderService service={service} onEdit={handleEdit} />
}
