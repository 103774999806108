import { useEditable } from 'utils'

import { CompanyEmployees } from './CompanyEmployees'
import { CompanyEmployeesForm } from './CompanyEmployeesForm'
import { Company } from 'types'

type EditableCompanyEmployeesProps = {
  company: Company
}

export const EditableCompanyEmployees = ({
  company,
}: EditableCompanyEmployeesProps) => {
  const { isEditing, handleEdit, handleClose } = useEditable()

  if (isEditing) {
    return <CompanyEmployeesForm company={company} onClose={handleClose} />
  }

  return <CompanyEmployees company={company} onEdit={handleEdit} />
}
