import { useMemo } from 'react'
import { Skeleton } from '@mui/material'

import { SelectField, SelectFieldProps } from 'components'
import { usePerformerServiceGroupsQuery } from 'api'
import { formatReferenceEntityToSelectOption } from 'utils'

type PerformerServiceGroupSelectFieldProps = Omit<SelectFieldProps, 'options'>

export const PerformerServiceGroupSelectField = (
  props: PerformerServiceGroupSelectFieldProps,
) => {
  const { data, loading } = usePerformerServiceGroupsQuery()

  const options = useMemo(
    () =>
      formatReferenceEntityToSelectOption(data?.performerServiceGroups || []),
    [data],
  )

  return loading ? (
    <Skeleton height={56} />
  ) : (
    <SelectField options={options} {...props} />
  )
}
