import { ReactNode } from 'react'
import { styled } from '@mui/material/styles'
import { SnackbarProvider, SnackbarProviderProps } from 'notistack'

const StyledSnackbarProvider = styled(
  ({ className, children, ...props }: SnackbarProviderProps) => (
    <SnackbarProvider classes={{ containerRoot: className }} {...props}>
      {children}
    </SnackbarProvider>
  ),
)(
  ({ theme }) => `
    margin-top: ${theme.spacing(9)};
    ${theme.breakpoints.down('xl')}: {
      margin-top: ${theme.spacing(6)};
    },
`,
)

type CustomSnackbarProviderProps = {
  children: ReactNode
}

export const CustomSnackbarProvider = ({
  children,
}: CustomSnackbarProviderProps) => {
  return (
    <StyledSnackbarProvider
      maxSnack={3}
      autoHideDuration={4000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      hideIconVariant
    >
      {children}
    </StyledSnackbarProvider>
  )
}
