import { gql } from '@apollo/client'

export const COUNTRY = gql`
  fragment Country on Country {
    id
    name
  }
`

export const LANGUAGE = gql`
  fragment Language on Language {
    id
    name
  }
`

export const TOPIC = gql`
  fragment Topic on Topic {
    id
    name
  }
`

export const SOFTWARE = gql`
  fragment Software on Software {
    id
    name
  }
`

export const GENDER = gql`
  fragment Gender on Gender {
    id
    name
  }
`

export const CURRENCY = gql`
  fragment Currency on Currency {
    id
    name
    symbol
  }
`

export const RATE = gql`
  fragment Rate on Rate {
    id
    name
    forOneName
    severalName
    multipleName
  }
`
