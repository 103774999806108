import { Routes, Route } from 'react-router-dom'

import { AuthLayout, RequireAuth } from 'components'
import { SignUp } from './SignUp'
import { SignIn } from './SignIn'
import { SignOut } from './SignOut'
import { PasswordRecovery } from './PasswordRecovery'
import { ResetPassword } from './ResetPassword'
import { ConfirmEmail } from './ConfirmEmail'

export const Auth = () => {
  return (
    <Routes>
      <Route
        path="sign-up"
        element={
          <RequireAuth isUnauthenticated>
            <AuthLayout>
              <SignUp />
            </AuthLayout>
          </RequireAuth>
        }
      />
      <Route
        path="sign-in"
        element={
          <RequireAuth isUnauthenticated>
            <AuthLayout>
              <SignIn />
            </AuthLayout>
          </RequireAuth>
        }
      />
      <Route
        path="password-recovery"
        element={
          <RequireAuth isUnauthenticated>
            <AuthLayout>
              <PasswordRecovery />
            </AuthLayout>
          </RequireAuth>
        }
      />
      <Route
        path="password-recovery"
        element={
          <RequireAuth isUnauthenticated>
            <AuthLayout>
              <ResetPassword />
            </AuthLayout>
          </RequireAuth>
        }
      />
      <Route
        path="sign-out"
        element={
          <RequireAuth>
            <SignOut />
          </RequireAuth>
        }
      />
      <Route path="confirm-email/:token" element={<ConfirmEmail />} />
    </Routes>
  )
}
