import { Routes, Route } from 'react-router-dom'

import { Layout, RequireAuth } from 'components'
import { PerformersList } from './PerformersList'
import { Performer } from './Performer'
import { CreatePerformer } from './CreatePerformer'
import { PerformerOnboarding } from './PerformerOnboarding'
import { CurrentPerformer } from './CurrentPerformer'

export const Performers = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequireAuth roles={['employee', 'admin']}>
            <Layout>
              <PerformersList />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path="create/*"
        element={
          <RequireAuth roles={['employee', 'admin']}>
            <Layout>
              <CreatePerformer />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path="onboarding/*"
        element={
          <RequireAuth
            hasAccess={(currentUser) =>
              currentUser.performer === null ||
              !currentUser.performer.isOnboardingFinished
            }
          >
            <Layout>
              <PerformerOnboarding />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path="me/*"
        element={
          <RequireAuth
            hasAccess={(currentUser) =>
              currentUser.performer !== null &&
              currentUser.performer.isOnboardingFinished
            }
          >
            <Layout maxWidth="md">
              <CurrentPerformer />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path=":id/*"
        element={
          <RequireAuth roles={['employee', 'admin']}>
            <Layout maxWidth="md">
              <Performer />
            </Layout>
          </RequireAuth>
        }
      />
    </Routes>
  )
}
