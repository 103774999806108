import { KeyboardEvent, MouseEvent } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { MenuItem, MenuList } from '@mui/material'

type SettingsItem = {
  path: string
  title: string
}

type SettingsItemProps = {
  settingsItems: SettingsItem[]
  autoFocusItem: boolean
  onKeyDown: (e: KeyboardEvent) => void
  onSettingsItemClick: (e: MouseEvent) => void
}

export const SettingsItems = ({
  settingsItems,
  autoFocusItem,
  onKeyDown,
  onSettingsItemClick,
}: SettingsItemProps) => {
  const location = useLocation()
  return (
    <MenuList
      autoFocusItem={autoFocusItem}
      id="menu-list-grow"
      onKeyDown={onKeyDown}
    >
      {settingsItems.map(({ path, title }) => (
        <MenuItem
          component={RouterLink}
          to={path}
          onClick={onSettingsItemClick}
          selected={location.pathname === path}
          key={path}
        >
          {title}
        </MenuItem>
      ))}
    </MenuList>
  )
}
