import { useMemo } from 'react'

import { AutocompleteField, AutocompleteFieldProps } from 'components'
import { useSoftwareQuery } from 'api'
import type { Software } from 'types'

export type SoftwareFieldProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> = Omit<
  AutocompleteFieldProps<Software, Multiple, DisableClearable, FreeSolo>,
  'loading' | 'options' | 'loading'
>

const getSoftwareLabel = ({ name }: Software) => name

const isOptionEqualToValue = (option: Software, value: Software) => {
  return option.id === value.id
}

export function SoftwareField<Multiple extends boolean | undefined = undefined>(
  props: SoftwareFieldProps<Multiple, false, false>,
) {
  const { data, loading } = useSoftwareQuery()

  const options = useMemo(() => data?.software || [], [data])

  return (
    <AutocompleteField<Software, Multiple, false, false>
      loading={loading}
      options={options}
      getOptionLabel={getSoftwareLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      {...props}
    />
  )
}
