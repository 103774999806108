import { useCallback, SyntheticEvent } from 'react'
import { FormProvider, useForm, useFieldArray } from 'react-hook-form'
import {
  Grid,
  Button,
  Box,
  Divider,
  IconButton,
  Hidden,
  Stack,
} from '@mui/material'
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material'
import invariant from 'tiny-invariant'

import { useCreateServiceMutation } from 'api'
import {
  ButtonContainerWithSpinner,
  CurrencySelectField,
  Field,
  LanguageSelectField,
  RateSelectField,
} from 'components'
import { setFormErrors, getCents } from 'utils'
import { Service } from 'types'

const errorsMessages = {
  name: {
    key: 'name' as const,
    default: 'Нельзя создать услугу с таким названием',
  },
  'languageServices.*.sourceLanguageId': {
    messages: {
      "can't be used with this target language": 'Такая языковая пара уже есть',
    },
    default: 'Нельзя сохранить с таким языком',
  },
}

type CreateServiceFormData = {
  name: string
  centsPerUnit: string
  rateId: string
  currencyId: string
  languageServices: {
    centsPerUnit: string
    currencyId: string
    sourceLanguageId: string
    targetLanguageId: string
  }[]
}

type CreateServiceFormProps = {
  onSubmit?: (service: Service) => void
}

export const CreateServiceForm = ({
  onSubmit: onSubmitAction,
}: CreateServiceFormProps) => {
  const [createService] = useCreateServiceMutation()

  const methods = useForm<CreateServiceFormData>({
    defaultValues: {
      name: '',
      centsPerUnit: '',
      rateId: '',
      currencyId: '',
      languageServices: [],
    },
    mode: 'onBlur',
  })

  const { fields, insert, remove } = useFieldArray({
    name: 'languageServices',
    keyName: 'uid',
    control: methods.control,
  })

  const languageServices = methods.watch('languageServices')

  const onSubmit = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault()

      methods.handleSubmit(async (formData) => {
        const { name, centsPerUnit, rateId, currencyId, languageServices } =
          formData

        invariant(rateId, 'rate must be chosen')
        invariant(currencyId, 'currency must be chosen')

        const { data } = await createService({
          variables: {
            input: {
              name,
              rateId,
              ...(!languageServices.length && {
                centsPerUnit: getCents(centsPerUnit),
              }),
              ...(!languageServices.length && { currencyId }),
              languageServices: languageServices.length
                ? languageServices.map((ls) => ({
                    centsPerUnit: getCents(ls.centsPerUnit),
                    currencyId: ls.currencyId,
                    sourceLanguageId: ls.sourceLanguageId,
                    targetLanguageId: ls.targetLanguageId,
                  }))
                : [],
            },
          },
        })

        if (data?.createService?.errors) {
          setFormErrors<Pick<CreateServiceFormData, 'name'>>(
            methods.setError,
            data.createService.errors,
            errorsMessages,
          )
        }

        if (data?.createService?.service) {
          if (onSubmitAction) onSubmitAction(data.createService.service)
        }
      })()
    },
    [methods, createService, onSubmitAction],
  )

  const addLanguageService = useCallback(
    (index: number) => () => {
      insert(index, {
        centsPerUnit: '',
        sourceLanguageId: '',
        targetLanguageId: '',
        currencyId: '',
      })
    },
    [insert],
  )

  const removeLanguageService = useCallback(
    (index: number) => () => {
      remove(index)
    },
    [remove],
  )

  return (
    <FormProvider {...methods}>
      <Grid
        container
        spacing={2}
        noValidate
        autoComplete="off"
        onSubmit={onSubmit}
        component="form"
      >
        <Grid item xs={12}>
          <Field
            name="name"
            label="Название"
            rules={{
              required: true,
              maxLength: {
                value: 255,
                message: 'Не более 255 символов',
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RateSelectField
            name="rateId"
            rules={{ required: true }}
            hasEmptyOption
            label="Тип тарифа"
          />
        </Grid>
        {fields.length ? (
          <Grid item xs={12}>
            <Divider flexItem light sx={{ mb: 4 }} />
            <Stack
              direction="column"
              spacing={2}
              divider={<Divider flexItem light style={{ marginBottom: 16 }} />}
            >
              {fields.map((field, index) => (
                <Stack
                  direction="row"
                  key={field.uid}
                  spacing={2}
                  flexGrow={1}
                  alignItems="flex-start"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <LanguageSelectField
                        name={`languageServices.${index}.sourceLanguageId`}
                        rules={{ required: true }}
                        label="Язык оригинала"
                        filterOptions={({ value }) =>
                          value !== languageServices[index].targetLanguageId
                        }
                        hasEmptyOption
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <LanguageSelectField
                        name={`languageServices.${index}.targetLanguageId`}
                        rules={{ required: true }}
                        label="Язык перевода"
                        filterOptions={({ value }) =>
                          value !== languageServices[index].sourceLanguageId
                        }
                        hasEmptyOption
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Stack
                        direction="row"
                        spacing={1}
                        flexGrow={1}
                        alignItems="flex-start"
                      >
                        <Grid container spacing={{ xs: 1, sm: 2 }}>
                          <Grid item xs={8} sm={7}>
                            <Field
                              name={`languageServices.${index}.centsPerUnit`}
                              rules={{ required: true }}
                              label={`Тариф`}
                            />
                          </Grid>
                          <Grid item xs={4} sm={5}>
                            <CurrencySelectField
                              name={`languageServices.${index}.currencyId`}
                              rules={{ required: true }}
                              label="Валюта"
                            />
                          </Grid>
                        </Grid>
                        <Hidden mdUp>
                          <Stack
                            direction="row"
                            spacing={0.5}
                            sx={{ height: 56 }}
                            alignItems="center"
                          >
                            <Box>
                              <IconButton
                                color="primary"
                                onClick={removeLanguageService(index)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                            <Box>
                              <IconButton
                                color="primary"
                                onClick={addLanguageService(index + 1)}
                              >
                                <AddIcon />
                              </IconButton>
                            </Box>
                          </Stack>
                        </Hidden>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Hidden mdDown>
                    <Stack
                      direction="row"
                      spacing={0.5}
                      sx={{ height: 56 }}
                      alignItems="center"
                    >
                      <Box>
                        <IconButton
                          color="primary"
                          onClick={removeLanguageService(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                      <Box>
                        <IconButton
                          color="primary"
                          onClick={addLanguageService(index + 1)}
                        >
                          <AddIcon />
                        </IconButton>
                      </Box>
                    </Stack>
                  </Hidden>
                </Stack>
              ))}
            </Stack>
          </Grid>
        ) : (
          <>
            <Grid item xs={8}>
              <Field
                name="centsPerUnit"
                rules={{ required: true }}
                label={`Тариф`}
              />
            </Grid>
            <Grid item xs={4}>
              <CurrencySelectField
                name="currencyId"
                rules={{ required: true }}
                label="Валюта"
              />
            </Grid>
          </>
        )}
        {!fields.length && (
          <Grid item xs={12} container justifyContent="center">
            <Grid item>
              <Button
                variant="text"
                color="primary"
                startIcon={<AddIcon />}
                onClick={addLanguageService(0)}
              >
                Добавить языковую услугу
              </Button>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={1}>
            <Grid item>
              <ButtonContainerWithSpinner
                isLoading={methods.formState.isSubmitting}
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={methods.formState.isSubmitting}
                >
                  Создать услугу
                </Button>
              </ButtonContainerWithSpinner>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}
