import { useCallback } from 'react'

import { usePerformerServiceTypesQuery } from 'api'
import type { Performer, PerformerService } from 'types'

export const useFilterOptions = (
  performer: Performer,
  service?: PerformerService,
) => {
  const { data: performerServiceTypesData } = usePerformerServiceTypesQuery()

  const filterTypeOptions = useCallback(
    ({ value }: { value: string }) => {
      const services = performer.services.filter(({ id }) => service?.id !== id)

      const forbiddenTypeIds = services.map((service) => service.type.id)

      return !forbiddenTypeIds.includes(value)
    },
    [performer, service],
  )

  const filterGroupOptions = useCallback(
    ({ value }: { value: string }) => {
      if (!performerServiceTypesData) return true

      const services = performer.services.filter(({ id }) => service?.id !== id)

      const usedTypeIds = services.map((service) => service.type.id) || []

      const allowedTypeIds =
        performerServiceTypesData.performerServiceTypes.filter(
          ({ id }) => !usedTypeIds.includes(id),
        )

      const allowedGroupIds = allowedTypeIds.map(({ group: { id } }) => id)

      return allowedGroupIds.includes(value)
    },
    [performer, service, performerServiceTypesData],
  )

  return { filterTypeOptions, filterGroupOptions }
}
