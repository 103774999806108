import { useEditable } from 'utils'
import { Service as TService } from 'types'

import { Service } from './Service'
import { ServiceForm } from './ServiceForm'

type EditableServiceProps = {
  service: TService
}

export const EditableService = ({ service }: EditableServiceProps) => {
  const { isEditing, handleEdit, handleClose } = useEditable()

  if (isEditing) {
    return <ServiceForm service={service} onCancel={handleClose} />
  }

  return <Service service={service} onEdit={handleEdit} />
}
