import { useCallback, FocusEvent } from 'react'
import { useFormContext, useController } from 'react-hook-form'
import ruLocale from 'date-fns/locale/ru'

import {
  DatePicker,
  DatePickerProps,
  LocalizationProvider,
} from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { RawField, FieldProps } from 'components'
import { mergeRefs } from 'utils'

export type DateFieldProps = Omit<
  DatePickerProps<Date, Date>,
  'onChange' | 'value' | 'renderInput'
> &
  Omit<FieldProps, 'onChange' | 'value'> & {
    onChange?: DatePickerProps<Date, Date>['onChange']
  }

const mask = '__.__.____'

export const DateField = ({
  views = ['year', 'month', 'day'],
  openTo = 'year',
  name,
  rules,
  defaultValue,
  inputRef,
  onBlur,
  onChange,
  ...props
}: DateFieldProps) => {
  const { control } = useFormContext()

  const { field } = useController({ control, name, rules, defaultValue })

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      field.onBlur()
      onBlur?.(e)
    },
    [field, onBlur],
  )

  const handleChange = useCallback(
    (value: Date | null) => {
      field.onChange(value)
      onChange?.(value)
    },
    [field, onChange],
  )

  return (
    <LocalizationProvider adapterLocale={ruLocale} dateAdapter={AdapterDateFns}>
      <DatePicker
        mask={mask}
        value={field.value}
        views={views}
        openTo={openTo}
        onChange={handleChange}
        renderInput={({ inputRef: refForPicker, ...params }) => (
          <RawField
            {...params}
            name={name}
            onBlur={handleBlur}
            inputRef={mergeRefs([
              ...(inputRef ? [inputRef] : []),
              ...(refForPicker ? [refForPicker] : []),
              field.ref,
            ])}
            inputProps={{
              ...params.inputProps,
              placeholder: '',
            }}
          />
        )}
        {...props}
      />
    </LocalizationProvider>
  )
}
