import { useCallback, ChangeEvent, FocusEvent } from 'react'
import { useRifm } from 'rifm'
import { useFormContext, useController, RegisterOptions } from 'react-hook-form'

import { RawField, RawFieldProps } from 'components'
import { mergeRefs } from 'utils'

type RawMaskedFieldProps = RawFieldProps &
  Omit<Parameters<typeof useRifm>[0], 'onChange'>

export type MaskedFieldProps = Omit<RawMaskedFieldProps, 'value'> & {
  defaultValue?: string
  rules?: RegisterOptions
}

export const MaskedField = ({
  name,
  rules,
  defaultValue,
  inputRef,
  format,
  replace,
  append,
  mask,
  accept,
  onChange,
  onBlur,
  ...props
}: MaskedFieldProps) => {
  const { control } = useFormContext()

  const { field } = useController({ name, control, rules, defaultValue })

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      field.onBlur()
      onBlur?.(e)
    },
    [field, onBlur],
  )

  const { value, onChange: onRifmChange } = useRifm({
    onChange: field.onChange,
    value: field.value,
    format,
    replace,
    append,
    mask,
    accept,
  })

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onRifmChange(e)
      onChange?.(e)
    },
    [onRifmChange, onChange],
  )

  return (
    <RawField
      name={name}
      value={value}
      onBlur={handleBlur}
      onChange={handleChange}
      inputRef={inputRef ? mergeRefs([field.ref, inputRef]) : field.ref}
      {...props}
    />
  )
}
