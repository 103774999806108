import { useCallback } from 'react'
import { QueryHookOptions, gql, useQuery } from '@apollo/client'

import { PAGE_INFO } from 'api'
import { Performer, SortOrder, PageInfo } from 'types'

const ITEMS_PER_PAGE = 30

export const cachePaginatedPerformersKeyArgs = ['search', 'filter', 'sortOrder']
export const cachePaginatedPerformersItemsMerge = (
  existing: Performer[],
  incoming: Performer[],
) => {
  return existing ? [...existing, ...incoming] : incoming
}
export type PaginatedPerformersData = {
  paginatedPerformers: {
    items: Performer[]
    pageInfo: PageInfo
  }
}

export type PaginatedPerformersVariables = {
  filter?: {
    services?: {
      groupId?: string
      typeId?: string
      sourceLanguageId?: string
      targetLanguageId?: string
    }
    statusId?: string
    genderId?: string
    countryId?: string
    nativeLanguageId?: string
    topicIds?: string[]
    softwareIds?: string[]
  }
  sortOrder?: {
    firstName?: SortOrder
    lastName?: SortOrder
  }
  search?: string
  pagination?: {
    limit?: number
    endCursor?: string
    startCursor?: string
  }
}

export const GET_PAGINATED_PERFORMERS = gql`
  query GetPaginatedPerformers(
    $filter: PerformersFilter
    $search: String
    $sortOrder: PerformersSortOrder
    $pagination: PaginationInput
  ) {
    paginatedPerformers(
      filter: $filter
      search: $search
      sortOrder: $sortOrder
      pagination: $pagination
    ) {
      items {
        id
        bio
        profile {
          id
          avatar
          firstName
          lastName
          email
          phone
          birthday
          city
          country {
            name
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PAGE_INFO}
`

export const defaultPaginatedPerformersQueryOptions: QueryHookOptions = {
  errorPolicy: 'all',
  notifyOnNetworkStatusChange: true,
}

export function usePaginatedPerformersQuery(
  paginatedPerformersQueryOptions?: QueryHookOptions<
    PaginatedPerformersData,
    PaginatedPerformersVariables
  >,
) {
  const { data, loading, error, fetchMore, ...rest } = useQuery<
    PaginatedPerformersData,
    PaginatedPerformersVariables
  >(GET_PAGINATED_PERFORMERS, {
    ...defaultPaginatedPerformersQueryOptions,
    ...paginatedPerformersQueryOptions,
    variables: {
      ...paginatedPerformersQueryOptions?.variables,
      pagination: {
        limit: ITEMS_PER_PAGE,
        ...paginatedPerformersQueryOptions?.variables?.pagination,
      },
    },
  })

  const endCursor = data?.paginatedPerformers.pageInfo.endCursor

  const patchedFetchMore = useCallback(() => {
    if (!loading && !error && endCursor) {
      fetchMore({
        variables: {
          pagination: {
            limit: ITEMS_PER_PAGE,
            ...paginatedPerformersQueryOptions?.variables?.pagination,
            endCursor,
          },
        },
      })
    }
  }, [
    fetchMore,
    endCursor,
    loading,
    error,
    paginatedPerformersQueryOptions?.variables?.pagination,
  ])

  return { data, loading, error, fetchMore: patchedFetchMore, ...rest }
}
