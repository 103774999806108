import { useCallback, useState } from 'react'

export const useMultipleForms = () => {
  const [forms, setForms] = useState<string[]>([])

  const addForm = useCallback(() => {
    setForms([...forms, `item-${new Date().getTime()}`])
  }, [forms, setForms])

  const removeForm = useCallback(
    (itemKey: string) => () => {
      setForms(forms.filter((formKey) => formKey !== itemKey))
    },
    [forms, setForms],
  )

  return { forms, addForm, removeForm }
}
