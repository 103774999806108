import { useCallback, SyntheticEvent } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Button, Grid } from '@mui/material'

import { useUpdateCompanyMutation } from 'api'
import { CustomerField, ButtonContainerWithSpinner } from 'components'
import { Company, Customer } from 'types'

type CompanyEmployeesFormData = {
  employees: Customer[]
}

type CompanyEmployeesFormProps = {
  onClose: () => void
  company: Company
  onSubmit?: () => void
}

export const CompanyEmployeesForm = ({
  company,
  onSubmit: onSubmitAction,
  onClose,
}: CompanyEmployeesFormProps) => {
  const [updateCompany, { loading: updateCompanyLoading }] =
    useUpdateCompanyMutation()

  const methods = useForm<CompanyEmployeesFormData>({
    defaultValues: {
      employees: company.employees,
    },
    mode: 'onBlur',
  })

  const onSubmit = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault()

      await methods.handleSubmit(async ({ employees }) => {
        await updateCompany({
          variables: {
            input: {
              id: company.id,
              employees: employees ? employees.map(({ id }) => id) : [],
            },
          },
        })
        if (onSubmitAction) onSubmitAction()
        onClose()
      })()
    },
    [methods, updateCompany, onSubmitAction, onClose, company.id],
  )

  return (
    <FormProvider {...methods}>
      <Grid
        container
        spacing={2}
        noValidate
        autoComplete="off"
        onSubmit={onSubmit}
        component="form"
      >
        <Grid item xs={12}>
          <CustomerField name="employees" label="Сотрудники" multiple />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={1}>
            <Grid item>
              <Button variant="text" color="primary" onClick={onClose}>
                Отмена
              </Button>
            </Grid>
            <Grid item>
              <ButtonContainerWithSpinner isLoading={updateCompanyLoading}>
                <Button
                  variant="text"
                  color="primary"
                  type="submit"
                  disabled={updateCompanyLoading}
                >
                  Сохранить
                </Button>
              </ButtonContainerWithSpinner>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}
