import { useCallback, useEffect, SyntheticEvent } from 'react'
import { FormProvider, useForm, useFieldArray } from 'react-hook-form'
import {
  Grid,
  Button,
  Box,
  Divider,
  IconButton,
  Hidden,
  useMediaQuery,
  Theme,
  Stack,
} from '@mui/material'
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material'

import {
  useRatesQuery,
  usePerformerServiceGroupsQuery,
  usePerformerServiceTypesQuery,
  useCreatePerformerServiceMutation,
  useUpdatePerformerServiceMutation,
  useDeletePerformerServiceMutation,
} from 'api'
import {
  ButtonContainerWithSpinner,
  CurrencySelectField,
  Field,
  LanguageSelectField,
  PerformerServiceGroupSelectField,
  PerformerServiceTypeSelectField,
} from 'components'
import { setFormErrors, getCents, getDollars } from 'utils'
import { Performer, PerformerService } from 'types'

import { useFilterOptions } from './hooks'

const errorsMessages = {
  'languageServices.*.sourceLanguageId': {
    messages: {
      "can't be used with this target language": 'Такая языковая пара уже есть',
    },
    default: 'Нельзя сохранить с таким языком',
  },
}

type PerformerServiceFormData = {
  groupId: string
  typeId: string
  centsPerUnit: string
  currencyId: string
  languageServices: {
    id?: string
    centsPerUnit: string
    currencyId: string
    sourceLanguageId: string
    targetLanguageId: string
  }[]
}

type PerformerServiceItemFormProps = {
  onClose: () => void
  service?: PerformerService
  isDeletable?: boolean
  performer: Performer
}

export const PerformerServiceForm = ({
  onClose,
  service,
  isDeletable,
  performer,
}: PerformerServiceItemFormProps) => {
  const isMatchSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
  const { data: ratesData } = useRatesQuery()
  const { data: performerServiceGroupsData } = usePerformerServiceGroupsQuery()
  const { data: performerServiceTypesData } = usePerformerServiceTypesQuery()

  const [createPerformerService, { loading: createPerformerServiceLoading }] =
    useCreatePerformerServiceMutation()
  const [updatePerformerService, { loading: updatePerformerServiceLoading }] =
    useUpdatePerformerServiceMutation()
  const [deletePerformerService, { loading: deletePerformerServiceLoading }] =
    useDeletePerformerServiceMutation()

  const isSubmittingCreateOrUpdate =
    createPerformerServiceLoading || updatePerformerServiceLoading

  const methods = useForm<PerformerServiceFormData>({
    defaultValues: service
      ? {
          groupId: service.type.group.id,
          centsPerUnit: service.centsPerUnit
            ? String(getDollars(service.centsPerUnit))
            : '',
          typeId: service.type.id,
          currencyId: service.currency?.id || '',
          languageServices: service.languageServices.map((ls) => ({
            id: ls.id,
            centsPerUnit: String(getDollars(ls.centsPerUnit)),
            currencyId: ls.currency.id,
            sourceLanguageId: ls.sourceLanguage.id,
            targetLanguageId: ls.targetLanguage.id,
          })) || [
            {
              centsPerUnit: '',
              currencyId: '',
              sourceLanguageId: '',
              targetLanguageId: '',
            },
          ],
        }
      : {
          groupId: '',
          centsPerUnit: '',
          typeId: '',
          currencyId: '',
          languageServices: [
            {
              centsPerUnit: '',
              currencyId: '',
              sourceLanguageId: '',
              targetLanguageId: '',
            },
          ],
        },
    mode: 'onChange',
    shouldUnregister: false,
  })

  const { fields, insert, remove } = useFieldArray({
    name: 'languageServices',
    keyName: 'uid',
    control: methods.control,
  })

  const [groupId, typeId, languageServices] = methods.watch([
    'groupId',
    'typeId',
    'languageServices',
  ])

  const group = performerServiceGroupsData?.performerServiceGroups.find(
    ({ id }) => id === groupId,
  )

  const serviceType = performerServiceTypesData?.performerServiceTypes.find(
    ({ id }) => id === typeId,
  )
  const rate = ratesData?.rates.find(({ id }) => id === serviceType?.rate.id)

  const onSubmit = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault()

      await methods.handleSubmit(
        async ({
          languageServices,
          centsPerUnit,
          groupId,
          currencyId,
          ...formData
        }) => {
          const payload = {
            ...formData,
            centsPerUnit: group?.isLanguageService
              ? null
              : getCents(centsPerUnit),
            currencyId: group?.isLanguageService ? null : currencyId,
            languageServices: group?.isLanguageService
              ? languageServices.map((ls) => ({
                  ...(ls.id && { id: ls.id }),
                  centsPerUnit: getCents(ls.centsPerUnit),
                  currencyId: ls.currencyId,
                  sourceLanguageId: ls.sourceLanguageId,
                  targetLanguageId: ls.targetLanguageId,
                }))
              : [],
          }

          if (service) {
            const { data } = await updatePerformerService({
              variables: { input: { id: service.id, ...payload } },
            })

            if (data?.updatePerformerService) {
              if (data.updatePerformerService.errors) {
                setFormErrors(
                  methods.setError,
                  data.updatePerformerService.errors,
                  errorsMessages,
                )
              } else {
                onClose()
              }
            }
          } else {
            const { data } = await createPerformerService({
              variables: { input: { performerId: performer.id, ...payload } },
            })
            if (data?.createPerformerService) {
              if (data.createPerformerService.errors) {
                setFormErrors(
                  methods.setError,
                  data.createPerformerService.errors,
                  errorsMessages,
                )
              } else {
                onClose()
              }
            }
          }
        },
      )()
    },
    [
      methods,
      service,
      onClose,
      createPerformerService,
      updatePerformerService,
      performer,
      group?.isLanguageService,
    ],
  )

  const handleDelete = useCallback(() => {
    if (service) {
      deletePerformerService({
        variables: { id: service.id },
      })
    }
  }, [service, deletePerformerService])

  const addLanguageService = useCallback(
    (index: number) => () => {
      insert(index, {
        centsPerUnit: '',
        sourceLanguageId: '',
        targetLanguageId: '',
        currencyId: '',
      })
    },
    [insert],
  )

  const removeLanguageService = useCallback(
    (index: number) => () => {
      remove(index)
    },
    [remove],
  )

  useEffect(() => {
    if (groupId !== service?.type.group.id) {
      methods.setValue('typeId', '')
      methods.setValue('centsPerUnit', '')
      methods.setValue('languageServices', [
        {
          centsPerUnit: '',
          currencyId: '',
          sourceLanguageId: '',
          targetLanguageId: '',
        },
      ])
    }
  }, [methods, groupId, service])

  const { filterGroupOptions, filterTypeOptions } = useFilterOptions(
    performer,
    service,
  )

  return (
    <FormProvider {...methods}>
      <Grid
        container
        spacing={2}
        noValidate
        autoComplete="off"
        onSubmit={onSubmit}
        component="form"
        sx={{ mt: 1 }}
      >
        <Grid item xs={12}>
          <PerformerServiceGroupSelectField
            name="groupId"
            rules={{ required: true }}
            label="Тип услуги"
            filterOptions={filterGroupOptions}
            hasEmptyOption
          />
        </Grid>
        <Grid item xs={12}>
          <PerformerServiceTypeSelectField
            name="typeId"
            rules={{ required: true }}
            label="Услуга"
            serviceGroupId={groupId}
            filterOptions={filterTypeOptions}
            disabled={!groupId}
            hasEmptyOption
          />
        </Grid>
        {group?.isLanguageService && rate && typeId ? (
          <Grid item xs={12}>
            <Divider flexItem light sx={{ mb: 4 }} />
            <Stack
              direction="column"
              spacing={2}
              divider={<Divider flexItem light style={{ marginBottom: 16 }} />}
            >
              {fields.map((field, index) => (
                <Stack
                  direction="row"
                  key={field.uid}
                  spacing={2}
                  flexGrow={1}
                  alignItems="flex-start"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <LanguageSelectField
                        name={`languageServices.${index}.sourceLanguageId`}
                        rules={{ required: true }}
                        label="Язык оригинала"
                        filterOptions={({ value }) =>
                          value !== languageServices[index].targetLanguageId
                        }
                        hasEmptyOption
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <LanguageSelectField
                        name={`languageServices.${index}.targetLanguageId`}
                        rules={{ required: true }}
                        label="Язык перевода"
                        filterOptions={({ value }) =>
                          value !== languageServices[index].sourceLanguageId
                        }
                        hasEmptyOption
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Stack
                        direction="row"
                        spacing={1}
                        flexGrow={1}
                        alignItems="flex-start"
                      >
                        <Grid container spacing={{ xs: 1, sm: 2 }}>
                          <Grid item xs={8}>
                            <Field
                              name={`languageServices.${index}.centsPerUnit`}
                              rules={{ required: true }}
                              label={`Тариф за ${rate.name}`}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <CurrencySelectField
                              name={`languageServices.${index}.currencyId`}
                              rules={{ required: true }}
                              label="Валюта"
                            />
                          </Grid>
                        </Grid>
                        <Hidden mdUp>
                          <Stack
                            direction="row"
                            spacing={0.5}
                            sx={{ height: 56 }}
                            alignItems="center"
                          >
                            <Box>
                              <IconButton
                                color="primary"
                                onClick={removeLanguageService(index)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                            <Box>
                              <IconButton
                                color="primary"
                                onClick={addLanguageService(index + 1)}
                              >
                                <AddIcon />
                              </IconButton>
                            </Box>
                          </Stack>
                        </Hidden>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Hidden mdDown>
                    <Stack
                      direction="row"
                      spacing={0.5}
                      sx={{ height: 56 }}
                      alignItems="center"
                    >
                      <Box>
                        <IconButton
                          color="primary"
                          onClick={removeLanguageService(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                      <Box>
                        <IconButton
                          color="primary"
                          onClick={addLanguageService(index + 1)}
                        >
                          <AddIcon />
                        </IconButton>
                      </Box>
                    </Stack>
                  </Hidden>
                </Stack>
              ))}
            </Stack>
          </Grid>
        ) : (
          <>
            {rate && typeId && (
              <>
                <Grid item xs={8}>
                  <Field
                    name="centsPerUnit"
                    rules={{ required: true }}
                    label={`Тариф за ${rate.forOneName}`}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CurrencySelectField
                    name="currencyId"
                    rules={{ required: true }}
                    label="Валюта"
                  />
                </Grid>
              </>
            )}
          </>
        )}
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            spacing={isMatchSm ? 2 : 0}
            wrap="nowrap"
          >
            {service && isDeletable && (
              <Grid item>
                <ButtonContainerWithSpinner
                  isLoading={deletePerformerServiceLoading}
                >
                  <Button
                    variant="text"
                    color="primary"
                    onClick={handleDelete}
                    disabled={deletePerformerServiceLoading}
                  >
                    Удалить
                  </Button>
                </ButtonContainerWithSpinner>
              </Grid>
            )}
            <Grid item>
              <Button variant="text" color="primary" onClick={onClose}>
                Отменить
              </Button>
            </Grid>
            <Grid item>
              <ButtonContainerWithSpinner
                isLoading={isSubmittingCreateOrUpdate}
              >
                <Button
                  variant="text"
                  color="primary"
                  type="submit"
                  disabled={isSubmittingCreateOrUpdate}
                >
                  Сохранить
                </Button>
              </ButtonContainerWithSpinner>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}
