import { Button, Grid } from '@mui/material'
import { ChevronLeft as ChevronLeftIcon } from '@mui/icons-material'
import { ReactNode } from 'react'

import { useBackClick } from './hooks'

type LayoutWithBackButtonProps = {
  children: ReactNode
}

export const LayoutWithBackButton = ({
  children,
}: LayoutWithBackButtonProps) => {
  const { handleBackClick, backTitle } = useBackClick()
  return (
    <Grid container direction="column" spacing={3} sx={{ mt: 2, mb: 5 }}>
      <Grid item xs={12} flexGrow={1}>
        <Button
          variant="text"
          color="primary"
          startIcon={<ChevronLeftIcon />}
          onClick={handleBackClick}
        >
          {backTitle}
        </Button>
      </Grid>
      {children}
    </Grid>
  )
}
