import { useMemo } from 'react'
import { Skeleton } from '@mui/material'

import { SelectField, SelectFieldProps } from 'components'
import { usePerformerStatusesQuery } from 'api'
import { formatReferenceEntityToSelectOption } from 'utils'

type PerformerStatusSelectFieldProps = Omit<SelectFieldProps, 'options'>

export const PerformerStatusSelectField = (
  props: PerformerStatusSelectFieldProps,
) => {
  const { data, loading } = usePerformerStatusesQuery()

  const options = useMemo(
    () => formatReferenceEntityToSelectOption(data?.performerStatuses || []),
    [data],
  )

  return loading ? (
    <Skeleton height={56} />
  ) : (
    <SelectField options={options} {...props} />
  )
}
