import { gql, useMutation } from '@apollo/client'

import { PERFORMER } from 'api'
import { Performer } from 'types'

export const DELETE_PERFORMER_COMMENT = gql`
  mutation DeletePerformerComment($id: ID!) {
    deletePerformerComment(id: $id) {
      performer {
        ...Performer
      }
    }
  }
  ${PERFORMER}
`

export type DeletePerformerCommentData = {
  deletePerformerComment: { performer: Performer }
}

export type DeletePerformerCommentVariables = {
  id: string
}

export const useDeletePerformerCommentMutation = () => {
  return useMutation<
    DeletePerformerCommentData,
    DeletePerformerCommentVariables
  >(DELETE_PERFORMER_COMMENT)
}
