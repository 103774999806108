import { Grid, IconButton, List } from '@mui/material'
import { Edit as EditIcon } from '@mui/icons-material'

import { Customer } from 'types'

import { CustomerCompanyCard } from './CustomerCompanyCard'

type CustomerCompaniesProps = {
  customer: Customer
  onEdit?: () => void
}

export const CustomerCompanies = ({
  customer: { companies },
  onEdit,
}: CustomerCompaniesProps) => {
  return (
    <Grid container spacing={1} alignItems="center" wrap="nowrap">
      <Grid item sx={{ flexGrow: 1 }}>
        <List>
          {companies.length > 0
            ? companies.map((company) => (
                <CustomerCompanyCard company={company} key={company.id} />
              ))
            : 'Пока ни одной компании не добавлено'}
        </List>
      </Grid>
      <Grid item>
        <IconButton aria-label="Редактировать" onClick={onEdit} size="large">
          <EditIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}
