import { gql, MutationFunctionOptions, useMutation } from '@apollo/client'

import {
  GET_CURRENT_USER,
  GET_PAGINATED_PERFORMERS,
  PERFORMER,
  INPUT_ERROR,
  CurrentUserData,
  useCurrentUserQuery,
} from 'api'
import { Performer, InputError } from 'types'

export const CREATE_PERFORMER = gql`
  mutation CreatePerformer($input: CreatePerformerInput!) {
    createPerformer(input: $input) {
      performer {
        ...Performer
      }
      errors {
        ...InputError
      }
    }
  }
  ${PERFORMER}
  ${INPUT_ERROR}
`

type CreatePerformerDataSuccess = {
  performer: Performer
  errors: null
}

type CreatePerformerDataInputError = {
  performer: null
  errors: InputError<
    'bio' | 'cv' | 'nativeLanguageId' | 'profile.email' | 'profile.phone'
  >[]
}

export type CreatePerformerData = {
  createPerformer: CreatePerformerDataSuccess | CreatePerformerDataInputError
}

export type CreatePerformerVariables = {
  input: {
    cv?: File | null
    bio?: string
    nativeLanguageId: string
    statusId?: string
    profile:
      | {
          id: string
          city: string
          birthday: string
          countryId: string
          genderId: string
          avatar?: File | null
        }
      | {
          firstName: string
          lastName: string
          email: string
          phone: string
          city: string
          birthday: string
          countryId: string
          genderId: string
          avatar?: File | null
        }
  }
}

export const useCreatePerformerMutation = () => {
  const [createPerformer, fetchResult] = useMutation<
    CreatePerformerData,
    CreatePerformerVariables
  >(CREATE_PERFORMER, {
    refetchQueries: [GET_PAGINATED_PERFORMERS],
  })

  const { data, client } = useCurrentUserQuery()
  const currentUser = data!.currentUser!

  const sendCreatePerformer = async (
    mutationOptions: MutationFunctionOptions<
      CreatePerformerData,
      CreatePerformerVariables
    >,
  ) => {
    const result = await createPerformer(mutationOptions)

    if (result.data?.createPerformer.performer?.profile.id === currentUser.id) {
      await client.query<CurrentUserData>({
        query: GET_CURRENT_USER,
        fetchPolicy: 'network-only',
      })
    }

    return result
  }

  return [sendCreatePerformer, fetchResult] as const
}
