import { MouseEvent, useCallback } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Hidden,
  Link,
  Typography,
} from '@mui/material'
import { Phone as PhoneIcon, Email as EmailIcon } from '@mui/icons-material'

import { getUserFullname, pluralize, sliceContent } from 'utils'
import { Performer } from 'types'

type PerformerCardProps = {
  performer: Performer
}

export const PerformerCard = ({ performer }: PerformerCardProps) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const search = searchParams.get('search')

  const fullName = getUserFullname(performer.profile)
  const fullYears =
    new Date().getFullYear() -
    new Date(performer.profile.birthday).getFullYear()
  const age = `${fullYears}\u00a0${pluralize(fullYears, [
    'год',
    'года',
    'лет',
  ])}`

  const handleClick = useCallback(() => {
    const backButtonTitle = search ? 'К результатам поиска' : 'К исполнителям'

    navigate(`/performers/${performer.id}`, {
      state: { backButtonTitle },
    })
  }, [navigate, performer, search])

  return (
    <Card>
      <CardActionArea
        onClick={handleClick}
        sx={{ display: 'flex', justifyContent: 'flex-start' }}
      >
        <Hidden mdDown>
          <Avatar
            src={performer.profile.avatar}
            variant="square"
            sx={{
              minWidth: 160,
              width: 160,
              height: 160,
            }}
          />
        </Hidden>
        <CardContent sx={{ display: 'flex', flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} container spacing={2} alignItems="center">
              <Hidden mdUp>
                <Grid item>
                  <Avatar
                    src={performer.profile.avatar}
                    sx={{ width: 80, height: 80 }}
                  />
                </Grid>
              </Hidden>
              <Grid item flexGrow={1} container alignItems="center" spacing={1}>
                <Grid item flexGrow={1}>
                  <Typography variant="h6">{fullName}</Typography>
                </Grid>
                <Grid item xs={12} md="auto">
                  <Grid container spacing={1}>
                    <Grid item>
                      <EmailIcon />
                    </Grid>
                    <Grid item>
                      <Link
                        onClick={(e: MouseEvent) => {
                          e.stopPropagation()
                        }}
                        href={`mailto:${performer.profile.email}`}
                        variant="body1"
                      >
                        {performer.profile.email}
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md="auto">
                  <Grid container spacing={1}>
                    <Grid item>
                      <PhoneIcon />
                    </Grid>
                    <Grid item>
                      <Link
                        onClick={(e: MouseEvent) => {
                          e.stopPropagation()
                        }}
                        href={`tel:+${performer.profile.phone}`}
                        variant="body1"
                      >
                        +{performer.profile.phone}
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="body2">
                  {`${performer.profile.country.name}, ${performer.profile.city}, ${age}`}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary" component="p">
                  {performer.bio && sliceContent(performer.bio, 200, 275)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
