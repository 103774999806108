import { gql, useMutation } from '@apollo/client'

import { EDUCATION, INPUT_ERROR } from 'api'
import { Education, InputError } from 'types'

export const UPDATE_EDUCATION = gql`
  mutation UpdateEducation($input: UpdateEducationInput!) {
    updateEducation(input: $input) {
      education {
        ...Education
      }
      errors {
        ...InputError
      }
    }
  }
  ${EDUCATION}
  ${INPUT_ERROR}
`

type UpdateEducationDataSuccess = {
  education: Education
  errors: null
}

type UpdateEducationDataInputError = {
  education: null
  errors: InputError<'graduationYear' | 'diploma'>[]
}

export type UpdateEducationData = {
  updateEducation: UpdateEducationDataSuccess | UpdateEducationDataInputError
}

export type UpdateEducationVariables = {
  input: {
    id: string
    graduationYear?: number
    organizationName?: string
    countryId?: string
    major?: string
    diploma?: File | null
  }
}

export const useUpdateEducationMutation = () => {
  return useMutation<UpdateEducationData, UpdateEducationVariables>(
    UPDATE_EDUCATION,
  )
}
