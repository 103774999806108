import { ReactNode } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material'

type MenuItem = {
  path: string
  title: string
  icon: ReactNode
}

type MenuItemsProps = {
  menuItems: MenuItem[]
}

export const MenuItems = ({ menuItems }: MenuItemsProps) => {
  const location = useLocation()
  return (
    <List>
      {menuItems.map(({ path, title, icon }) => (
        <ListItem
          button
          component={RouterLink}
          key={title}
          to={path}
          selected={location.pathname === path}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={title} />
        </ListItem>
      ))}
    </List>
  )
}
