import { gql, useMutation } from '@apollo/client'

import { PERFORMER, INPUT_ERROR } from 'api'
import { Performer, InputError } from 'types'

export const CREATE_EXPERIENCE = gql`
  mutation CreateExperience($input: CreateExperienceInput!) {
    createExperience(input: $input) {
      performer {
        ...Performer
      }
      errors {
        ...InputError
      }
    }
  }
  ${PERFORMER}
  ${INPUT_ERROR}
`

type CreateExperienceDataSuccess = {
  performer: Performer
  errors: null
}

type CreateExperienceDataInputError = {
  performer: null
  errors: InputError<'startYear' | 'endYear'>[]
}

export type CreateExperienceData = {
  createExperience: CreateExperienceDataSuccess | CreateExperienceDataInputError
}

export type CreateExperienceVariables = {
  input: {
    performerId: string
    organizationName: string
    position: string
    countryId: string
    startYear: number
    endYear: number | null
    achievements?: string
  }
}

export const useCreateExperienceMutation = () => {
  return useMutation<CreateExperienceData, CreateExperienceVariables>(
    CREATE_EXPERIENCE,
  )
}
