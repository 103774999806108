import { getIsAuthorized } from 'utils'
import { Role, User } from 'types'

type Route = {
  path: string
  roles?: Role[]
  hasAccess?: (user: User) => boolean
  isUnauthenticated?: boolean
}

const routes: Route[] = [
  {
    path: '/performers/onboarding',
    roles: ['user'],
    hasAccess: (user) =>
      user.performer === null || !user.performer.isOnboardingFinished,
  },
  {
    path: '/performers/me',
    roles: ['user'],
    hasAccess: (user) =>
      user.performer !== null && user.performer.isOnboardingFinished,
  },
  {
    path: '/performers',
    roles: ['admin', 'employee'],
  },
  {
    path: '/auth/sign-in',
    isUnauthenticated: true,
  },
]

export const getUserRedirectPath = (user: User | null) => {
  return routes.filter(({ roles, hasAccess, isUnauthenticated }: Route) => {
    const isAuthorized = getIsAuthorized({
      currentUser: user,
      roles,
      hasAccess,
      isUnauthenticated,
    })
    return isAuthorized
  })[0].path
}
