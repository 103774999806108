import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  KeyboardEvent,
  ReactNode,
} from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import {
  Avatar,
  ClickAwayListener,
  Grid,
  Grow,
  Divider,
  IconButton,
  Paper,
  Popper,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  Menu as MenuIcon,
  People as PeopleIcon,
  Person as PersonIcon,
  Brightness4 as Brightness4Icon,
  Brightness7 as Brightness7Icon,
  Business as BusinessIcon,
  BusinessCenter as BusinessCenterIcon,
  FolderCopy as FolderCopyIcon,
  ExitToApp as ExitToAppIcon,
  RecentActors as RecentActorsIcon,
} from '@mui/icons-material'

import { useCurrentUserQuery } from 'api'
import { getUserFullname, getIsAuthorized, useChangeThemeMethods } from 'utils'

import { DesktopNavbar } from './DesktopNavbar'
import { MobileNavbar } from './MobileNavbar'
import { MenuItems } from './MenuItems'
import { SettingsItems } from './SettingsItems'
import { User, Role } from 'types'

const NavbarAvatar = styled(Avatar)`
  width: 32px;
  height: 32px;
`

const SettingsAvatar = styled(Avatar)`
  width: 80px;
  height: 80px;
`

const StyledPaper = styled(Paper)(
  ({ theme }) => `
    margin-top: ${theme.spacing(3)};
    margin-right: ${theme.spacing(0.5)};
    padding-left: ${theme.spacing(2)};
    padding-right: ${theme.spacing(2)};
    ${theme.breakpoints.down('sm')}: {
      margin-top: ${theme.spacing(2)};
    }
`,
)

type TMenuItem = {
  path: string
  title: string
  icon: ReactNode
  roles?: Role[]
  isUnauthenticated?: boolean
  hasAccess?: (user: User) => boolean
}

const menuItemsConfig: TMenuItem[] = [
  {
    path: '/performers',
    title: 'Исполнители',
    icon: <PeopleIcon />,
    roles: ['admin', 'employee'],
  },
  {
    path: '/orders',
    title: 'Заказы',
    icon: <FolderCopyIcon />,
    roles: ['admin', 'employee'],
  },
  {
    path: '/customers',
    title: 'Клиенты',
    icon: <RecentActorsIcon />,
    roles: ['admin', 'employee'],
  },
  {
    path: '/companies',
    title: 'Компании',
    icon: <BusinessIcon />,
    roles: ['admin', 'employee'],
  },
  {
    path: '/services',
    title: 'Услуги',
    icon: <BusinessCenterIcon />,
    roles: ['admin'],
  },
  {
    path: '/performers/me',
    title: 'Мой профиль',
    icon: <PersonIcon />,
    roles: ['admin', 'employee', 'user'],
    hasAccess: (user) =>
      user.performer !== null && user.performer.isOnboardingFinished,
  },
  {
    path: '/performers/onboarding',
    title: 'Новый профиль',
    icon: <PersonIcon />,
    roles: ['user'],
    hasAccess: (user) =>
      user.performer === null || !user.performer.isOnboardingFinished,
  },
]

type TSettingsItem = {
  path: string
  title: string
  roles?: Role[]
  isUnauthenticated?: boolean
  hasAccess?: (user: User) => boolean
}

const settingsItemsConfig: TSettingsItem[] = [
  {
    path: '/settings/account',
    title: 'Аккаунт',
  },
  {
    path: '/settings/security',
    title: 'Безопасность',
  },
  {
    path: '/auth/sign-out',
    title: 'Выйти',
  },
]

function getItemsByUser<
  T extends {
    roles?: Role[]
    isUnauthenticated?: boolean
    hasAccess?: (user: User) => boolean
  },
>(items: T[], user: User | null): T[] {
  return items.filter(({ roles, isUnauthenticated, hasAccess }: T) => {
    return getIsAuthorized({
      currentUser: user,
      roles,
      isUnauthenticated,
      hasAccess,
    })
  })
}

type NavbarProps = {
  children: ReactNode
}

export const Navbar = ({ children }: NavbarProps) => {
  const theme = useTheme()
  const { toggleTheme } = useChangeThemeMethods()
  const isMatchLg = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))
  const { data } = useCurrentUserQuery()

  const currentUser = data!.currentUser

  const menuItems = useMemo(
    () => getItemsByUser<TMenuItem>(menuItemsConfig, currentUser),
    [currentUser],
  )
  const settingsItems = useMemo(
    () => getItemsByUser<TSettingsItem>(settingsItemsConfig, currentUser),
    [currentUser],
  )

  const avatarUrl = currentUser?.avatar || ''
  const fullName = currentUser ? getUserFullname(currentUser) : ''

  const isMenuVisibleInitial =
    menuItems.length > 0 && localStorage.getItem('isMenuVisible')

  const [isMenuVisible, setIsMenuVisibleRaw] = useState(
    isMenuVisibleInitial ? JSON.parse(isMenuVisibleInitial) : false,
  )

  const setIsMenuVisible = useCallback(
    (value: boolean) => {
      localStorage.setItem('isMenuVisible', String(value))
      setIsMenuVisibleRaw(value)
    },
    [setIsMenuVisibleRaw],
  )

  const NavbarComponent = isMatchLg ? DesktopNavbar : MobileNavbar

  const [isSettingsVisible, setIsSettingsVisible] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)

  const onSettingsTrigger = useCallback(() => {
    setIsSettingsVisible((prevValue) => !prevValue)
  }, [setIsSettingsVisible])

  const onSettingsClose = useCallback(
    (e: MouseEvent | TouchEvent | React.MouseEvent) => {
      if (
        !anchorRef.current ||
        !anchorRef.current.contains(e.target as HTMLElement)
      ) {
        setIsSettingsVisible(false)
      }
    },
    [setIsSettingsVisible],
  )

  const onSettingsListKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Tab') {
        e.preventDefault()
        setIsSettingsVisible(false)
      }
    },
    [setIsSettingsVisible],
  )

  // return focus to the button when we transitioned from !isSettingsVisible -> isSettingsVisible
  const prevIsSettingsVisible = useRef(isSettingsVisible)
  useEffect(() => {
    if (prevIsSettingsVisible.current === true && isSettingsVisible === false) {
      anchorRef.current!.focus()
    }

    prevIsSettingsVisible.current = isSettingsVisible
  }, [isSettingsVisible])

  return (
    <NavbarComponent
      isMenuVisible={isMenuVisible}
      setIsMenuVisible={setIsMenuVisible}
      toolbarContent={
        <>
          <Grid container alignItems="center" spacing={1}>
            {menuItems.length > 0 && (
              <Grid item>
                <Tooltip title="Открыть меню" enterDelay={300}>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="Открыть меню"
                    onClick={() => setIsMenuVisible(!isMenuVisible)}
                    size="large"
                  >
                    <MenuIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
            <Grid item flexGrow={1}>
              <Typography variant="h6">ProLing CRM</Typography>
            </Grid>
            <Grid item>
              <Tooltip title="Сменить тему" enterDelay={300}>
                <IconButton
                  color="inherit"
                  onClick={toggleTheme}
                  aria-label="Сменить тему"
                  size="large"
                >
                  {theme.palette.mode === 'light' ? (
                    <Brightness4Icon />
                  ) : (
                    <Brightness7Icon />
                  )}
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              {!currentUser && (
                <Tooltip title="Войти" enterDelay={300}>
                  <IconButton
                    component={RouterLink}
                    color="inherit"
                    aria-label="Войти"
                    to="/auth/sign-in"
                    size="large"
                  >
                    <ExitToAppIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          </Grid>
          {currentUser && (
            <>
              <Grid item>
                <Tooltip title="Открыть настройки" enterDelay={300}>
                  <IconButton
                    color="inherit"
                    ref={anchorRef}
                    aria-controls={
                      isSettingsVisible ? 'menu-list-grow' : undefined
                    }
                    aria-haspopup="true"
                    onClick={onSettingsTrigger}
                    size="large"
                  >
                    <NavbarAvatar src={avatarUrl} alt={fullName} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Popper
                open={isSettingsVisible}
                anchorEl={anchorRef.current}
                transition
                disablePortal
                placement="bottom"
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <StyledPaper>
                      <ClickAwayListener onClickAway={onSettingsClose}>
                        <Grid
                          container
                          direction="column"
                          alignItems="center"
                          justifyContent="center"
                          spacing={2}
                        >
                          <Grid item>
                            <SettingsAvatar src={avatarUrl} />
                          </Grid>
                          {fullName && (
                            <Grid item>
                              <Typography variant="body1" align="center">
                                {fullName}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                align="center"
                              >
                                {currentUser.email}
                              </Typography>
                            </Grid>
                          )}
                          <Grid item container direction="column">
                            <Divider variant="fullWidth" />
                          </Grid>
                          <Grid item>
                            <SettingsItems
                              settingsItems={settingsItems}
                              autoFocusItem={isSettingsVisible}
                              onKeyDown={onSettingsListKeyDown}
                              onSettingsItemClick={onSettingsClose}
                            />
                          </Grid>
                        </Grid>
                      </ClickAwayListener>
                    </StyledPaper>
                  </Grow>
                )}
              </Popper>
            </>
          )}
        </>
      }
      drawerContent={<MenuItems menuItems={menuItems} />}
    >
      {children}
    </NavbarComponent>
  )
}
