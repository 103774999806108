const extensionAcceptRules = {
  jpg: { 'image/jpg': ['.jpg', '.jpeg'] },
  png: { 'image/png': ['.png'] },
  pdf: { 'application/pdf': ['.pdf'] },
  doc: { 'application/msword': ['.doc'] },
  docx: {
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
      '.docx',
    ],
  },
}

export const getAcceptExtensions = (extensions: string[]) =>
  extensions.reduce(
    (acc, ext) =>
      ext && ext in extensionAcceptRules
        ? {
            ...acc,
            ...extensionAcceptRules[ext as keyof typeof extensionAcceptRules],
          }
        : acc,
    {},
  )
