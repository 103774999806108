import { Grid, Typography, Link, IconButton } from '@mui/material'
import { Edit as EditIcon } from '@mui/icons-material'

import { Education } from 'types'

type PerformerEducationProps = {
  education: Education
  onEdit?: () => void
}

export const PerformerEducation = ({
  education,
  onEdit,
}: PerformerEducationProps) => {
  return (
    <Grid container spacing={1} alignItems="center" wrap="nowrap">
      <Grid item flexGrow={1}>
        <Grid container spacing={1} direction="column">
          <Grid item xs={12}>
            <Typography variant="body1">
              {education.organizationName}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Typography variant="body2">
                  {education.graduationYear}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  {education.country.name}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{education.major}</Typography>
              </Grid>
            </Grid>
          </Grid>
          {education.diploma && (
            <Grid item>
              <Link variant="body2" href={education.diploma}>
                Скачать документ
              </Link>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <IconButton aria-label="Редактировать" onClick={onEdit} size="large">
          <EditIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}
