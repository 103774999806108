import { useCallback, useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { Grid, IconButton, InputAdornment, Typography } from '@mui/material'
import { Clear as ClearIcon } from '@mui/icons-material'
import useInfiniteScroll from 'react-infinite-scroll-hook'

import { usePaginatedServicesQuery } from 'api'
import { Field, FixedButton, Spinner } from 'components'
import { useDebounce, useInitialSearchParams } from 'utils'
import { ServiceCard } from './ServiceCard'

const getServicesFooterText = (itemsLength: number, totalCount: number) => {
  if (totalCount === 0) return 'К сожалению, ничего не найдено по запросу'
  if (itemsLength === totalCount) return 'Это все услуги, найденные по запросу'
  return ''
}

type ServicesSearchFormData = {
  search: string
}

export const ServicesList = () => {
  const initialSearchParams = useInitialSearchParams()
  const [, setSearchParams] = useSearchParams()

  const methods = useForm<ServicesSearchFormData>({
    defaultValues: {
      search: initialSearchParams.get('search') || '',
    },
    mode: 'onBlur',
    shouldUnregister: false,
  })

  const { search } = useDebounce<ServicesSearchFormData>(methods.watch(), 300)

  const variables = useMemo(
    () => ({
      ...(search && { search }),
    }),
    [search],
  )

  const {
    data: paginatedServicesData,
    error: paginatedServicesError,
    loading: paginatedServicesLoading,
    fetchMore,
  } = usePaginatedServicesQuery({
    variables,
  })

  const paginatedServicesHasNextPage = Boolean(
    paginatedServicesData?.paginatedServices.pageInfo.endCursor,
  )

  const [sentryRef] = useInfiniteScroll({
    loading: paginatedServicesLoading,
    hasNextPage: paginatedServicesHasNextPage,
    onLoadMore: fetchMore,
    disabled: Boolean(paginatedServicesError),
    rootMargin: '0px 0px 400px 0px',
  })

  useEffect(() => {
    setSearchParams({ ...(search && { search }) })
  }, [setSearchParams, search])

  const resetSearchField = useCallback(() => {
    methods.resetField('search')
  }, [methods])

  return (
    <>
      <FormProvider {...methods}>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="stretch"
          sx={{ mt: 1 }}
        >
          <Grid item xs={12} flexGrow={1}>
            <Field
              name="search"
              label="Поиск"
              withoutHelperText
              {...(search && {
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="clear search input"
                        onClick={resetSearchField}
                        size="small"
                        edge="end"
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              })}
            />
          </Grid>
          {paginatedServicesLoading && !Boolean(paginatedServicesData) ? (
            <Grid item xs={12}>
              <Spinner />
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {paginatedServicesData!.paginatedServices.items.map(
                    (service) => (
                      <Grid item xs={12} key={service.id}>
                        <ServiceCard service={service} />
                      </Grid>
                    ),
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                container
                alignItems="flex-start"
                justifyContent="center"
                sx={{ height: 80 }}
              >
                {paginatedServicesHasNextPage || paginatedServicesLoading ? (
                  <div ref={sentryRef} />
                ) : null}
                {paginatedServicesLoading ? (
                  <Spinner />
                ) : (
                  <Typography variant="body1" color="primary">
                    {getServicesFooterText(
                      paginatedServicesData!.paginatedServices.items.length,
                      paginatedServicesData!.paginatedServices.pageInfo
                        .totalCount,
                    )}
                  </Typography>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </FormProvider>
      <FixedButton ariaLabel="Add service" to="/services/create" />
    </>
  )
}
