import { QueryHookOptions, gql, useQuery } from '@apollo/client'

import { RATE } from 'api'
import { Rate } from 'types'

export type RatesData = {
  rates: Rate[]
}

export const GET_RATES = gql`
  query GetRates {
    rates {
      ...Rate
    }
  }
  ${RATE}
`

export const ratesQueryOptions: QueryHookOptions<RatesData> = {
  errorPolicy: 'all',
}

export function useRatesQuery() {
  return useQuery<RatesData>(GET_RATES, ratesQueryOptions)
}
