import { useCallback, useMemo, useState, SyntheticEvent } from 'react'

import { AutocompleteField, AutocompleteFieldProps } from 'components'
import { usePaginatedCompaniesQuery } from 'api'
import type { Company } from 'types'

export type CompanyFieldProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> = Omit<
  AutocompleteFieldProps<Company, Multiple, DisableClearable, FreeSolo>,
  'loading' | 'options' | 'loading'
>

const getCompanyLabel = ({ name }: Company) => name

const isOptionEqualToValue = (option: Company, value: Company) => {
  return option.id === value.id
}

const filterOptions = (options: Company[]) => options

export function CompanyField<Multiple extends boolean = false>(
  props: CompanyFieldProps<Multiple, false, false>,
) {
  const [search, setSearch] = useState('')

  const { data, loading } = usePaginatedCompaniesQuery({
    variables: { search },
  })

  const options = useMemo(() => data?.paginatedCompanies.items || [], [data])

  const handleInputChange = useCallback(
    (_e: SyntheticEvent, value: string) => {
      setSearch(value)
    },
    [setSearch],
  )

  return (
    <AutocompleteField<Company, Multiple, false, false>
      loading={loading}
      options={options}
      onInputChange={handleInputChange}
      getOptionLabel={getCompanyLabel}
      filterOptions={filterOptions}
      isOptionEqualToValue={isOptionEqualToValue}
      {...props}
    />
  )
}
