import { useMemo } from 'react'

import type { Topic, Software } from 'types'

type UsePaginatedPerformersQueryVariablesProps = {
  search: string
  statusId: string
  genderId: string
  countryId: string
  nativeLanguageId: string
  topics: Topic[]
  software: Software[]
  serviceGroupId: string
  serviceTypeId: string
  serviceSourceLanguageId: string
  serviceTargetLanguageId: string
}

export function usePaginatedPerformersQueryVariables({
  search,
  statusId,
  genderId,
  countryId,
  nativeLanguageId,
  topics,
  software,
  serviceGroupId,
  serviceTypeId,
  serviceSourceLanguageId,
  serviceTargetLanguageId,
}: UsePaginatedPerformersQueryVariablesProps) {
  const filterServiceVariables = useMemo(
    () => ({
      ...(serviceGroupId && { groupId: serviceGroupId }),
      ...(serviceTypeId && { typeId: serviceTypeId }),
      ...(serviceSourceLanguageId && {
        sourceLanguageId: serviceSourceLanguageId,
      }),
      ...(serviceTargetLanguageId && {
        targetLanguageId: serviceTargetLanguageId,
      }),
    }),
    [
      serviceGroupId,
      serviceTypeId,
      serviceSourceLanguageId,
      serviceTargetLanguageId,
    ],
  )

  const filterVariables = useMemo(
    () => ({
      ...(statusId && { statusId }),
      ...(genderId && { genderId }),
      ...(countryId && { countryId }),
      ...(nativeLanguageId && { nativeLanguageId }),
      ...(topics.length && {
        topicIds: topics.map(({ id }) => id),
      }),
      ...(software.length && {
        softwareIds: software.map(({ id }) => id),
      }),
      ...(Object.keys(filterServiceVariables).length && {
        service: filterServiceVariables,
      }),
    }),
    [
      statusId,
      genderId,
      countryId,
      nativeLanguageId,
      topics,
      software,
      filterServiceVariables,
    ],
  )

  return useMemo(
    () => ({
      ...(search && { search }),
      ...(Object.keys(filterVariables) && { filter: filterVariables }),
    }),
    [search, filterVariables],
  )
}
