import { useCallback } from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Paper,
  Grid,
  Typography,
  useMediaQuery,
  Theme,
} from '@mui/material'

import { CreateCustomerProfileForm } from './CreateCustomerProfileForm'
import { Customer } from 'types'

const StyledPaper = styled(Paper)(
  ({ theme }) => `
    min-width: ${theme.spacing(40)};
    margin-top: ${theme.spacing(3)};
    margin-bottom: ${theme.spacing(5)};
    padding: ${theme.spacing(3)};
    padding-bottom: ${theme.spacing(5)};
`,
)

export const CreateCustomer = () => {
  const isMatchSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
  const navigate = useNavigate()

  const onSubmit = useCallback(
    (customer: Customer) => {
      navigate(`/customers/${customer.id}`, { replace: true })
    },
    [navigate],
  )

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={10} md={8}>
        <StyledPaper>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box my={2}>
                <Typography
                  variant={isMatchSm ? 'h4' : 'h5'}
                  color="primary"
                  align="center"
                >
                  Новый клиент
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <CreateCustomerProfileForm onSubmit={onSubmit} />
            </Grid>
          </Grid>
        </StyledPaper>
      </Grid>
    </Grid>
  )
}
