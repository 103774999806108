import { useEffect } from 'react'
import { gql } from '@apollo/client'

import { USER, useCurrentUserQuery } from 'api'
import { User } from 'types'

type CurrentUserUpdatedData = {
  user: User
}

type CurrentUserUpdatedVariables = {
  userId: string
}

export const CURRENT_USER_UPDATED = gql`
  subscription($userId: ID!) {
    userUpdated(userId: $userId) {
      ...User
    }
  }
  ${USER}
`

export const useCurrentUserUpdatedSubscription = () => {
  const { data, client } = useCurrentUserQuery()

  useEffect(() => {
    if (data?.currentUser?.id) {
      const updatedCurrentUserSubscription = client
        .subscribe<CurrentUserUpdatedData, CurrentUserUpdatedVariables>({
          query: CURRENT_USER_UPDATED,
          variables: { userId: data.currentUser.id },
        })
        .subscribe(() => {})

      return () => {
        updatedCurrentUserSubscription.unsubscribe()
      }
    }
  })
}
