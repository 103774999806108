import { useCallback, SyntheticEvent } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Button, Grid } from '@mui/material'

import { useUpdatePerformerMutation } from 'api'
import {
  ButtonContainerWithSpinner,
  TopicField,
  SoftwareField,
} from 'components'
import { Performer, Software, Topic } from 'types'

type PerformerSkillsFormData = {
  topics: Topic[]
  software: Software[]
}

type PerformerSkillsFormProps = {
  onClose: () => void
  performer: Performer
  onSubmit?: () => void
}

export const PerformerSkillsForm = ({
  performer,
  onSubmit: onSubmitAction,
  onClose,
}: PerformerSkillsFormProps) => {
  const [updatePerformer, { loading: updatePerformerLoading }] =
    useUpdatePerformerMutation()

  const methods = useForm<PerformerSkillsFormData>({
    defaultValues: {
      topics: performer.topics,
      software: performer.software,
    },
    mode: 'onBlur',
  })

  const onSubmit = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault()

      await methods.handleSubmit(async ({ topics, software }) => {
        await updatePerformer({
          variables: {
            input: {
              id: performer.id,
              topics: topics ? topics.map(({ id }) => id) : [],
              software: software ? software.map(({ id }) => id) : [],
            },
          },
        })
        if (onSubmitAction) onSubmitAction()
        onClose()
      })()
    },
    [methods, updatePerformer, onSubmitAction, onClose, performer.id],
  )

  return (
    <FormProvider {...methods}>
      <Grid
        container
        spacing={2}
        noValidate
        autoComplete="off"
        onSubmit={onSubmit}
        component="form"
      >
        <Grid item xs={12}>
          <TopicField name="topics" label="Тематики" multiple />
        </Grid>
        <Grid item xs={12}>
          <SoftwareField
            name="software"
            label="Программное обеспечение"
            multiple
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={1}>
            <Grid item>
              <Button variant="text" color="primary" onClick={onClose}>
                Отмена
              </Button>
            </Grid>
            <Grid item>
              <ButtonContainerWithSpinner isLoading={updatePerformerLoading}>
                <Button
                  variant="text"
                  color="primary"
                  type="submit"
                  disabled={updatePerformerLoading}
                >
                  Сохранить
                </Button>
              </ButtonContainerWithSpinner>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}
