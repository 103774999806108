import { gql, useMutation } from '@apollo/client'

import { ORDER } from 'api'
import { Order } from 'types'

export const DELETE_ORDER_COMMENT = gql`
  mutation DeleteOrderComment($id: ID!) {
    deleteOrderComment(id: $id) {
      order {
        ...Order
      }
    }
  }
  ${ORDER}
`

export type DeleteOrderCommentData = {
  deleteOrderComment: { order: Order }
}

export type DeleteOrderCommentVariables = {
  id: string
}

export const useDeleteOrderCommentMutation = () => {
  return useMutation<DeleteOrderCommentData, DeleteOrderCommentVariables>(
    DELETE_ORDER_COMMENT,
  )
}
