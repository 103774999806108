import { Typography } from '@mui/material'

export const PrivacyAgreement = () => (
  <div>
    <Typography color="primary" variant="h4" sx={{ mt: 2, mb: 2 }}>
      Условия передачи информации
    </Typography>
    <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
      Я даю согласие ООО "Пролинг" на обработку всех моих персональных данных,
      указанных при заполнении форм на сайте, любыми способами, в том числе
      воспроизведение, электронное копирование, обезличивание, блокирование,
      уничтожение, а также вышеуказанную обработку иных моих персональных
      данных, полученных в результате их обработки, с целью сотрудничества в
      области переводов на иностранные языки.
    </Typography>
    <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
      Указанное согласие дано на срок 15 лет, а в случае его отзыва обработка
      моих персональных данных должна быть прекращена ООО "Пролинг" и данные
      уничтожены.
    </Typography>
    <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
      Я даю согласие на получение мной служебных писем от ООО "Пролинг" путем
      рассылки по всем зарегистрированным исполнителям.
    </Typography>
  </div>
)
