import { ReactNode } from 'react'
import { useParams } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import {
  Box,
  Paper,
  Grid,
  Typography,
  useMediaQuery,
  Theme,
} from '@mui/material'
import invariant from 'tiny-invariant'

import { useCustomerQuery, useCustomerUpdatedSubscription } from 'api'
import { NotFound404 } from 'pages'
import { LayoutWithBackButton, Spinner } from 'components'
import { Customer as TCustomer } from 'types'

import { EditableCustomerProfile } from './EditableCustomerProfile'
import { EditableCustomerCompanies } from './EditableCustomerCompanies'
import { EditableCustomerComments } from './EditableCustomerComments'

const StyledPaper = styled(Paper)(
  ({ theme }) => `
    min-width: ${theme.spacing(40)};
    padding: ${theme.spacing(3)};
    padding-bottom: ${theme.spacing(5)};
`,
)

type CustomerForm = {
  title: string
  formComponent: ReactNode
}

const getForms = (customer: TCustomer): CustomerForm[] => [
  {
    title: 'Профиль',
    formComponent: <EditableCustomerProfile customer={customer} />,
  },
  {
    title: 'Компании',
    formComponent: <EditableCustomerCompanies customer={customer} />,
  },
  {
    title: 'Комментарии',
    formComponent: <EditableCustomerComments customer={customer} />,
  },
]

type URLParams = {
  id: string
}

export const Customer = () => {
  const isMatchSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
  const { id } = useParams<URLParams>()
  invariant(id, "Customer id isn't set")

  const { data: customerData, loading: customerLoading } = useCustomerQuery(
    id,
    { fetchPolicy: 'network-only' },
  )

  const customer = customerData?.customer
  useCustomerUpdatedSubscription(customer?.id)

  if (customerLoading) {
    return (
      <LayoutWithBackButton>
        <Grid item xs={12}>
          <Spinner />
        </Grid>
      </LayoutWithBackButton>
    )
  }

  if (!customer) {
    return (
      <LayoutWithBackButton>
        <Grid item xs={12}>
          <NotFound404 withLayout={false} />
        </Grid>
      </LayoutWithBackButton>
    )
  }

  return (
    <LayoutWithBackButton>
      {getForms(customer).map(({ title, formComponent }, index) => (
        <Grid item xs={12} key={`${title}-${index}`}>
          <StyledPaper>
            <Grid container direction="column" spacing={2}>
              <Grid item xs={12}>
                <Box my={2}>
                  <Typography
                    variant={isMatchSm ? 'h4' : 'h5'}
                    color="primary"
                    align="center"
                  >
                    {title}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                {formComponent}
              </Grid>
            </Grid>
          </StyledPaper>
        </Grid>
      ))}
    </LayoutWithBackButton>
  )
}
