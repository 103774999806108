import { Routes, Route } from 'react-router-dom'

import { Layout, RequireAuth } from 'components'
import { CompaniesList } from './CompaniesList'
import { Company } from './Company'
import { CreateCompany } from './CreateCompany'

export const Companies = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequireAuth roles={['employee', 'admin']}>
            <Layout>
              <CompaniesList />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path="create"
        element={
          <RequireAuth roles={['employee', 'admin']}>
            <Layout maxWidth="md">
              <CreateCompany />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path=":id"
        element={
          <RequireAuth roles={['employee', 'admin']}>
            <Layout maxWidth="md">
              <Company />
            </Layout>
          </RequireAuth>
        }
      />
    </Routes>
  )
}
