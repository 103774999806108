import { styled } from '@mui/material/styles'
import { Avatar, Box, Grid, IconButton, Link, Typography } from '@mui/material'
import { Edit as EditIcon } from '@mui/icons-material'

import { getUserFullname } from 'utils'
import { Customer } from 'types'

const StyledAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
`

type CustomerProfileProps = {
  onEdit?: () => void
  customer: Customer
}

export const CustomerProfile = ({ onEdit, customer }: CustomerProfileProps) => {
  const fullName = getUserFullname(customer.profile)

  const {
    profile: { avatar, email, phone, birthday },
  } = customer

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item>
        <StyledAvatar src={avatar} alt={fullName} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4" align="center">
          {fullName}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1} alignItems="center" wrap="nowrap">
          <Grid item flexGrow={1}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="caption" color="primary">
                  Почта
                </Typography>
                <Box>
                  <Link href={`mailto:${email}`} variant="body1">
                    {email}
                  </Link>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" color="primary">
                  Телефон
                </Typography>
                <Box>
                  <Link href={`tel:+${phone}`} variant="body1">
                    +{phone}
                  </Link>
                </Box>
              </Grid>
              {birthday && (
                <Grid item xs={12}>
                  <Typography variant="caption" color="primary">
                    Дата рождения
                  </Typography>
                  <Typography variant="body1">{birthday}</Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="Редактировать"
              onClick={onEdit}
              size="large"
            >
              <EditIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
