import { useCallback, SyntheticEvent } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { format } from 'date-fns'
import { Button, Grid } from '@mui/material'

import { useCreateCustomerMutation } from 'api'
import {
  AvatarField,
  ButtonContainerWithSpinner,
  DateField,
  EmailField,
  Field,
  PhoneField,
} from 'components'
import { setFormErrors, resetPhoneFormat } from 'utils'
import { Customer } from 'types'

const errorsMessages = {
  'profile.email': {
    key: 'email' as const,
    messages: {
      'has already been taken':
        'Пользователь с таким email уже зарегистрирован',
      'is invalid': 'Неверный email',
    },
    default: 'Нельзя зарегистрироваться с таким email',
  },
  'profile.phone': {
    key: 'phone' as const,
    messages: {
      'is invalid': 'Неверный номер телефона',
      'has already been taken':
        'Пользователь с таким телефоном уже зарегистрирован',
    },
    default: 'Нельзя зарегистрироваться с таким номером телефона',
  },
}

type CreateCustomerProfileFormData = {
  id: string
  birthday?: Date | null
  firstName: string
  lastName: string
  email: string
  phone: string
  avatar?: File | string
}

type CreateCustomerProfileFormProps = {
  onSubmit?: (customer: Customer) => void
}

export const CreateCustomerProfileForm = ({
  onSubmit: onSubmitAction,
}: CreateCustomerProfileFormProps) => {
  const [createCustomer] = useCreateCustomerMutation()

  const methods = useForm<CreateCustomerProfileFormData>({
    defaultValues: {
      birthday: null,
      avatar: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    },
    mode: 'onBlur',
  })

  const onSubmit = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault()

      methods.handleSubmit(async (formData) => {
        const {
          birthday: rawBirthday,
          avatar,
          firstName,
          lastName,
          email,
          phone,
        } = formData

        const birthday = rawBirthday ? format(rawBirthday, 'yyyy-MM-dd') : ''

        const { data } = await createCustomer({
          variables: {
            input: {
              profile: {
                firstName,
                lastName,
                phone: resetPhoneFormat(phone),
                email,
                ...(birthday && { birthday }),
                // URL of existing avatar is not valid value
                ...(typeof avatar !== 'string' && { avatar }),
              },
            },
          },
        })

        if (data?.createCustomer?.errors) {
          setFormErrors<Pick<CreateCustomerProfileFormData, 'email' | 'phone'>>(
            methods.setError,
            data.createCustomer.errors,
            errorsMessages,
          )
        }

        if (data?.createCustomer?.customer) {
          if (onSubmitAction) onSubmitAction(data.createCustomer.customer)
        }
      })()
    },
    [methods, createCustomer, onSubmitAction],
  )

  return (
    <FormProvider {...methods}>
      <Grid
        container
        spacing={2}
        noValidate
        autoComplete="off"
        onSubmit={onSubmit}
        component="form"
      >
        <Grid item container alignItems="center" spacing={2}>
          <Grid item xs={12} sm={6}>
            <AvatarField name="avatar" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  name="firstName"
                  label="Имя"
                  rules={{
                    required: true,
                    maxLength: {
                      value: 255,
                      message: 'Не более 255 символов',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="lastName"
                  label="Фамилия"
                  rules={{
                    required: true,
                    maxLength: {
                      value: 255,
                      message: 'Не более 255 символов',
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <EmailField
            name="email"
            label="Email"
            rules={{
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PhoneField rules={{ required: true }} name="phone" label="Телефон" />
        </Grid>
        <Grid item xs={12}>
          <DateField name="birthday" label="Дата рождения" />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={1}>
            <Grid item>
              <ButtonContainerWithSpinner
                isLoading={methods.formState.isSubmitting}
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={methods.formState.isSubmitting}
                >
                  Добавить клиента
                </Button>
              </ButtonContainerWithSpinner>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}
