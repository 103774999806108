type SelectOption = {
  value: string
  label: string
}

type ReferenceEntity = {
  id: string
  name: string
}

export const formatReferenceEntityToSelectOption = (
  entities: ReferenceEntity[],
): SelectOption[] =>
  entities.map(({ id, name }) => ({ value: id, label: name }))

export const getUserFullname = ({
  firstName,
  lastName,
}: {
  firstName?: string
  lastName?: string
}) => {
  if (firstName && lastName) return `${firstName} ${lastName}`
  if (firstName && !lastName) return firstName
  return undefined
}

export const getFilenameFromS3Path = (url: string) => {
  const filenameIdStart = url.lastIndexOf('/') + 1
  const versionStart = url.lastIndexOf('?v=')
  const filenameWithId = url.slice(filenameIdStart, versionStart)
  const filenameStart = filenameWithId.indexOf('_') + 1
  const filename = filenameWithId.substr(filenameStart)
  const decodedFilename = decodeURI(filename)

  return decodedFilename
}

export const pluralize = (number: number, titles: string[]) => {
  const cases = [2, 0, 1, 1, 1, 2]
  return titles[
    number % 100 > 4 && number % 100 < 20 ? 2 : cases[Math.min(number % 10, 5)]
  ]
}

export const sliceContent = (content: string, min: number, max: number) => {
  if (content.length <= min) return content
  const trailing = content.slice(min, max)
  return (
    content.slice(0, min) +
    (trailing.lastIndexOf('.') !== -1
      ? trailing.slice(0, trailing.lastIndexOf('.') + 1)
      : `${trailing.slice(0, trailing.lastIndexOf(' '))}...`)
  )
}

export const resetPhoneFormat = (phone: string) => {
  return phone.replace(/\W/g, '')
}

const months = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'cентября',
  'октября',
  'ноября',
  'декабря',
]

export const formatDate = (timestamp: string) => {
  const date = new Date(timestamp)
  return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`
}

export const formatDateTime = (timestamp: string) => {
  const date = new Date(timestamp)
  return `${date.getHours()}:${('0' + date.getMinutes()).slice(
    -2,
  )}, ${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`
}

export const localizeNumber = (num: number | string) => {
  return String(num).replace(/\./, ',')
}

export const getCents = (rawDollars: string | number, isShort = false) => {
  const dollars =
    typeof rawDollars === 'string'
      ? Number(rawDollars.replace(/,/, '.'))
      : rawDollars

  return isShort
    ? Math.round(Math.round((dollars * 1000) / 10)) * 10
    : Math.round(dollars * 1_000)
}

export const getDollars = (rawCents: string | number, isShort = false) => {
  const cents = typeof rawCents === 'string' ? Number(rawCents) : rawCents

  return isShort ? Math.round(cents / 10) / 100 : cents / 1_000
}
