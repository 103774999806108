import { gql, useMutation } from '@apollo/client'

import { CUSTOMER, INPUT_ERROR } from 'api'
import { Customer, InputError } from 'types'

export const UPDATE_CUSTOMER_COMMENT = gql`
  mutation UpdateCustomerComment($input: UpdateCustomerCommentInput!) {
    updateCustomerComment(input: $input) {
      customer {
        ...Customer
      }
      errors {
        ...InputError
      }
    }
  }
  ${CUSTOMER}
  ${INPUT_ERROR}
`

type UpdateCustomerCommentDataSuccess = {
  customer: Customer
  errors: null
}

type UpdateCustomerCommentDataInputError = {
  customer: null
  errors: InputError<'content'>[]
}

export type UpdateCustomerCommentData = {
  updateCustomerComment:
    | UpdateCustomerCommentDataSuccess
    | UpdateCustomerCommentDataInputError
}

export type UpdateCustomerCommentVariables = {
  input: {
    id: string
    content: string
  }
}

export const useUpdateCustomerCommentMutation = () => {
  return useMutation<UpdateCustomerCommentData, UpdateCustomerCommentVariables>(
    UPDATE_CUSTOMER_COMMENT,
  )
}
