import { useEditable } from 'utils'

import { CustomerCompanies } from './CustomerCompanies'
import { CustomerCompaniesForm } from './CustomerCompaniesForm'
import { Customer } from 'types'

type EditableCustomerCompaniesProps = {
  customer: Customer
}

export const EditableCustomerCompanies = ({
  customer,
}: EditableCustomerCompaniesProps) => {
  const { isEditing, handleEdit, handleClose } = useEditable()

  if (isEditing) {
    return <CustomerCompaniesForm customer={customer} onClose={handleClose} />
  }

  return <CustomerCompanies customer={customer} onEdit={handleEdit} />
}
