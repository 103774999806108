import { gql, MutationFunctionOptions, useMutation } from '@apollo/client'

import {
  GET_CURRENT_USER,
  PERFORMER,
  INPUT_ERROR,
  CurrentUserData,
  useCurrentUserQuery,
} from 'api'
import { Performer, InputError } from 'types'

export const UPDATE_PERFORMER = gql`
  mutation UpdatePerformer($input: UpdatePerformerInput!) {
    updatePerformer(input: $input) {
      performer {
        ...Performer
      }
      errors {
        ...InputError
      }
    }
  }
  ${PERFORMER}
  ${INPUT_ERROR}
`

type UpdatePerformerDataSuccess = {
  performer: Performer
  errors: null
}

type UpdatePerformerDataInputError = {
  performer: null
  errors: InputError<'profile.email' | 'profile.phone'>[]
}

export type UpdatePerformerData = {
  updatePerformer: UpdatePerformerDataSuccess | UpdatePerformerDataInputError
}

export type UpdatePerformerVariables = {
  input: {
    id: string
    cv?: File | null
    bio?: string
    topics?: string[]
    software?: string[]
    isOnboardingFinished?: boolean
    nativeLanguageId?: string
    statusId?: string
    profile?: {
      email?: string
      phone?: string
      firstName?: string
      lastName?: string
      city?: string
      birthday?: string
      countryId?: string
      genderId?: string
      avatar?: File | null
    }
  }
}

export const useUpdatePerformerMutation = () => {
  const [updatePerformer, fetchResult] = useMutation<
    UpdatePerformerData,
    UpdatePerformerVariables
  >(UPDATE_PERFORMER)
  const { data, client } = useCurrentUserQuery()
  const currentUser = data!.currentUser!

  const sendUpdatePerformer = async (
    mutationOptions: MutationFunctionOptions<
      UpdatePerformerData,
      UpdatePerformerVariables
    >,
  ) => {
    const result = await updatePerformer(mutationOptions)

    if (result.data?.updatePerformer.performer?.profile.id === currentUser.id) {
      await client.query<CurrentUserData>({
        query: GET_CURRENT_USER,
        fetchPolicy: 'network-only',
      })
    }

    return result
  }

  return [sendUpdatePerformer, fetchResult] as const
}
