import { gql, useMutation } from '@apollo/client'

import { ORDER, INPUT_ERROR } from 'api'
import { Order, InputError } from 'types'

export const UPDATE_ORDER_COMMENT = gql`
  mutation UpdateOrderComment($input: UpdateOrderCommentInput!) {
    updateOrderComment(input: $input) {
      order {
        ...Order
      }
      errors {
        ...InputError
      }
    }
  }
  ${ORDER}
  ${INPUT_ERROR}
`

type UpdateOrderCommentDataSuccess = {
  order: Order
  errors: null
}

type UpdateOrderCommentDataInputError = {
  order: null
  errors: InputError<'content'>[]
}

export type UpdateOrderCommentData = {
  updateOrderComment:
    | UpdateOrderCommentDataSuccess
    | UpdateOrderCommentDataInputError
}

export type UpdateOrderCommentVariables = {
  input: {
    id: string
    content: string
  }
}

export const useUpdateOrderCommentMutation = () => {
  return useMutation<UpdateOrderCommentData, UpdateOrderCommentVariables>(
    UPDATE_ORDER_COMMENT,
  )
}
