import { useCallback, SyntheticEvent } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Grid, Button, useMediaQuery, Theme } from '@mui/material'

import {
  useCreateOrderCommentMutation,
  useUpdateOrderCommentMutation,
  useDeleteOrderCommentMutation,
} from 'api'
import { ButtonContainerWithSpinner, Field } from 'components'
import { Comment } from 'types'

type OrderCommentFormData = {
  content: string
}

type OrderCommentFormProps = {
  comment?: Comment
  orderId?: string
  onClose: () => void
}

export const OrderCommentForm = ({
  comment,
  orderId,
  onClose,
}: OrderCommentFormProps) => {
  const isMatchSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))

  const [createOrderComment, { loading: createOrderCommentLoading }] =
    useCreateOrderCommentMutation()
  const [updateOrderComment, { loading: updateOrderCommentLoading }] =
    useUpdateOrderCommentMutation()
  const [deleteOrderComment, { loading: deleteOrderCommentLoading }] =
    useDeleteOrderCommentMutation()

  const isSubmittingCreateOrUpdate =
    createOrderCommentLoading || updateOrderCommentLoading

  const methods = useForm<OrderCommentFormData>({
    defaultValues: comment
      ? {
          content: comment.content,
        }
      : {
          content: '',
        },
    mode: 'onBlur',
  })

  const onSubmit = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault()

      await methods.handleSubmit(async (formData) => {
        if (comment) {
          await updateOrderComment({
            variables: { input: { id: comment.id, ...formData } },
          })
        } else if (orderId) {
          await createOrderComment({
            variables: { input: { orderId, ...formData } },
          })
        }

        if (onClose) onClose()
      })()
    },
    [
      methods,
      comment,
      onClose,
      createOrderComment,
      updateOrderComment,
      orderId,
    ],
  )

  const handleDelete = useCallback(() => {
    if (comment) {
      deleteOrderComment({
        variables: { id: comment.id },
      })
    }
  }, [comment, deleteOrderComment])

  return (
    <FormProvider {...methods}>
      <Grid
        container
        spacing={2}
        noValidate
        autoComplete="off"
        onSubmit={onSubmit}
        component="form"
      >
        <Grid item xs={12}>
          <Field name="content" label="Комментарий" multiline rows={3} />
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            spacing={isMatchSm ? 2 : 0}
            wrap="nowrap"
          >
            {comment && (
              <Grid item>
                <ButtonContainerWithSpinner
                  isLoading={deleteOrderCommentLoading}
                >
                  <Button
                    variant="text"
                    color="primary"
                    onClick={handleDelete}
                    disabled={deleteOrderCommentLoading}
                  >
                    Удалить
                  </Button>
                </ButtonContainerWithSpinner>
              </Grid>
            )}
            <Grid item>
              <Button variant="text" color="primary" onClick={onClose}>
                Отменить
              </Button>
            </Grid>
            <Grid item>
              <ButtonContainerWithSpinner
                isLoading={isSubmittingCreateOrUpdate}
              >
                <Button
                  variant="text"
                  color="primary"
                  type="submit"
                  disabled={isSubmittingCreateOrUpdate}
                >
                  Сохранить
                </Button>
              </ButtonContainerWithSpinner>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}
