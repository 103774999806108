import { MultipleEditableRoot, MultipleEditableItem } from 'components'
import { useMultipleForms } from 'utils'
import { Performer } from 'types'

import { PerformerCommentForm } from './PerformerCommentForm'
import { EditablePerformerComment } from './EditablePerformerComment'

type EditablePerformerCommentsProps = {
  performer: Performer
}

export const EditablePerformerComments = ({
  performer,
}: EditablePerformerCommentsProps) => {
  const { forms, addForm, removeForm } = useMultipleForms()

  return (
    <MultipleEditableRoot
      onAddForm={addForm}
      buttonTitle="Оставить комментарий"
    >
      {performer.comments.map((comment) => (
        <MultipleEditableItem key={comment.id}>
          <EditablePerformerComment comment={comment} />
        </MultipleEditableItem>
      ))}
      {forms.map((itemKey) => (
        <MultipleEditableItem key={itemKey}>
          <PerformerCommentForm
            performerId={performer.id}
            onClose={removeForm(itemKey)}
          />
        </MultipleEditableItem>
      ))}
    </MultipleEditableRoot>
  )
}
