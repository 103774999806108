import { gql, useMutation } from '@apollo/client'

import { COMPANY } from 'api'
import { Company } from 'types'

export const DELETE_COMPANY_CONTRACT = gql`
  mutation DeleteCompanyContract($id: ID!) {
    deleteCompanyContract(id: $id) {
      company {
        ...Company
      }
    }
  }
  ${COMPANY}
`

export type DeleteCompanyContractData = {
  deleteCompanyContract: { company: Company }
}

export type DeleteCompanyContractVariables = {
  id: string
}

export const useDeleteCompanyContractMutation = () => {
  return useMutation<DeleteCompanyContractData, DeleteCompanyContractVariables>(
    DELETE_COMPANY_CONTRACT,
  )
}
