import { ReactNode } from 'react'
import { Button, Grid } from '@mui/material'
import { Add as AddIcon } from '@mui/icons-material'

type MultipleEditableRootProps = {
  onAddForm: () => void
  buttonTitle: string
  isButtonVisible?: boolean
  children: ReactNode
}

export const MultipleEditableRoot = ({
  children,
  onAddForm,
  buttonTitle,
  isButtonVisible = true,
}: MultipleEditableRootProps) => {
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
    >
      {children}
      {isButtonVisible && (
        <Grid item xs={12}>
          <Grid item container justifyContent="center" xs={12}>
            <Button
              variant="text"
              color="primary"
              startIcon={<AddIcon />}
              onClick={onAddForm}
            >
              {buttonTitle}
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
