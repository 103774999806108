import { useMemo } from 'react'
import { Field, FieldProps } from 'components'

export type SelectOption = {
  value: string
  label: string
}

export type FilterOptions = (option: SelectOption) => boolean

export type SelectFieldProps = FieldProps & {
  options: SelectOption[]
  hasEmptyOption?: boolean
  filterOptions?: FilterOptions
}

export const SelectField = ({
  options: rawOptions,
  name,
  disabled,
  filterOptions,
  hasEmptyOption = false,
  ...props
}: SelectFieldProps) => {
  const options = useMemo(
    () => (filterOptions ? rawOptions.filter(filterOptions) : rawOptions),
    [filterOptions, rawOptions],
  )

  return (
    <Field
      select
      SelectProps={{ native: true, multiple: false }}
      name={name}
      disabled={options.length === 0 || disabled}
      {...props}
    >
      {hasEmptyOption ? <option aria-label="None" value="" /> : null}
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Field>
  )
}
