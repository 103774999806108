import { QueryHookOptions, gql, useQuery } from '@apollo/client'

import { ORDER_STATUS } from 'api'
import { OrderStatus } from 'types'

export type OrderStatusesData = {
  orderStatuses: OrderStatus[]
}

export const GET_ORDER_STATUSES = gql`
  query GetOrderStatuses {
    orderStatuses {
      ...OrderStatus
    }
  }
  ${ORDER_STATUS}
`

export const orderStatusesQueryOptions: QueryHookOptions<OrderStatusesData> = {
  errorPolicy: 'all',
}

export function useOrderStatusesQuery() {
  return useQuery<OrderStatusesData>(
    GET_ORDER_STATUSES,
    orderStatusesQueryOptions,
  )
}
