import { useEffect } from 'react'
import { gql, useApolloClient } from '@apollo/client'

import { SERVICE } from 'api'
import { Service } from 'types'

type ServiceUpdatedData = {
  service: Service
}

type ServiceUpdatedVariables = {
  serviceId: string
}

export const SERVICE_UPDATED = gql`
  subscription ($serviceId: ID!) {
    serviceUpdated(serviceId: $serviceId) {
      ...Service
    }
  }
  ${SERVICE}
`

export const useServiceUpdatedSubscription = (serviceId?: string) => {
  const client = useApolloClient()

  useEffect(() => {
    if (serviceId) {
      const updatedServiceSubscription = client
        .subscribe<ServiceUpdatedData, ServiceUpdatedVariables>({
          query: SERVICE_UPDATED,
          variables: { serviceId },
        })
        .subscribe(() => {})

      return () => {
        updatedServiceSubscription.unsubscribe()
      }
    }
  })
}
