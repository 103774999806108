import { useCallback, SyntheticEvent } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Button, Grid } from '@mui/material'
import invariant from 'tiny-invariant'

import { useCreateOrderMutation } from 'api'
import {
  ButtonContainerWithSpinner,
  CompanyField,
  CustomerField,
  DateTimeField,
} from 'components'
import { setFormErrors } from 'utils'
import { Order } from 'types'

const errorsMessages = {
  startAt: {
    key: 'startAt' as const,
    default: 'Нельзя создать заказ с такой датой начала',
  },
  endAt: {
    key: 'endAt' as const,
    default: 'Нельзя создать заказ с такой датой окончания',
  },
}

type CreateOrderFormData = {
  startAt: Date
  customer: { id: string } | null
  company: { id: string } | null
  endAt: Date | null
}

type CreateOrderFormProps = {
  onSubmit?: (order: Order) => void
}

export const CreateOrderForm = ({
  onSubmit: onSubmitAction,
}: CreateOrderFormProps) => {
  const [createOrder] = useCreateOrderMutation()

  const methods = useForm<CreateOrderFormData>({
    defaultValues: {
      startAt: new Date(new Date().setMinutes(0)),
      endAt: null,
      customer: null,
      company: null,
    },
    mode: 'onBlur',
  })

  const onSubmit = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault()

      methods.handleSubmit(async (formData) => {
        const { startAt, endAt, customer, company } = formData

        invariant(endAt, 'endAt must be provided')
        invariant(customer, 'customer must be chosen')

        const { data } = await createOrder({
          variables: {
            input: {
              startAt: startAt.toISOString(),
              endAt: endAt.toISOString(),
              customerId: customer.id,
              companyId: company?.id || null,
            },
          },
        })

        if (data?.createOrder?.errors) {
          setFormErrors<Pick<CreateOrderFormData, 'startAt' | 'endAt'>>(
            methods.setError,
            data.createOrder.errors,
            errorsMessages,
          )
        }

        if (data?.createOrder?.order) {
          if (onSubmitAction) onSubmitAction(data.createOrder.order)
        }
      })()
    },
    [methods, createOrder, onSubmitAction],
  )

  return (
    <FormProvider {...methods}>
      <Grid
        container
        spacing={2}
        noValidate
        autoComplete="off"
        onSubmit={onSubmit}
        component="form"
      >
        <Grid item xs={12} sm={6}>
          <DateTimeField
            name="startAt"
            label="Время запуска"
            rules={{
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateTimeField
            name="endAt"
            label="Время готовности"
            rules={{
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CompanyField name="company" label="Компания" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomerField
            name="customer"
            label="Заказчик"
            rules={{
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={1}>
            <Grid item>
              <ButtonContainerWithSpinner
                isLoading={methods.formState.isSubmitting}
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={methods.formState.isSubmitting}
                >
                  Создать заказ
                </Button>
              </ButtonContainerWithSpinner>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}
