import { styled } from '@mui/material/styles'
import {
  Box,
  Paper,
  Grid,
  Typography,
  useMediaQuery,
  Theme,
} from '@mui/material'

import { ChangePassword } from './ChangePassword'

const StyledPaper = styled(Paper)(
  ({ theme }) => `
    min-width: ${theme.spacing(40)};
    padding: ${theme.spacing(3)};
    padding-bottom: ${theme.spacing(5)};
`,
)

export const Security = () => {
  const isMatchSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))

  return (
    <Grid container direction="column" spacing={3} sx={{ mt: 3, mb: 5 }}>
      <Grid item>
        <StyledPaper>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Box my={2}>
                <Typography variant={isMatchSm ? 'h4' : 'h5'} color="primary">
                  Настройки безопасности
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <ChangePassword />
            </Grid>
          </Grid>
        </StyledPaper>
      </Grid>
    </Grid>
  )
}
