import { gql, useMutation } from '@apollo/client'

import { PERFORMER } from 'api'
import { Performer } from 'types'

export const DELETE_EXPERIENCE = gql`
  mutation DeleteExperience($id: ID!) {
    deleteExperience(id: $id) {
      performer {
        ...Performer
      }
    }
  }
  ${PERFORMER}
`

export type DeleteExperienceData = {
  deleteExperience: { performer: Performer }
}

export type DeleteExperienceVariables = {
  id: string
}

export const useDeleteExperienceMutation = () => {
  return useMutation<DeleteExperienceData, DeleteExperienceVariables>(
    DELETE_EXPERIENCE,
  )
}
