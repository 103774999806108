import { gql, useMutation } from '@apollo/client'

import { COMPANY, INPUT_ERROR } from 'api'
import { Company, InputError } from 'types'

export const UPDATE_COMPANY_COMMENT = gql`
  mutation UpdateCompanyComment($input: UpdateCompanyCommentInput!) {
    updateCompanyComment(input: $input) {
      company {
        ...Company
      }
      errors {
        ...InputError
      }
    }
  }
  ${COMPANY}
  ${INPUT_ERROR}
`

type UpdateCompanyCommentDataSuccess = {
  company: Company
  errors: null
}

type UpdateCompanyCommentDataInputError = {
  company: null
  errors: InputError<'content'>[]
}

export type UpdateCompanyCommentData = {
  updateCompanyComment:
    | UpdateCompanyCommentDataSuccess
    | UpdateCompanyCommentDataInputError
}

export type UpdateCompanyCommentVariables = {
  input: {
    id: string
    content: string
  }
}

export const useUpdateCompanyCommentMutation = () => {
  return useMutation<UpdateCompanyCommentData, UpdateCompanyCommentVariables>(
    UPDATE_COMPANY_COMMENT,
  )
}
