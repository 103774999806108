import { getDollars } from '../formatters'
type ServiceGroup = {
  id: string
  name: string
  isLanguageService: boolean
  hasTopics: boolean
  hasSoftware: boolean
}

type ServiceType = {
  id: string
  name: string
  groupId: string
  rateId: string
}

type PerformerLanguageService = {
  id: string
  serviceIds: string[]
  sourceLanguageId: string
  targetLanguageId: string
}

type PerformerService = {
  id: string
  centsPerUnit: number
  performerId: string
  typeId: string
  currencyId: string
}

type RegularGroupItem = ServiceGroup & {
  services: PerformerService[]
}

type LanguageServiceGroupItem = RegularGroupItem & {
  languageService: PerformerLanguageService
}

type ServiceGroupItem = RegularGroupItem | LanguageServiceGroupItem

export function transformServicesIntoServiceGroups(
  services: PerformerService[],
  languageServices: PerformerLanguageService[],
  serviceTypes: ServiceType[],
  serviceGroups: ServiceGroup[],
): ServiceGroupItem[] {
  const serviceGroupItems: ServiceGroupItem[] = []
  for (let service of services) {
    const serviceType = serviceTypes.find(
      (serviceType) => serviceType.id === service.typeId,
    ) as ServiceType

    const serviceGroup = serviceGroups.find(
      (serviceGroup) => serviceGroup.id === serviceType.groupId,
    ) as ServiceGroup

    const languageService =
      serviceGroup.isLanguageService &&
      languageServices.find(({ serviceIds }) => {
        return serviceIds.some((id) => id === service.id)
      })

    if (languageService) {
      let serviceGroupItem = serviceGroupItems.find(
        (item) =>
          'languageService' in item &&
          item.languageService.id === languageService.id,
      )

      if (serviceGroupItem) {
        serviceGroupItem.services = [...serviceGroupItem.services, service]
      } else {
        serviceGroupItems.push({
          ...serviceGroup,
          languageService: languageService,
          services: [service],
        })
      }
    } else {
      let serviceGroupItem = serviceGroupItems.find(
        (serviceGroupItem) => serviceGroupItem.id === serviceGroup.id,
      )

      if (serviceGroupItem) {
        serviceGroupItem.services = [...serviceGroupItem.services, service]
      } else {
        serviceGroupItems.push({
          ...serviceGroup,
          services: [service],
        })
      }
    }
  }
  return Object.values(serviceGroupItems)
}

type OrderService = {
  centsPerUnit: number
  units: number
  discount?: number
  currency: {
    symbol: string
  }
}

export const getServiceTotal = (service: {
  centsPerUnit: number
  units: number
  discount?: number
}) => {
  const centsPerUnitWithDiscount =
    service.centsPerUnit * (service.discount ? 1 - service.discount / 100 : 1)
  return centsPerUnitWithDiscount * service.units
}

export function getServicesTotalDollars(services: OrderService[]) {
  const totalCents = services.reduce<Record<string, number>>((acc, val) => {
    const { symbol } = val.currency
    if (!acc[symbol]) {
      acc[symbol] = 0
    }
    acc[symbol] = acc[symbol] + getServiceTotal(val)
    return acc
  }, {})

  const totalCentsText = Object.entries(totalCents)
    .map(([key, value]) => `${getDollars(value, true)}\u00a0${key}`)
    .join(' + ')

  return totalCentsText
}
