import { QueryHookOptions, gql, useQuery } from '@apollo/client'

import { ORDER } from 'api'
import { Order } from 'types'

export type OrderData = {
  order: Order
}

export type OrderVariables = {
  id: string
}

export const GET_ORDERS = gql`
  query GetOrder($id: ID!) {
    order(id: $id) {
      ...Order
    }
  }
  ${ORDER}
`

export const defaultOrderQueryOptions: QueryHookOptions<
  OrderData,
  OrderVariables
> = {
  errorPolicy: 'all',
}

export function useOrderQuery(
  orderId: string,
  orderQueryOptions?: Omit<
    QueryHookOptions<OrderData, OrderVariables>,
    'variables'
  >,
) {
  return useQuery<OrderData, OrderVariables>(GET_ORDERS, {
    ...defaultOrderQueryOptions,
    ...orderQueryOptions,
    variables: {
      id: orderId,
    },
  })
}
