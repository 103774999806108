import { useCallback, useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import {
  useSoftwareQuery,
  useTopicsQuery,
  usePerformerServiceGroupsQuery,
} from 'api'
import { useInitialSearchParams, usePrevious } from 'utils'
import { Software, Topic } from 'types'

type PerformersFilterFormData = {
  search: string
  statusId: string
  genderId: string
  countryId: string
  nativeLanguageId: string
  serviceGroupId: string
  serviceTypeId: string
  serviceSourceLanguageId: string
  serviceTargetLanguageId: string
  software: Software[]
  topics: Topic[]
}

export function usePerformersFilterForm() {
  const initialSearchParams = useInitialSearchParams()
  const [, setSearchParams] = useSearchParams()

  const { data: topicsData } = useTopicsQuery()
  const { data: softwareData } = useSoftwareQuery()
  const { data: performerServiceGroupsData } = usePerformerServiceGroupsQuery()

  const methods = useForm<PerformersFilterFormData>({
    defaultValues: {
      search: initialSearchParams.get('search') ?? '',
      statusId: initialSearchParams.get('statusId') ?? '',
      genderId: initialSearchParams.get('genderId') ?? '',
      countryId: initialSearchParams.get('countryId') ?? '',
      nativeLanguageId: initialSearchParams.get('nativeLanguageId') ?? '',
      serviceGroupId: initialSearchParams.get('serviceGroupId') ?? '',
      serviceTypeId: initialSearchParams.get('serviceTypeId') ?? '',
      serviceSourceLanguageId:
        initialSearchParams.get('serviceSourceLanguageId') ?? '',
      serviceTargetLanguageId:
        initialSearchParams.get('serviceTargetLanguageId') ?? '',
      topics: [],
      software: [],
    },
    mode: 'onBlur',
    shouldUnregister: false,
  })

  useEffect(() => {
    if (topicsData) {
      const topicIds = initialSearchParams.getAll('topicIds')

      if (topicIds) {
        methods.setValue(
          'topics',
          topicsData.topics.filter((topic) => topicIds.includes(topic.id)),
        )
      }
    }
  }, [topicsData, methods, initialSearchParams])

  useEffect(() => {
    if (softwareData) {
      const softwareIds = initialSearchParams.getAll('softwareIds')

      if (softwareIds) {
        methods.setValue(
          'software',
          softwareData.software.filter((software) =>
            softwareIds.includes(software.id),
          ),
        )
      }
    }
  }, [softwareData, methods, initialSearchParams])

  const resetSearchField = useCallback(() => {
    methods.reset({ ...methods.getValues(), search: '' })
  }, [methods])

  const resetFilters = useCallback(() => {
    methods.reset({
      search: methods.getValues('search'),
      statusId: '',
      genderId: '',
      countryId: '',
      nativeLanguageId: '',
      serviceGroupId: '',
      serviceTypeId: '',
      serviceSourceLanguageId: '',
      serviceTargetLanguageId: '',
      topics: [],
      software: [],
    })
  }, [methods])

  const {
    search,
    topics,
    software,
    statusId,
    genderId,
    countryId,
    nativeLanguageId,
    serviceGroupId,
    serviceTypeId,
    serviceSourceLanguageId,
    serviceTargetLanguageId,
  } = methods.watch()

  useEffect(() => {
    setSearchParams({
      ...(search && { search }),
      ...(statusId && { statusId }),
      ...(genderId && { genderId }),
      ...(countryId && { countryId }),
      ...(nativeLanguageId && { nativeLanguageId }),
      ...(serviceGroupId && { serviceGroupId: serviceGroupId }),
      ...(serviceTypeId && { serviceTypeId: serviceTypeId }),
      ...(serviceSourceLanguageId && {
        serviceSourceLanguageId: serviceSourceLanguageId,
      }),
      ...(serviceTargetLanguageId && {
        serviceTargetLanguageId: serviceTargetLanguageId,
      }),
      ...(topics.length > 0 && {
        topicIds: topics.map(({ id }) => id),
      }),
      ...(software.length > 0 && {
        softwareIds: software.map(({ id }) => id),
      }),
    })
  }, [
    setSearchParams,
    search,
    topics,
    software,
    statusId,
    genderId,
    countryId,
    nativeLanguageId,
    serviceGroupId,
    serviceTypeId,
    serviceSourceLanguageId,
    serviceTargetLanguageId,
  ])

  const serviceGroup = useMemo(
    () =>
      performerServiceGroupsData?.performerServiceGroups.find(
        ({ id }) => id === serviceGroupId,
      ),
    [performerServiceGroupsData, serviceGroupId],
  )

  const previousServiceGroup = usePrevious(serviceGroup)

  useEffect(() => {
    if (previousServiceGroup && serviceGroup !== previousServiceGroup) {
      methods.setValue('serviceTypeId', '')

      if (!serviceGroup?.isLanguageService) {
        methods.setValue('serviceSourceLanguageId', '')
        methods.setValue('serviceTargetLanguageId', '')
      }
      if (!serviceGroup?.hasTopics) {
        methods.setValue('topics', [])
      }
      if (!serviceGroup?.hasSoftware) {
        methods.setValue('software', [])
      }
    }
  }, [methods, serviceGroup, previousServiceGroup])

  return { resetSearchField, resetFilters, ...methods }
}
