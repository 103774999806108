import { gql, useMutation } from '@apollo/client'

import { CUSTOMER } from 'api'
import { Customer } from 'types'

export const DELETE_CUSTOMER_COMMENT = gql`
  mutation DeleteCustomerComment($id: ID!) {
    deleteCustomerComment(id: $id) {
      customer {
        ...Customer
      }
    }
  }
  ${CUSTOMER}
`

export type DeleteCustomerCommentData = {
  deleteCustomerComment: { customer: Customer }
}

export type DeleteCustomerCommentVariables = {
  id: string
}

export const useDeleteCustomerCommentMutation = () => {
  return useMutation<DeleteCustomerCommentData, DeleteCustomerCommentVariables>(
    DELETE_CUSTOMER_COMMENT,
  )
}
