import { Link as RouterLink } from 'react-router-dom'
import { Fab, Zoom, useTheme } from '@mui/material'
import { Add as AddIcon } from '@mui/icons-material'

export type FixedButtonProps = {
  to: string
  ariaLabel?: string
}

export const FixedButton = ({ to, ariaLabel = 'Add' }: FixedButtonProps) => {
  const theme = useTheme()

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  }

  return (
    <Zoom in timeout={transitionDuration} unmountOnExit>
      <Fab
        aria-label={ariaLabel}
        color="secondary"
        component={RouterLink}
        to={to}
        sx={{
          position: 'fixed',
          bottom: theme.spacing(2),
          right: theme.spacing(2),
        }}
      >
        <AddIcon />
      </Fab>
    </Zoom>
  )
}
