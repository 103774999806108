import { MultipleEditableRoot, MultipleEditableItem } from 'components'
import { useMultipleForms } from 'utils'
import { Order } from 'types'

import { OrderCommentForm } from './OrderCommentForm'
import { EditableOrderComment } from './EditableOrderComment'

type EditableOrderCommentsProps = {
  order: Order
}

export const EditableOrderComments = ({
  order,
}: EditableOrderCommentsProps) => {
  const { forms, addForm, removeForm } = useMultipleForms()

  return (
    <MultipleEditableRoot
      onAddForm={addForm}
      buttonTitle="Оставить комментарий"
    >
      {order.comments.map((comment) => (
        <MultipleEditableItem key={comment.id}>
          <EditableOrderComment comment={comment} />
        </MultipleEditableItem>
      ))}
      {forms.map((itemKey) => (
        <MultipleEditableItem key={itemKey}>
          <OrderCommentForm orderId={order.id} onClose={removeForm(itemKey)} />
        </MultipleEditableItem>
      ))}
    </MultipleEditableRoot>
  )
}
