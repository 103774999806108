import { useCallback } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Hidden,
  Typography,
} from '@mui/material'

import { Store as StoreIcon } from '@mui/icons-material'

import { Company } from 'types'

type CompanyCardProps = {
  company: Company
}

export const CompanyCard = ({ company }: CompanyCardProps) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const search = searchParams.get('search')

  const handleClick = useCallback(() => {
    const backButtonTitle = search ? 'К результатам поиска' : 'К компаниям'

    navigate(`/companies/${company.id}`, {
      state: {
        backButtonTitle,
      },
    })
  }, [navigate, company, search])

  return (
    <Card>
      <CardActionArea
        onClick={handleClick}
        sx={{ display: 'flex', justifyContent: 'flex-start' }}
      >
        <Hidden mdDown>
          <Avatar
            src={company.logo}
            variant="square"
            sx={{ minWidth: 160, width: 160, height: 160 }}
          >
            <StoreIcon sx={{ width: '75%', height: '75%' }} />
          </Avatar>
        </Hidden>
        <CardContent sx={{ display: 'flex', flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} container spacing={2} alignItems="center">
              <Hidden mdUp>
                <Grid item>
                  <Avatar src={company.logo} sx={{ width: 80, height: 80 }}>
                    <StoreIcon sx={{ width: '75%', height: '75%' }} />
                  </Avatar>
                </Grid>
              </Hidden>
              <Grid item flexGrow={1} container alignItems="center" spacing={1}>
                <Grid item flexGrow={1}>
                  <Typography variant="h6">{company.name}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
