import { Routes, Route } from 'react-router-dom'

import { Layout, RequireAuth } from 'components'
import { CustomersList } from './CustomersList'
import { Customer } from './Customer'
import { CreateCustomer } from './CreateCustomer'

export const Customers = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequireAuth roles={['employee', 'admin']}>
            <Layout>
              <CustomersList />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path="create"
        element={
          <RequireAuth roles={['employee', 'admin']}>
            <Layout maxWidth="md">
              <CreateCustomer />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path=":id"
        element={
          <RequireAuth roles={['employee', 'admin']}>
            <Layout maxWidth="md">
              <Customer />
            </Layout>
          </RequireAuth>
        }
      />
    </Routes>
  )
}
