import { ReactNode } from 'react'
import { styled } from '@mui/material/styles'
import { AppBar, Divider, Drawer, IconButton, Toolbar } from '@mui/material'
import { ChevronLeft as ChevronLeftIcon } from '@mui/icons-material'

import { drawerWidth } from './utils'

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isMenuVisible',
})<{ isMenuVisible: boolean }>(
  ({ theme, isMenuVisible }) => `
  transition: ${theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  })};

  ${
    isMenuVisible &&
    `
    width: calc(100% - ${drawerWidth}px);
    margin-left: ${drawerWidth};
    transition: ${theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    })};
  `
  }
`,
)

const StyledDrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

const StyledMain = styled('main', {
  shouldForwardProp: (prop) => prop !== 'isMenuVisible',
})<{
  isMenuVisible?: boolean
}>(
  ({ theme, isMenuVisible }) => `
  flex-grow: 1;
  transition: ${theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  })};
  margin-left: -${drawerWidth}px;
  ${
    isMenuVisible &&
    `
    transition: ${theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    })};
    margin-left: 0;
  `
  }
`,
)

type DesktopNavbarProps = {
  setIsMenuVisible: (value: boolean) => void
  isMenuVisible: boolean
  drawerContent: ReactNode
  toolbarContent: ReactNode
  children: ReactNode
}

export const DesktopNavbar = ({
  setIsMenuVisible,
  isMenuVisible,
  drawerContent,
  toolbarContent,
  children,
}: DesktopNavbarProps) => {
  return (
    <>
      <StyledAppBar position="fixed" isMenuVisible={isMenuVisible}>
        <Toolbar>{toolbarContent}</Toolbar>
      </StyledAppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        anchor="left"
        open={isMenuVisible}
        variant="persistent"
      >
        <StyledDrawerHeader>
          <IconButton onClick={() => setIsMenuVisible(false)} size="large">
            <ChevronLeftIcon />
          </IconButton>
        </StyledDrawerHeader>
        <Divider />
        {drawerContent}
      </Drawer>
      <StyledMain isMenuVisible={isMenuVisible}>
        <StyledDrawerHeader />
        {children}
      </StyledMain>
    </>
  )
}
