import { useCallback, useMemo, useState, SyntheticEvent } from 'react'

import { AutocompleteField, AutocompleteFieldProps } from 'components'
import { useHybridSearchServicesQuery, HybridService } from 'api'

export type HybridServiceFieldProps = Omit<
  AutocompleteFieldProps<HybridService, false, false, true>,
  'loading' | 'options' | 'loading'
>

const getHybridServiceLabel = (option: string | HybridService) => {
  if (typeof option === 'string') {
    return option
  }
  if (option.__typename === 'Service') {
    return option.name
  } else {
    return `${option.service.name} (${option.sourceLanguage.name} - ${option.targetLanguage.name})`
  }
}

const isOptionEqualToValue = (option: HybridService, value: HybridService) => {
  return `${option.id}${option.__typename}` === `${value.id}${value.__typename}`
}

const filterOptions = (options: HybridService[]) => options

export function HybridServiceField(props: HybridServiceFieldProps) {
  const [search, setSearch] = useState('')

  const { data, loading } = useHybridSearchServicesQuery({
    variables: { search },
  })

  const options = useMemo(() => data?.hybridSearchServices || [], [data])

  const handleInputChange = useCallback(
    (_e: SyntheticEvent, value: string) => {
      setSearch(value)
    },
    [setSearch],
  )

  return (
    <AutocompleteField<HybridService, false, false, true>
      loading={loading}
      options={options}
      onInputChange={handleInputChange}
      getOptionLabel={getHybridServiceLabel}
      filterOptions={filterOptions}
      isOptionEqualToValue={isOptionEqualToValue}
      freeSolo
      autoSelect
      handleHomeEndKeys
      {...props}
    />
  )
}
