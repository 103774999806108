import { QueryHookOptions, gql, useQuery } from '@apollo/client'

import { USER } from 'api'
import { User } from 'types'

export type UserData = {
  user: User
}

export type UserVariables = {
  id: string
}

export const GET_USER = gql`
  query GetUser($id: ID!) {
    user(id: $id) {
      ...User
    }
  }
  ${USER}
`

export const defaultUserQueryOptions: QueryHookOptions<
  UserData,
  UserVariables
> = {
  errorPolicy: 'all',
}

export function useUserQuery(
  userId: string,
  userQueryOptions?: Omit<
    QueryHookOptions<UserData, UserVariables>,
    'variables'
  >,
) {
  return useQuery<UserData, UserVariables>(GET_USER, {
    ...defaultUserQueryOptions,
    ...userQueryOptions,
    variables: {
      id: userId,
    },
  })
}
