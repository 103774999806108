import { MultipleEditableRoot, MultipleEditableItem } from 'components'
import { useMultipleForms } from 'utils'
import { Performer } from 'types'

import { useHasUnusedPerformerServices } from './hooks'
import { PerformerServiceForm } from './PerformerServiceForm'
import { EditablePerformerService } from './EditablePerformerService'

type EditablePerformerServicesProps = {
  performer: Performer
}

export const EditablePerformerServices = ({
  performer,
}: EditablePerformerServicesProps) => {
  const { forms, addForm, removeForm } = useMultipleForms()

  const buttonTitle =
    performer.services.length > 0 || forms.length > 0
      ? 'Добавить ещё'
      : 'Добавить'

  const isDeletable = performer.services.length > 1

  const isButtonVisible = useHasUnusedPerformerServices(performer)

  return (
    <MultipleEditableRoot
      onAddForm={addForm}
      buttonTitle={buttonTitle}
      isButtonVisible={isButtonVisible}
    >
      {performer.services.map((service) => (
        <MultipleEditableItem key={service.id}>
          <EditablePerformerService
            performer={performer}
            service={service}
            isDeletable={isDeletable}
          />
        </MultipleEditableItem>
      ))}
      {forms.map((itemKey) => (
        <MultipleEditableItem key={itemKey}>
          <PerformerServiceForm
            performer={performer}
            onClose={removeForm(itemKey)}
          />
        </MultipleEditableItem>
      ))}
    </MultipleEditableRoot>
  )
}
