import { MultipleEditableRoot, MultipleEditableItem } from 'components'
import { useMultipleForms } from 'utils'
import { Company } from 'types'

import { CompanyCommentForm } from './CompanyCommentForm'
import { EditableCompanyComment } from './EditableCompanyComment'

type EditableCompanyCommentsProps = {
  company: Company
}

export const EditableCompanyComments = ({
  company,
}: EditableCompanyCommentsProps) => {
  const { forms, addForm, removeForm } = useMultipleForms()

  return (
    <MultipleEditableRoot
      onAddForm={addForm}
      buttonTitle="Оставить комментарий"
    >
      {company.comments.map((comment) => (
        <MultipleEditableItem key={comment.id}>
          <EditableCompanyComment comment={comment} />
        </MultipleEditableItem>
      ))}
      {forms.map((itemKey) => (
        <MultipleEditableItem key={itemKey}>
          <CompanyCommentForm
            companyId={company.id}
            onClose={removeForm(itemKey)}
          />
        </MultipleEditableItem>
      ))}
    </MultipleEditableRoot>
  )
}
