import { gql } from '@apollo/client'

export const SESSION = gql`
  fragment Session on Session {
    accessToken
    renewalToken
  }
`

export const USER = gql`
  fragment User on User {
    id
    avatar
    firstName
    lastName
    email
    phone
    role
    isEmailConfirmed
  }
`
