import { MaskedField, MaskedFieldProps } from 'components'

export type YearFieldProps = Omit<MaskedFieldProps, 'format'>

const integerAccept = /\d+/g

const parseInteger = (value: string) =>
  (value.match(integerAccept) || []).join('')

const formatYear = (value: string) => {
  const digits = parseInteger(value)
  const chars = digits.split('')
  return chars
    .reduce((r: string, v: string, i) => {
      if (i === 0 && ['1', '2'].includes(v)) return `${r}${v}`
      if (i === 1 && ((r === '1' && v === '9') || (r === '2' && v === '0')))
        return `${r}${v}`
      if (i > 1 && r.length > 1) return `${r}${v}`
      return `${r}`
    }, '')
    .substring(0, 4)
}

export const YearField = (props: YearFieldProps) => {
  return (
    <MaskedField
      accept={integerAccept}
      format={formatYear}
      inputProps={{ pattern: '[0-9]*', inputMode: 'numeric' }}
      {...props}
    />
  )
}
