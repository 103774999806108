import { useEditable } from 'utils'
import { Comment } from 'types'

import { CompanyComment } from './CompanyComment'
import { CompanyCommentForm } from './CompanyCommentForm'

type EditableCompanyCommentProps = {
  comment: Comment
}

export const EditableCompanyComment = ({
  comment,
}: EditableCompanyCommentProps) => {
  const { isEditing, handleEdit, handleClose } = useEditable()

  if (isEditing) {
    return <CompanyCommentForm comment={comment} onClose={handleClose} />
  }

  return <CompanyComment comment={comment} onEdit={handleEdit} />
}
