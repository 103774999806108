import { useMemo } from 'react'
import { Skeleton } from '@mui/material'

import { SelectField, SelectFieldProps } from 'components'
import { useCountriesQuery } from 'api'
import { formatReferenceEntityToSelectOption } from 'utils'

type CountrySelectFieldProps = Omit<SelectFieldProps, 'options'>

export const CountrySelectField = (props: CountrySelectFieldProps) => {
  const { data, loading } = useCountriesQuery()

  const options = useMemo(
    () => formatReferenceEntityToSelectOption(data?.countries || []),
    [data],
  )

  return loading ? (
    <Skeleton height={56} />
  ) : (
    <SelectField options={options} {...props} />
  )
}
