import { useEditable } from 'utils'
import { Comment } from 'types'

import { PerformerComment } from './PerformerComment'
import { PerformerCommentForm } from './PerformerCommentForm'

type EditablePerformerCommentProps = {
  comment: Comment
}

export const EditablePerformerComment = ({
  comment,
}: EditablePerformerCommentProps) => {
  const { isEditing, handleEdit, handleClose } = useEditable()

  if (isEditing) {
    return <PerformerCommentForm comment={comment} onClose={handleClose} />
  }

  return <PerformerComment comment={comment} onEdit={handleEdit} />
}
