import { Grid, Divider, IconButton, Stack, Typography } from '@mui/material'
import { Edit as EditIcon } from '@mui/icons-material'

import { Service as TService } from 'types'

type ServiceProps = {
  onEdit?: () => void
  service: TService
}

export const Service = ({ onEdit, service }: ServiceProps) => {
  const { name, centsPerUnit, rate, currency, languageServices } = service

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Grid container spacing={1} alignItems="center" wrap="nowrap">
          <Grid item flexGrow={1}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="caption" color="primary">
                  Название услуги
                </Typography>
                <Typography variant="body1">{name}</Typography>
              </Grid>
              {centsPerUnit && currency && !languageServices.length && (
                <Grid item xs={12}>
                  <Typography variant="caption" color="primary">
                    Стоимость
                  </Typography>
                  <Typography variant="body1">
                    {`${centsPerUnit / 1_000}\u00a0${
                      currency.symbol
                    }\u00a0за\u00a0${rate.forOneName}`}
                  </Typography>
                </Grid>
              )}
              {service.languageServices.length > 0 && (
                <Grid item xs={12}>
                  <Divider flexItem light sx={{ mb: 2 }} />
                  <Stack
                    spacing={2}
                    direction="column"
                    divider={<Divider light variant="middle" />}
                  >
                    {service.languageServices.map((languageService) => (
                      <div key={languageService.id}>
                        <Grid
                          container
                          spacing={2}
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Grid item xs={12} sm="auto">
                            <Typography variant="body1">
                              {`${languageService.sourceLanguage.name}\u00a0-\u00a0${languageService.targetLanguage?.name}`}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="body2">
                              {`${languageService.centsPerUnit / 1_000}\u00a0${
                                languageService.currency.symbol
                              }\u00a0за\u00a0${rate.forOneName}`}
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    ))}
                  </Stack>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="Редактировать"
              onClick={onEdit}
              size="large"
            >
              <EditIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
