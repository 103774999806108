import { gql, useMutation } from '@apollo/client'

import { PERFORMER_SERVICE, INPUT_ERROR } from 'api'
import { PerformerService, InputError } from 'types'

export const UPDATE_PERFORMER_SERVICE = gql`
  mutation UpdatePerformerService($input: UpdatePerformerServiceInput!) {
    updatePerformerService(input: $input) {
      service {
        ...PerformerService
      }
      errors {
        ...InputError
      }
    }
  }
  ${PERFORMER_SERVICE}
  ${INPUT_ERROR}
`

type UpdatePerformerServiceDataSuccess = {
  service: PerformerService
  errors: null
}

type UpdatePerformerServiceDataInputError = {
  service: null
  errors: InputError<
    | 'centsPerUnit'
    | 'currencyId'
    | `language_services.${number}.source_language_id`
  >[]
}

export type UpdatePerformerServiceData = {
  updatePerformerService:
    | UpdatePerformerServiceDataSuccess
    | UpdatePerformerServiceDataInputError
}

type UpdatePerformerLanguageServiceVariables = {
  id?: string
  centsPerUnit: number
  currencyId: string
  sourceLanguageId: string
  targetLanguageId: string
}

export type UpdatePerformerServiceVariables = {
  input: {
    id: string
    centsPerUnit: number | null
    currencyId: string | null
    typeId: string
    languageServices: UpdatePerformerLanguageServiceVariables[]
  }
}

export const useUpdatePerformerServiceMutation = () => {
  return useMutation<
    UpdatePerformerServiceData,
    UpdatePerformerServiceVariables
  >(UPDATE_PERFORMER_SERVICE)
}
