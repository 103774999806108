import { Avatar, Box, Grid, Typography, IconButton } from '@mui/material'
import { Edit as EditIcon } from '@mui/icons-material'

import { useCurrentUserQuery } from 'api'
import { getUserFullname, formatDateTime } from 'utils'
import { Comment } from 'types'

type PerformerCommentProps = {
  comment: Comment
  onEdit?: () => void
}

export const PerformerComment = ({
  comment,
  onEdit,
}: PerformerCommentProps) => {
  const { data } = useCurrentUserQuery()
  const currentUser = data!.currentUser!

  const isEditPossible =
    currentUser.id === comment.manager.profile.id ||
    currentUser.role === 'admin'
  const fullName = getUserFullname(comment.manager.profile)

  const commentDateTime =
    comment.insertedAt !== comment.updatedAt
      ? `Отредактировано ${formatDateTime(comment.updatedAt)}`
      : formatDateTime(comment.insertedAt)

  return (
    <Grid container spacing={1} alignItems="center" wrap="nowrap">
      <Grid item container spacing={1} flexGrow={1}>
        <Grid item container spacing={1} alignItems="center" wrap="nowrap">
          <Grid item>
            <Avatar
              src={comment.manager.profile.avatar}
              alt={fullName}
              sx={{ width: 64, height: 64 }}
            />
          </Grid>
          <Grid item container flexGrow={1}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">{fullName}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                {commentDateTime}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">{comment.content}</Typography>
        </Grid>
      </Grid>
      <Grid item>
        {isEditPossible ? (
          <IconButton aria-label="Редактировать" onClick={onEdit} size="large">
            <EditIcon />
          </IconButton>
        ) : (
          <Box p={3} />
        )}
      </Grid>
    </Grid>
  )
}
