import { useCallback, SyntheticEvent } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { format } from 'date-fns'
import { Button, Grid } from '@mui/material'

import { useUpdatePerformerMutation } from 'api'
import {
  AvatarField,
  ButtonContainerWithSpinner,
  CountrySelectField,
  DateField,
  EmailField,
  Field,
  GenderSelectField,
  LanguageSelectField,
  PerformerStatusSelectField,
  PhoneField,
  UploadField,
} from 'components'
import { resetPhoneFormat, setFormErrors } from 'utils'
import { Performer } from 'types'

const errorsMessages = {
  'profile.email': {
    key: 'email' as const,
    messages: {
      'has already been taken':
        'Пользователь с таким email уже зарегистрирован',
      'is invalid': 'Неверный email',
    },
    default: 'Нельзя зарегистрироваться с таким email',
  },
  'profile.phone': {
    key: 'phone' as const,
    messages: {
      'is invalid': 'Неверный номер телефона',
      'has already been taken':
        'Пользователь с таким телефоном уже зарегистрирован',
    },
    default: 'Нельзя зарегистрироваться с таким номером телефона',
  },
}

const acceptedExtensions = ['pdf']

type PerformerProfileFormData = {
  bio: string
  cv?: File | string
  nativeLanguageId: string
  statusId: string
  city: string
  birthday: Date | null
  countryId: string
  genderId: string
  firstName: string
  lastName: string
  email: string
  phone: string
  avatar: File | string
}

type PerformerProfileFormProps = {
  performer: Performer
  onSubmit?: () => void
  onCancel?: () => void
}

export const PerformerProfileForm = ({
  performer,
  onSubmit: onSubmitAction,
  onCancel,
}: PerformerProfileFormProps) => {
  const [updatePerformer] = useUpdatePerformerMutation()

  const methods = useForm<PerformerProfileFormData>({
    defaultValues: {
      bio: performer.bio,
      cv: performer.cv,
      nativeLanguageId: performer.nativeLanguage.id,
      statusId: performer.status.id,
      city: performer.profile.city,
      birthday: performer.profile.birthday
        ? new Date(performer.profile.birthday)
        : null,
      countryId: performer.profile.country.id,
      genderId: performer.profile.gender.id,
      avatar: performer.profile.avatar,
      firstName: performer.profile.firstName,
      lastName: performer.profile.lastName,
      email: performer.profile.email,
      phone: performer.profile.phone,
    },
    mode: 'onBlur',
  })

  const onSubmit = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault()
      methods.handleSubmit(async (formData) => {
        const {
          city,
          birthday: rawBirthday,
          nativeLanguageId,
          statusId,
          countryId,
          genderId,
          bio,
          cv,
          avatar,
          firstName,
          lastName,
          email,
          phone,
        } = formData

        const birthday = rawBirthday ? format(rawBirthday, 'yyyy-MM-dd') : ''

        const { data } = await updatePerformer({
          variables: {
            input: {
              id: performer.id,
              bio,
              nativeLanguageId,
              statusId,
              // URL of existing cv is not valid value
              ...(typeof cv !== 'string' && { cv }),
              profile: {
                city,
                birthday,
                countryId,
                genderId,
                firstName,
                lastName,
                phone: resetPhoneFormat(phone),
                email,
                // URL of existing avatar is not valid value
                ...(typeof avatar !== 'string' && { avatar }),
              },
            },
          },
        })

        if (data?.updatePerformer?.errors) {
          setFormErrors<Pick<PerformerProfileFormData, 'email' | 'phone'>>(
            methods.setError,
            data.updatePerformer.errors,
            errorsMessages,
          )
        }

        if (data?.updatePerformer?.performer) {
          if (onSubmitAction) onSubmitAction()
          if (onCancel) onCancel()
        }
      })()
    },
    [methods, updatePerformer, onSubmitAction, onCancel, performer.id],
  )

  return (
    <FormProvider {...methods}>
      <Grid
        container
        spacing={2}
        noValidate
        autoComplete="off"
        onSubmit={onSubmit}
        component="form"
      >
        <Grid item xs={12}>
          <Grid container alignItems="flex-end" spacing={2}>
            <Grid item xs={12} sm={6}>
              <AvatarField name="avatar" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    name="firstName"
                    label="Имя"
                    rules={{
                      required: true,
                      maxLength: {
                        value: 255,
                        message: 'Не более 255 символов',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="lastName"
                    label="Фамилия"
                    rules={{
                      required: true,
                      maxLength: {
                        value: 255,
                        message: 'Не более 255 символов',
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <EmailField
            name="email"
            label="Email"
            rules={{
              required: true,
              maxLength: {
                value: 255,
                message: 'Не более 255 символов',
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PhoneField rules={{ required: true }} name="phone" label="Телефон" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CountrySelectField
            name="countryId"
            rules={{ required: true }}
            label="Страна проживания"
            hasEmptyOption
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name="city"
            label="Город проживания"
            rules={{
              required: true,
              maxLength: {
                value: 255,
                message: 'Не более 255 символов',
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LanguageSelectField
            name="nativeLanguageId"
            rules={{ required: true }}
            label="Родной язык"
            hasEmptyOption
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <GenderSelectField
            name="genderId"
            rules={{ required: true }}
            label="Пол"
            hasEmptyOption
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateField
            name="birthday"
            label="Дата рождения"
            rules={{ required: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PerformerStatusSelectField
            name="statusId"
            rules={{ required: true }}
            label="Статус"
          />
        </Grid>
        <Grid item xs={12}>
          <Field name="bio" label="Немного обо мне" multiline minRows={3} />
        </Grid>
        <Grid item xs={12}>
          <UploadField
            name="cv"
            label="Загрузить резюме"
            labelActive="Приложите резюме"
            accept={acceptedExtensions}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={1}>
            {onCancel && (
              <Grid item>
                <Button variant="text" color="primary" onClick={onCancel}>
                  Отмена
                </Button>
              </Grid>
            )}
            <Grid item>
              <ButtonContainerWithSpinner
                isLoading={methods.formState.isSubmitting}
              >
                <Button
                  variant="text"
                  color="primary"
                  type="submit"
                  disabled={methods.formState.isSubmitting}
                >
                  Сохранить
                </Button>
              </ButtonContainerWithSpinner>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}
