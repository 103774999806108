import { Grid, Typography, IconButton } from '@mui/material'
import { Edit as EditIcon } from '@mui/icons-material'

import { Performer } from 'types'

type PerformerSkillsProps = {
  performer: Performer
  onEdit?: () => void
}

export const PerformerSkills = ({
  performer: { topics, software },
  onEdit,
}: PerformerSkillsProps) => {
  return (
    <Grid container spacing={1} alignItems="center" wrap="nowrap">
      <Grid item sx={{ flexGrow: 1 }}>
        <Grid container spacing={1} direction="column">
          <Grid item xs={12}>
            <Typography variant="caption" color="primary">
              Тематики
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              {topics.length > 0
                ? topics.map(({ name }) => name).join(', ')
                : 'Не выбрано'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" color="primary">
              Программное обеспечение
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              {software.length > 0
                ? software.map(({ name }) => name).join(', ')
                : 'Не выбрано'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <IconButton aria-label="Редактировать" onClick={onEdit} size="large">
          <EditIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}
