import { useCallback, useState } from 'react'

export const useEditable = (isEditingInitial = false) => {
  const [isEditing, setIsEditing] = useState(isEditingInitial)

  const handleEdit = useCallback(() => {
    setIsEditing(true)
  }, [setIsEditing])

  const handleClose = useCallback(() => {
    setIsEditing(false)
  }, [setIsEditing])

  return { isEditing, handleEdit, handleClose }
}
