import { useCallback } from 'react'
import { QueryHookOptions, gql, useQuery } from '@apollo/client'

import { PAGE_INFO } from 'api'
import { Service, SortOrder, PageInfo } from 'types'

const ITEMS_PER_PAGE = 30

export const cachePaginatedServicesKeyArgs = ['search', 'sortOrder']
export const cachePaginatedServicesItemsMerge = (
  existing: Service[],
  incoming: Service[],
) => {
  return existing ? [...existing, ...incoming] : incoming
}
export type PaginatedServicesData = {
  paginatedServices: {
    items: Service[]
    pageInfo: PageInfo
  }
}

export type PaginatedServicesVariables = {
  sortOrder?: {
    name?: SortOrder
  }
  search?: string
  pagination?: {
    limit?: number
    endCursor?: string
    startCursor?: string
  }
}

export const GET_PAGINATED_SERVICES = gql`
  query GetPaginatedServices(
    $search: String
    $sortOrder: ServicesSortOrder
    $pagination: PaginationInput
  ) {
    paginatedServices(
      search: $search
      sortOrder: $sortOrder
      pagination: $pagination
    ) {
      items {
        id
        name
        centsPerUnit
        currency {
          name
          symbol
        }
        rate {
          name
          forOneName
        }
        languageServices {
          id
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PAGE_INFO}
`

export const defaultPaginatedServicesQueryOptions: QueryHookOptions = {
  errorPolicy: 'all',
  notifyOnNetworkStatusChange: true,
}

export function usePaginatedServicesQuery(
  paginatedServicesQueryOptions?: QueryHookOptions,
) {
  const { data, loading, error, fetchMore, ...rest } = useQuery<
    PaginatedServicesData,
    PaginatedServicesVariables
  >(GET_PAGINATED_SERVICES, {
    ...defaultPaginatedServicesQueryOptions,
    ...paginatedServicesQueryOptions,
    variables: {
      ...paginatedServicesQueryOptions?.variables,
      pagination: {
        limit: ITEMS_PER_PAGE,
        ...paginatedServicesQueryOptions?.variables?.pagination,
      },
    },
  })

  const endCursor = data?.paginatedServices.pageInfo.endCursor

  const patchedFetchMore = useCallback(() => {
    if (!loading && !error && endCursor) {
      fetchMore({
        variables: {
          pagination: {
            limit: ITEMS_PER_PAGE,
            ...paginatedServicesQueryOptions?.variables?.pagination,
            endCursor,
          },
        },
      })
    }
  }, [
    fetchMore,
    endCursor,
    loading,
    error,
    paginatedServicesQueryOptions?.variables?.pagination,
  ])

  return { data, loading, error, fetchMore: patchedFetchMore, ...rest }
}
