import { Link as RouterLink } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import {
  Box,
  Button,
  Grid,
  FormHelperText,
  Link,
  Typography,
} from '@mui/material'

import { useSendPasswordUrlMutation } from 'api'
import { ButtonContainerWithSpinner, EmailField, Logo } from 'components'

type FormData = {
  email: string
}

export const PasswordRecovery = () => {
  const [sendResetPasswordUrl, { data, loading }] = useSendPasswordUrlMutation()
  const methods = useForm<FormData>({
    defaultValues: { email: '' },
    shouldFocusError: true,
  })
  const onSubmit = methods.handleSubmit(async (data) => {
    sendResetPasswordUrl({ variables: { input: { email: data.email } } })
  })

  return (
    <FormProvider {...methods}>
      <Grid
        container
        spacing={2}
        noValidate
        autoComplete="off"
        onSubmit={onSubmit}
        component="form"
      >
        <Grid item xs={12} container justifyContent="center">
          <Logo />
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <Box my={2}>
            <Typography variant="body1" color="primary">
              Восстановите пароль
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <EmailField name="email" rules={{ required: true }} label="Email" />
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <FormHelperText>
            {data?.sendResetPasswordUrl?.email
              ? 'Ссылка для сброса пароля направлена на указанный email'
              : ' '}
          </FormHelperText>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
        >
          <ButtonContainerWithSpinner isLoading={loading}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
            >
              Восстановить пароль
            </Button>
          </ButtonContainerWithSpinner>
        </Grid>

        <Grid item xs={12} container justifyContent="center">
          <Link
            variant="body2"
            color="primary"
            component={RouterLink}
            to="/auth/sign-in"
          >
            Помню пароль
          </Link>
        </Grid>
      </Grid>
    </FormProvider>
  )
}
