import { gql } from '@apollo/client'

export const INPUT_ERROR = gql`
  fragment InputError on InputError {
    key
    message
  }
`

export const PAGE_INFO = gql`
  fragment PageInfo on PageInfo {
    totalCount
    startCursor
    endCursor
  }
`
