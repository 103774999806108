import {
  BaseMutationOptions,
  gql,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client'

import { resetWebsocketConnection } from 'utils'
import { USER, SESSION } from 'api'
import { User, Session } from 'types'

export const SIGN_IN = gql`
  mutation SignIn($input: SignInInput!) {
    signIn(input: $input) {
      session {
        ...Session
      }
      user {
        ...User
      }
    }
  }
  ${USER}
  ${SESSION}
`

export type SignInData = {
  signIn: {
    session: Session
    user: User
  }
}

export type SignInVariables = {
  input: {
    email: string
    password: string
  }
}

export const signInMutationOptions: BaseMutationOptions<
  SignInData,
  SignInVariables
> = {
  errorPolicy: 'all',
  onCompleted: (data) => {
    if (data.signIn?.session) {
      const { accessToken, renewalToken } = data.signIn.session
      localStorage.setItem('accessToken', accessToken)
      localStorage.setItem('renewalToken', renewalToken)
      resetWebsocketConnection()
    }
  },
}

export const useSignInMutation = () => {
  const [signIn, fetchResult] = useMutation<SignInData, SignInVariables>(
    SIGN_IN,
    signInMutationOptions,
  )

  const sendSignIn = async (
    mutationOptions: MutationFunctionOptions<SignInData, SignInVariables>,
  ) => {
    const result = await signIn(mutationOptions)
    if (result.data?.signIn?.session) await fetchResult.client.resetStore()
    return result
  }

  return [sendSignIn, fetchResult] as const
}
