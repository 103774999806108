import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import {
  Button,
  Box,
  Paper,
  Stepper,
  Step,
  StepButton,
  Hidden,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  Theme,
} from '@mui/material'
import {
  ArrowForward as ArrowForwardIcon,
  ArrowBack as ArrowBackIcon,
} from '@mui/icons-material'

import { useCurrentUserQuery, useUpdatePerformerMutation } from 'api'
import { User, PerformerService } from 'types'

import {
  EditablePerformerEducation,
  EditablePerformerExperience,
  EditablePerformerServices,
  EditablePerformerSkills,
} from 'pages/Performers/components'

import { PerformerOnboardingInfoForm } from './PerformerOnboardingInfoForm'

const StyledPaper = styled(Paper)(
  ({ theme }) => `
    min-width: ${theme.spacing(40)};
    margin-top: ${theme.spacing(3)};
    margin-bottom: ${theme.spacing(5)};
    padding: ${theme.spacing(3)};
    padding-bottom: ${theme.spacing(5)};
`,
)

type OnboardingStepScreenProps = {
  onSubmit: () => void
}

type TStep = {
  label: string
  title: string
  onboardingStepScreenComponent: (
    props: OnboardingStepScreenProps,
  ) => React.ReactNode
  isCompleted: boolean
  hasOwnButtons: boolean
}

const isServiceHasSkills = ({ type: { group } }: PerformerService) => {
  return group.hasTopics || group.hasSoftware
}

const getSteps = (user: User): TStep[] => [
  {
    label: 'Личное',
    title: 'Расскажите о себе',
    onboardingStepScreenComponent: ({
      onSubmit,
    }: OnboardingStepScreenProps) => (
      <PerformerOnboardingInfoForm
        profileId={user.id}
        performer={user.performer}
        onSubmit={onSubmit}
      />
    ),
    isCompleted: user.performer !== null,
    hasOwnButtons: true,
  },
  {
    label: 'Образование',
    title: 'Какое у вас образование?',
    onboardingStepScreenComponent: () => (
      <EditablePerformerEducation performer={user.performer!} />
    ),
    isCompleted: user.performer !== null && user.performer.education.length > 0,
    hasOwnButtons: false,
  },
  {
    label: 'Опыт',
    title: 'Поделитесь вашим опытом',
    onboardingStepScreenComponent: () => (
      <EditablePerformerExperience performer={user.performer!} />
    ),
    isCompleted:
      user.performer !== null && user.performer.experience.length > 0,
    hasOwnButtons: false,
  },
  {
    label: 'Услуги',
    title: 'Укажите услуги и тарифы',
    onboardingStepScreenComponent: () => (
      <EditablePerformerServices performer={user.performer!} />
    ),
    isCompleted: user.performer !== null && user.performer.services.length > 0,
    hasOwnButtons: false,
  },
  ...(user.performer !== null &&
  user.performer.services.some(isServiceHasSkills)
    ? [
        {
          label: 'Навыки',
          title: 'Добавьте навыки',
          onboardingStepScreenComponent: () => (
            <EditablePerformerSkills
              performer={user.performer!}
              isEditingInitial
            />
          ),
          isCompleted:
            user.performer.topics.length > 0 ||
            user.performer.software.length > 0,
          hasOwnButtons: false,
        },
      ]
    : []),
]

export const PerformerOnboarding = () => {
  const { data } = useCurrentUserQuery()
  const [updatePerformer] = useUpdatePerformerMutation()
  const currentUser = data!.currentUser!

  const isMatchSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))

  const steps = getSteps(currentUser)
  const currentStep = steps.findIndex((step) => step.isCompleted === false)

  const [activeStep, setActiveStep] = useState(
    currentStep === -1 ? steps.length - 1 : currentStep,
  )
  const isLastStepActive = activeStep === steps.length - 1

  const navigate = useNavigate()
  const finishOnboarding = useCallback(async () => {
    await updatePerformer({
      variables: {
        input: { id: currentUser!.performer!.id, isOnboardingFinished: true },
      },
    })
    navigate('/performers/me', { replace: true })
  }, [updatePerformer, currentUser, navigate])

  const goBack = useCallback(() => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1)
    }
  }, [activeStep, setActiveStep])

  const goNext = useCallback(() => {
    if (!isLastStepActive) {
      setActiveStep(activeStep + 1)
    } else {
      finishOnboarding()
    }
  }, [isLastStepActive, activeStep, setActiveStep, finishOnboarding])

  const { onboardingStepScreenComponent, title, hasOwnButtons, isCompleted } =
    steps[activeStep]

  return (
    <Grid container alignContent="center" justifyContent="center">
      <Grid item xs={12} sm={10} md={8}>
        <StyledPaper>
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
              <Stepper nonLinear activeStep={activeStep} alternativeLabel>
                {steps.map(({ label, isCompleted }, index) => (
                  <Step
                    key={label}
                    completed={index !== activeStep && isCompleted}
                    disabled={steps[index - 1] && !steps[index - 1].isCompleted}
                  >
                    <StepButton
                      onClick={() => {
                        setActiveStep(index)
                      }}
                    >
                      <Hidden mdDown>{label}</Hidden>
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Box my={2}>
                <Typography
                  variant={isMatchSm ? 'h4' : 'h5'}
                  color="primary"
                  align="center"
                >
                  {title}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              {onboardingStepScreenComponent({ onSubmit: goNext })}
            </Grid>
            {!hasOwnButtons && (
              <>
                <Grid item xs={12}>
                  <Divider variant="fullWidth" />
                </Grid>
                <Grid item container justifyContent="space-between" xs={12}>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={goBack}
                    startIcon={<ArrowBackIcon />}
                  >
                    Назад
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={goNext}
                    disabled={!isCompleted}
                    {...(!isLastStepActive && {
                      endIcon: <ArrowForwardIcon />,
                    })}
                  >
                    {isLastStepActive ? 'Начать сотрудничество' : 'Далее'}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </StyledPaper>
      </Grid>
    </Grid>
  )
}
