import { gql, useMutation } from '@apollo/client'

import { COMPANY, INPUT_ERROR } from 'api'
import { Company, InputError } from 'types'

export const CREATE_COMPANY_COMMENT = gql`
  mutation CreateCompanyComment($input: CreateCompanyCommentInput!) {
    createCompanyComment(input: $input) {
      company {
        ...Company
      }
      errors {
        ...InputError
      }
    }
  }
  ${COMPANY}
  ${INPUT_ERROR}
`

type CreateCompanyCommentDataSuccess = {
  company: Company
  errors: null
}

type CreateCompanyCommentDataInputError = {
  company: null
  errors: InputError<'content'>[]
}

export type CreateCompanyCommentData = {
  createCompanyComment:
    | CreateCompanyCommentDataSuccess
    | CreateCompanyCommentDataInputError
}

export type CreateCompanyCommentVariables = {
  input: {
    companyId: string
    content: string
  }
}

export const useCreateCompanyCommentMutation = () => {
  return useMutation<CreateCompanyCommentData, CreateCompanyCommentVariables>(
    CREATE_COMPANY_COMMENT,
  )
}
