import { Avatar, Grid, Typography, IconButton } from '@mui/material'
import { Edit as EditIcon } from '@mui/icons-material'

import { getUserFullname } from 'utils'
import { User } from 'types'

type ProfileProps = {
  user: User
  onEdit?: () => void
}

export const Profile = ({ user, onEdit }: ProfileProps) => {
  const fullName = getUserFullname(user)

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item>
        <Avatar
          src={user.avatar}
          alt={fullName}
          sx={{ width: 120, height: 120 }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4" align="center">
          {fullName}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1} alignItems="center" wrap="nowrap">
          <Grid item flexGrow={1}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="caption" color="primary">
                  Почта
                </Typography>
                <Typography variant="body1">{user.email}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" color="primary">
                  Телефон
                </Typography>
                <Typography variant="body1">+{user.phone}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="Редактировать"
              onClick={onEdit}
              size="large"
            >
              <EditIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
