import { useMemo } from 'react'
import { Field, FieldProps } from 'components'

export type EmailFieldProps = FieldProps

export const EmailField = ({ rules, ...props }: EmailFieldProps) => {
  const memoizedRules = useMemo(
    () => ({
      pattern: {
        value: /\S+@\S+\.\S+\S+/,
        message: 'Введите действительный email',
      },
      ...rules,
    }),
    [rules],
  )

  return <Field rules={memoizedRules} {...props} />
}
