import { useMemo } from 'react'
import { FormProvider } from 'react-hook-form'
import {
  Badge,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { Tune as TuneIcon, Clear as ClearIcon } from '@mui/icons-material'
import useInfiniteScroll from 'react-infinite-scroll-hook'

import {
  usePaginatedPerformersQuery,
  usePerformerServiceGroupsQuery,
} from 'api'
import {
  Field,
  FixedButton,
  Spinner,
  TopicField,
  SoftwareField,
  LanguageSelectField,
  CountrySelectField,
  GenderSelectField,
  PerformerStatusSelectField,
  PerformerServiceGroupSelectField,
  PerformerServiceTypeSelectField,
} from 'components'
import { useDebounce } from 'utils'

import { PerformerCard } from './PerformerCard'
import {
  useFiltersMenuState,
  usePerformersFilterForm,
  usePaginatedPerformersQueryVariables,
} from './hooks'

const getPerformersFooterText = (itemsLength: number, totalCount: number) => {
  if (totalCount === 0) return 'К сожалению, никого не найдено по запросу'
  if (itemsLength === totalCount)
    return 'Это все исполнители, найденные по запросу'
  return ''
}

export const PerformersList = () => {
  const { data: performerServiceGroupsData } = usePerformerServiceGroupsQuery()

  const isMatchSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))

  const { resetSearchField, resetFilters, ...methods } =
    usePerformersFilterForm()

  const search = useDebounce<string>(methods.watch('search'), 300)

  const {
    topics,
    software,
    statusId,
    genderId,
    countryId,
    nativeLanguageId,
    serviceGroupId,
    serviceTypeId,
    serviceSourceLanguageId,
    serviceTargetLanguageId,
  } = methods.watch()

  const variables = usePaginatedPerformersQueryVariables({
    search,
    topics,
    software,
    statusId,
    genderId,
    countryId,
    nativeLanguageId,
    serviceGroupId,
    serviceTypeId,
    serviceSourceLanguageId,
    serviceTargetLanguageId,
  })

  const {
    data: paginatedPerformersData,
    error: paginatedPerformersError,
    loading: paginatedPerformersLoading,
    fetchMore,
  } = usePaginatedPerformersQuery({
    variables,
  })

  const hasPaginatedPerformersNextPage = Boolean(
    paginatedPerformersData?.paginatedPerformers.pageInfo.endCursor,
  )

  const [sentryRef] = useInfiniteScroll({
    loading: paginatedPerformersLoading,
    hasNextPage: hasPaginatedPerformersNextPage,
    onLoadMore: fetchMore,
    disabled: Boolean(paginatedPerformersError),
    rootMargin: '0px 0px 400px 0px',
  })

  const { isFiltersMenuActive, toggleIsFiltersMenuActive, activeFiltersCount } =
    useFiltersMenuState({
      topics,
      software,
      statusId,
      genderId,
      countryId,
      nativeLanguageId,
      serviceGroupId,
      serviceTypeId,
      serviceSourceLanguageId,
      serviceTargetLanguageId,
    })

  const serviceGroup = useMemo(
    () =>
      performerServiceGroupsData?.performerServiceGroups.find(
        ({ id }) => id === serviceGroupId,
      ),
    [performerServiceGroupsData, serviceGroupId],
  )

  return (
    <>
      <FormProvider {...methods}>
        <Grid container spacing={2} justifyContent="center" sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item flexGrow={1}>
                <Field
                  name="search"
                  label="Поиск"
                  withoutHelperText
                  {...(search && {
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="clear search input"
                            onClick={resetSearchField}
                            size="small"
                            edge="end"
                          >
                            <ClearIcon fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  })}
                />
              </Grid>
              <Grid item>
                {isMatchSm ? (
                  <Button
                    sx={{ height: 56 }}
                    variant="text"
                    color={isFiltersMenuActive ? 'primary' : 'inherit'}
                    startIcon={
                      <Badge
                        badgeContent={activeFiltersCount}
                        color="secondary"
                        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                      >
                        <TuneIcon />
                      </Badge>
                    }
                    size="large"
                    onClick={toggleIsFiltersMenuActive}
                  >
                    Фильтры
                  </Button>
                ) : (
                  <IconButton
                    color={isFiltersMenuActive ? 'primary' : 'default'}
                    sx={{ height: 56 }}
                    onClick={toggleIsFiltersMenuActive}
                    size="large"
                  >
                    <Badge badgeContent={activeFiltersCount} color="secondary">
                      <TuneIcon />
                    </Badge>
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Grid>
          {isFiltersMenuActive && (
            <Grid item xs={12}>
              <Paper sx={{ p: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <PerformerServiceGroupSelectField
                      name="serviceGroupId"
                      label="Тип услуги"
                      hasEmptyOption
                      withoutHelperText
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <PerformerServiceTypeSelectField
                      name="serviceTypeId"
                      label="Услуга"
                      serviceGroupId={serviceGroupId}
                      disabled={!serviceGroup}
                      hasEmptyOption
                      withoutHelperText
                    />
                  </Grid>

                  {serviceGroup?.isLanguageService && (
                    <>
                      <Grid item xs={12} md={6}>
                        <LanguageSelectField
                          name="serviceSourceLanguageId"
                          label="Язык оригинала"
                          filterOptions={({ value }) =>
                            value !== serviceTargetLanguageId
                          }
                          hasEmptyOption
                          withoutHelperText
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <LanguageSelectField
                          name="serviceTargetLanguageId"
                          label="Язык перевода"
                          filterOptions={({ value }) =>
                            value !== serviceSourceLanguageId
                          }
                          hasEmptyOption
                          withoutHelperText
                        />
                      </Grid>
                    </>
                  )}

                  {serviceGroup?.hasTopics && (
                    <Grid item xs={12}>
                      <TopicField
                        name="topics"
                        label="Тематики"
                        multiple
                        inputProps={{ withoutHelperText: true }}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} sm={6} md={3}>
                    <LanguageSelectField
                      name="nativeLanguageId"
                      label="Родной язык"
                      hasEmptyOption
                      withoutHelperText
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <CountrySelectField
                      name="countryId"
                      label="Страна проживания"
                      hasEmptyOption
                      withoutHelperText
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <GenderSelectField
                      name="genderId"
                      label="Пол"
                      hasEmptyOption
                      withoutHelperText
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <PerformerStatusSelectField
                      name="statusId"
                      label="Статус"
                      hasEmptyOption
                      withoutHelperText
                    />
                  </Grid>

                  {serviceGroup?.hasSoftware && (
                    <Grid item xs={12}>
                      <SoftwareField
                        name="software"
                        label="Программное обеспечение"
                        multiple
                        inputProps={{ withoutHelperText: true }}
                      />
                    </Grid>
                  )}

                  <Grid
                    item
                    xs={12}
                    container
                    justifyContent={isMatchSm ? 'flex-end' : 'center'}
                  >
                    <Button
                      onClick={resetFilters}
                      disabled={activeFiltersCount === 0}
                      variant="outlined"
                      color="primary"
                    >
                      Сбросить фильтры
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )}
          {paginatedPerformersLoading && !paginatedPerformersData ? (
            <Grid item xs={12} sx={{ mt: 1 }}>
              <Spinner />
            </Grid>
          ) : (
            paginatedPerformersData && (
              <>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {paginatedPerformersData.paginatedPerformers.items.map(
                      (performer) => (
                        <Grid item xs={12} key={performer.id}>
                          <PerformerCard performer={performer} />
                        </Grid>
                      ),
                    )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ height: 80 }}
                  container
                  alignItems="flex-start"
                  justifyContent="center"
                >
                  {hasPaginatedPerformersNextPage && <div ref={sentryRef} />}
                  {paginatedPerformersLoading ? (
                    <Spinner />
                  ) : (
                    <Typography variant="body1" color="primary">
                      {getPerformersFooterText(
                        paginatedPerformersData.paginatedPerformers.items
                          .length,
                        paginatedPerformersData.paginatedPerformers.pageInfo
                          .totalCount,
                      )}
                    </Typography>
                  )}
                </Grid>
              </>
            )
          )}
        </Grid>
      </FormProvider>
      <FixedButton ariaLabel="Add performer" to="/performers/create" />
    </>
  )
}
