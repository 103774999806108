import { gql, useMutation } from '@apollo/client'

import { USER, INPUT_ERROR } from 'api'
import { User, InputError } from 'types'

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        ...User
      }
      errors {
        ...InputError
      }
    }
  }
  ${USER}
  ${INPUT_ERROR}
`

type ChangePasswordDataSuccess = {
  user: User
  errors: null
}

type ChangePasswordDataInputError = {
  user: null
  errors: InputError<'currentPassword' | 'password'>[]
}

export type ChangePasswordData = {
  updateUser: ChangePasswordDataSuccess | ChangePasswordDataInputError
}

export type ChangePasswordVariables = {
  input: {
    id: string
    currentPassword: string
    password: string
  }
}

export const useChangePasswordMutation = () => {
  return useMutation<ChangePasswordData, ChangePasswordVariables>(
    CHANGE_PASSWORD,
  )
}
