import { QueryHookOptions, gql, useQuery } from '@apollo/client'

import { COUNTRY } from 'api'
import { Country } from 'types'

export type CountriesData = {
  countries: Country[]
}

export const GET_COUNTRIES = gql`
  query GetCountries {
    countries {
      ...Country
    }
  }
  ${COUNTRY}
`

export const countriesQueryOptions: QueryHookOptions<CountriesData> = {
  errorPolicy: 'all',
}

export function useCountriesQuery() {
  return useQuery<CountriesData>(GET_COUNTRIES, countriesQueryOptions)
}
