import { gql, useMutation } from '@apollo/client'

import { COMPANY } from 'api'
import { Company } from 'types'

export const DELETE_COMPANY_COMMENT = gql`
  mutation DeleteCompanyComment($id: ID!) {
    deleteCompanyComment(id: $id) {
      company {
        ...Company
      }
    }
  }
  ${COMPANY}
`

export type DeleteCompanyCommentData = {
  deleteCompanyComment: { company: Company }
}

export type DeleteCompanyCommentVariables = {
  id: string
}

export const useDeleteCompanyCommentMutation = () => {
  return useMutation<DeleteCompanyCommentData, DeleteCompanyCommentVariables>(
    DELETE_COMPANY_COMMENT,
  )
}
