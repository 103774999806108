import { useEffect } from 'react'
import { gql, useApolloClient } from '@apollo/client'

import { ORDER } from 'api'
import { Order } from 'types'

type OrderUpdatedData = {
  order: Order
}

type OrderUpdatedVariables = {
  orderId: string
}

export const ORDER_UPDATED = gql`
  subscription ($orderId: ID!) {
    orderUpdated(orderId: $orderId) {
      ...Order
    }
  }
  ${ORDER}
`

export const useOrderUpdatedSubscription = (orderId?: string) => {
  const client = useApolloClient()

  useEffect(() => {
    if (orderId) {
      const updatedOrderSubscription = client
        .subscribe<OrderUpdatedData, OrderUpdatedVariables>({
          query: ORDER_UPDATED,
          variables: { orderId },
        })
        .subscribe(() => {})

      return () => {
        updatedOrderSubscription.unsubscribe()
      }
    }
  })
}
