import { gql, useMutation } from '@apollo/client'

import { GET_PAGINATED_COMPANIES, COMPANY, INPUT_ERROR } from 'api'
import { Company, InputError } from 'types'

export const CREATE_COMPANY = gql`
  mutation CreateCompany($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      company {
        ...Company
      }
      errors {
        ...InputError
      }
    }
  }
  ${COMPANY}
  ${INPUT_ERROR}
`

type CreateCompanyDataSuccess = {
  company: Company
  errors: null
}

type CreateCompanyDataInputError = {
  company: null
  errors: InputError<'name'>[]
}

export type CreateCompanyData = {
  createCompany: CreateCompanyDataSuccess | CreateCompanyDataInputError
}

export type CreateCompanyVariables = {
  input: {
    name: string
    docName?: string
    logo?: File | null
    url?: string
    address?: string
    legalAddress?: string
    mailAddress?: string
    inn?: string
    kpp?: string
    ogrn?: string
    employees?: string[]
  }
}

export const useCreateCompanyMutation = () => {
  return useMutation<CreateCompanyData, CreateCompanyVariables>(
    CREATE_COMPANY,
    {
      refetchQueries: [GET_PAGINATED_COMPANIES],
    },
  )
}
