import { useLocation, Navigate } from 'react-router-dom'

import { useCurrentUserQuery } from 'api'
import { getIsAuthorized } from 'utils'
import { User, Role } from 'types'

import { getUserRedirectPath } from './getUserRedirectPath'

type RequireAuthProps = {
  roles?: Role[]
  isUnauthenticated?: boolean
  hasAccess?: (user: User) => boolean
  children: JSX.Element
}

export const RequireAuth = ({
  roles,
  isUnauthenticated = false,
  hasAccess,
  children,
}: RequireAuthProps) => {
  const location = useLocation()
  const { data } = useCurrentUserQuery()

  const currentUser = data?.currentUser || null

  const isAuthorized = getIsAuthorized({
    currentUser,
    roles,
    hasAccess,
    isUnauthenticated,
  })

  if (!isAuthorized) {
    return (
      <Navigate
        to={getUserRedirectPath(currentUser)}
        state={{ referrer: location }}
        replace
      />
    )
  }

  return children
}
