import { useEditable } from 'utils'
import { Customer } from 'types'

import { CustomerProfile } from './CustomerProfile'
import { CustomerProfileForm } from './CustomerProfileForm'

type EditableCustomerProfileProps = {
  customer: Customer
}

export const EditableCustomerProfile = ({
  customer,
}: EditableCustomerProfileProps) => {
  const { isEditing, handleEdit, handleClose } = useEditable()

  if (isEditing) {
    return <CustomerProfileForm customer={customer} onCancel={handleClose} />
  }

  return <CustomerProfile customer={customer} onEdit={handleEdit} />
}
