import { ErrorOption } from 'react-hook-form'
import { camelize } from 'humps'

type FormInputError = {
  key: string
  message: string
}

type ErrorsConfig<TFormData> = {
  [key: string]: ErrorConfig<TFormData>
}

type ErrorConfig<TFormData> = {
  key?: keyof TFormData
  messages?: {
    [key: string]: string
  }
  default: string
}

type SetError<TFormData> = (
  key: keyof TFormData,
  error: ErrorOption,
  opts: { shouldFocus: boolean },
) => void

export const setFormErrors = <TFormData>(
  setError: SetError<TFormData>,
  errors: FormInputError[],
  errorsConfig: ErrorsConfig<TFormData>,
): void => {
  errors.forEach(({ key: rawKey, message }) => {
    const key = camelize(rawKey as string)

    const errorConfig = errorsConfig[key.replace(/\.[0-9]\./, '.*.')]

    if (errorConfig) {
      setError(
        errorConfig.key || (key as keyof TFormData),
        {
          type: 'manual',
          message:
            (errorConfig.messages && errorConfig.messages[message]) ||
            errorConfig.default,
        },
        { shouldFocus: true },
      )
    }
  })
}
