import { useMemo } from 'react'
import { Skeleton } from '@mui/material'

import { SelectField, SelectFieldProps } from 'components'
import { useLanguagesQuery } from 'api'
import { formatReferenceEntityToSelectOption } from 'utils'

type LanguageSelectFieldProps = Omit<SelectFieldProps, 'options'> & {}

export const LanguageSelectField = (props: LanguageSelectFieldProps) => {
  const { data, loading } = useLanguagesQuery()

  const options = useMemo(() => {
    const languages = data?.languages || []

    return formatReferenceEntityToSelectOption(languages)
  }, [data])

  return loading ? (
    <Skeleton height={56} />
  ) : (
    <SelectField options={options} {...props} />
  )
}
