import { Typography, Stack } from '@mui/material'

import { Layout } from 'components'

export const Home = () => {
  return (
    <Layout>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 5 }}
      >
        <Typography variant="body1">
          На главной странице пока ничего нет, но потом обязательно появится
        </Typography>
      </Stack>
    </Layout>
  )
}
