import { Routes, Route } from 'react-router-dom'

import { Layout, RequireAuth } from 'components'
import { Account } from './Account'
import { Security } from './Security'

export const Settings = () => {
  return (
    <Layout maxWidth="sm">
      <Routes>
        <Route
          path="account/*"
          element={
            <RequireAuth>
              <Account />
            </RequireAuth>
          }
        />
        <Route
          path="security/*"
          element={
            <RequireAuth>
              <Security />
            </RequireAuth>
          }
        />
      </Routes>
    </Layout>
  )
}
