import { gql, useMutation } from '@apollo/client'

import { PERFORMER } from 'api'
import { Performer } from 'types'

export const DELETE_EDUCATION = gql`
  mutation DeleteEducation($id: ID!) {
    deleteEducation(id: $id) {
      performer {
        ...Performer
      }
    }
  }
  ${PERFORMER}
`

export type DeleteEducationData = {
  deleteEducation: { performer: Performer }
}

export type DeleteEducationVariables = {
  id: string
}

export const useDeleteEducationMutation = () => {
  return useMutation<DeleteEducationData, DeleteEducationVariables>(
    DELETE_EDUCATION,
  )
}
