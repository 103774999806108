import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { styled } from '@mui/material/styles'

import { useCurrentUserQuery } from 'api'
import { RequireAuth, Spinner } from 'components'
import {
  Auth,
  Home,
  Performers,
  Customers,
  Companies,
  Settings,
  Orders,
  Services,
  NotFound404,
} from 'pages'

const SpinnerContainer = styled('div')`
  overflow: hidden;
  min-height: 100vh;
  display: flex;
`

export default function App() {
  const { loading } = useCurrentUserQuery()

  return loading ? (
    <SpinnerContainer>
      <Spinner />
    </SpinnerContainer>
  ) : (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth roles={['employee', 'admin']}>
              <Home />
            </RequireAuth>
          }
        />
        <Route path="performers/*" element={<Performers />} />
        <Route
          path="customers/*"
          element={
            <RequireAuth>
              <Customers />
            </RequireAuth>
          }
        />
        <Route
          path="companies/*"
          element={
            <RequireAuth>
              <Companies />
            </RequireAuth>
          }
        />
        <Route
          path="orders/*"
          element={
            <RequireAuth>
              <Orders />
            </RequireAuth>
          }
        />
        <Route
          path="services/*"
          element={
            <RequireAuth>
              <Services />
            </RequireAuth>
          }
        />
        <Route
          path="settings/*"
          element={
            <RequireAuth>
              <Settings />
            </RequireAuth>
          }
        />
        <Route path="auth/*" element={<Auth />} />
        <Route
          path="*"
          element={
            <RequireAuth>
              <NotFound404 />
            </RequireAuth>
          }
        />
      </Routes>
    </Router>
  )
}
