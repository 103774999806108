import { useEffect } from 'react'
import { gql, useApolloClient } from '@apollo/client'

import { PERFORMER } from 'api'
import { Performer } from 'types'

type PerformerUpdatedData = {
  performer: Performer
}

type PerformerUpdatedVariables = {
  performerId: string
}

export const PERFORMER_UPDATED = gql`
  subscription($performerId: ID!) {
    performerUpdated(performerId: $performerId) {
      ...Performer
    }
  }
  ${PERFORMER}
`

export const usePerformerUpdatedSubscription = (performerId?: string) => {
  const client = useApolloClient()

  useEffect(() => {
    if (performerId) {
      const updatedPerformerSubscription = client
        .subscribe<PerformerUpdatedData, PerformerUpdatedVariables>({
          query: PERFORMER_UPDATED,
          variables: { performerId },
        })
        .subscribe(() => {})

      return () => {
        updatedPerformerSubscription.unsubscribe()
      }
    }
  })
}
