import {
  BaseMutationOptions,
  gql,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client'

import { resetWebsocketConnection } from 'utils'
import { USER, SESSION, INPUT_ERROR } from 'api'
import { User, Session, InputError } from 'types'

export const SIGN_UP = gql`
  mutation SignUp($input: SignUpInput!) {
    signUp(input: $input) {
      session {
        ...Session
      }
      user {
        ...User
      }
      errors {
        ...InputError
      }
    }
  }
  ${USER}
  ${SESSION}
  ${INPUT_ERROR}
`

type SignUpDataSuccess = {
  session: Session
  user: User
  errors: null
}

type SignUpDataInputError = {
  session: null
  user: null
  errors: InputError<'email' | 'phone' | 'password'>[]
}

export type SignUpData = {
  signUp: SignUpDataSuccess | SignUpDataInputError
}

export type SignUpVariables = {
  input: {
    firstName: string
    lastName: string
    email: string
    phone: string
    password: string
  }
}

export const signUpMutationOptions: BaseMutationOptions<
  SignUpData,
  SignUpVariables
> = {
  errorPolicy: 'all',
  onCompleted: (data) => {
    if (data.signUp?.session) {
      const { accessToken, renewalToken } = data.signUp.session
      localStorage.setItem('accessToken', accessToken)
      localStorage.setItem('renewalToken', renewalToken)
      resetWebsocketConnection()
    }
  },
}

export const useSignUpMutation = () => {
  const [signUp, fetchResult] = useMutation<SignUpData, SignUpVariables>(
    SIGN_UP,
    signUpMutationOptions,
  )

  const sendSignUp = async (
    mutationOptions: MutationFunctionOptions<SignUpData, SignUpVariables>,
  ) => {
    const result = await signUp(mutationOptions)
    if (result.data?.signUp?.session) await fetchResult.client.resetStore()
    return result
  }

  return [sendSignUp, fetchResult] as const
}
