import { useEditable } from 'utils'
import { Company as TCompany } from 'types'

import { Company } from './Company'
import { CompanyForm } from './CompanyForm'

type EditableCompanyProps = {
  company: TCompany
}

export const EditableCompany = ({ company }: EditableCompanyProps) => {
  const { isEditing, handleEdit, handleClose } = useEditable()

  if (isEditing) {
    return <CompanyForm company={company} onCancel={handleClose} />
  }

  return <Company company={company} onEdit={handleEdit} />
}
