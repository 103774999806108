import { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import invariant from 'tiny-invariant'

import { useCurrentUserQuery, useConfirmEmailMutation } from 'api'
import { Spinner } from 'components'

type URLParams = {
  token: 'string'
}

export const ConfirmEmail = () => {
  const navigate = useNavigate()
  const { token } = useParams<URLParams>()
  invariant(token, "Token isn't set")

  const { enqueueSnackbar } = useSnackbar()
  const [confirmEmail] = useConfirmEmailMutation()

  const { data: currentUserData } = useCurrentUserQuery()
  const isEmailConfirmed = currentUserData?.currentUser?.isEmailConfirmed

  useEffect(() => {
    const sendConfirmationToken = async () => {
      const { data } = await confirmEmail({
        variables: { input: { token } },
      })

      if (data?.confirmEmail) {
        enqueueSnackbar('Email успешно подтвержден', {
          variant: 'success',
        })
      } else if (isEmailConfirmed) {
        enqueueSnackbar('Email уже был подтвержден, но всё равно спасибо', {
          variant: 'success',
        })
      } else {
        enqueueSnackbar('Ссылка для подтверждения email неверна или устарела', {
          variant: 'error',
        })
      }
    }

    sendConfirmationToken()

    navigate('/', { replace: true })
  }, [token, confirmEmail, isEmailConfirmed, navigate, enqueueSnackbar])

  return <Spinner />
}
